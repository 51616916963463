import { createSearchParams, useNavigate } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { GetBookRequestMessage } from '@hoot/events/messages/get-book-request-message';
import { GetBookResponseMessage } from '@hoot/events/messages/get-book-response-message';
import { ToggleFavouriteBookRequestMessage } from '@hoot/events/messages/toggle-favourite-book-request.message';
import { ToggleFavouriteBookResponseMessage } from '@hoot/events/messages/toggle-favourite-book-response.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookmarkType } from '@hoot/models/api/library';
import { UserProfileType } from '@hoot/models/api/user';
import { LibraryContext, libraryKeyLookup, useLibraries } from '@hoot/redux/reducers/librarySlice';
import {
  ReaderContext,
  readerKeyLookup,
  resetPageZoom,
  setBook,
  setIsDoublePage,
  setPageZoom,
  setReaderBookFromResponse,
  useReaders,
} from '@hoot/redux/reducers/readingSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { TeacherLibrarySandboxPageQueryParams } from '@hoot/ui/pages/v2/teacher/library/TeacherLibrarySandboxPage';
import { preloadImageQueue } from '../../../../../../utils/preloadImage';

interface UseTeacherOutOfLessonReaderControllerProps {
  readerContext: ReaderContext.SandboxReader | ReaderContext.StudentSpecificLibraryReader;
  studentProfileId?: string;
  defaultPageId?: string;
}

export const readerToLibraryKeyLookup: Record<ReaderContext, LibraryContext> = {
  [ReaderContext.SandboxReader]: LibraryContext.SandboxLibrary,
  [ReaderContext.StudentSpecificLibraryReader]: LibraryContext.StudentSpecificLibrary,
  [ReaderContext.InLessonReader]: LibraryContext.InLessonLibrary,
};

const useTeacherOutOfLessonReaderController = (props: UseTeacherOutOfLessonReaderControllerProps) => {
  const { readerContext, studentProfileId, defaultPageId } = props;

  const readerKey = readerKeyLookup[readerContext];
  const { book, pageZoom } = useReaders()[readerKey];

  const libraryContext = readerToLibraryKeyLookup[readerContext];
  const libraryKey = libraryKeyLookup[libraryContext];
  const library = useLibraries()[libraryKey];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getBookRequest = useEmit<GetBookRequestMessage>(EventType.GetBookRequest, undefined, { enabled: false });
  const toggleFavouriteBookEmitter = useEmit<ToggleFavouriteBookRequestMessage>(EventType.ToggleFavouriteBookRequest, undefined, { enabled: false });

  // Get book response.
  useSocketSubscription<GetBookResponseMessage>(EventType.GetBookResponse, {
    onEventReceived: (response) => {
      preloadImageQueue(response.book.pages.map((p) => p.url));
      dispatch(
        setReaderBookFromResponse({
          readerContext: !!studentProfileId ? ReaderContext.StudentSpecificLibraryReader : ReaderContext.SandboxReader,
          book: response.book,
          defaultPageId,
        }),
      );
    },
  });

  // Toggle fav book response.
  useSocketSubscription<ToggleFavouriteBookResponseMessage>(EventType.ToggleFavouriteBookResponse, {
    onEventReceived: (response) => {
      if (response.bookId === book?.id) {
        dispatch(
          setBook({
            readerContext,
            book: {
              ...book,
              shelf: response.updatedShelf,
            },
          }),
        );
      }
    },
  });

  const onLoadBook = (bookId: string) => {
    getBookRequest.queueMessage({
      bookId,
      studentProfileId,
      studentBookmarkType: BookmarkType.InLesson,
    });
  };

  const onGoToFirstPage = () => {
    dispatch(
      setReaderBookFromResponse({
        readerContext: !!studentProfileId ? ReaderContext.StudentSpecificLibraryReader : ReaderContext.SandboxReader,
        book: book!,
        defaultPageId: undefined,
      }),
    );
  };

  const onGoToPageWithId = (pageId: string) => {
    dispatch(
      setReaderBookFromResponse({
        readerContext: !!studentProfileId ? ReaderContext.StudentSpecificLibraryReader : ReaderContext.SandboxReader,
        book: book!,
        defaultPageId: pageId,
      }),
    );
  };

  const onToggleFavouriteBook = (bookId: string, profileId: string, profileType: UserProfileType) => {
    const toggleFavouriteBookMessage: ToggleFavouriteBookRequestMessage = {
      bookId,
      profileId,
      profileType,
      shelf: profileType === UserProfileType.Student ? 'student' : 'my',
    };
    toggleFavouriteBookEmitter.queueMessage(toggleFavouriteBookMessage);
  };

  const onResetZoom = () => {
    dispatch(resetPageZoom({ readerContext }));
  };

  const onZoomOffsetChange = (offsetLeft: number, offsetTop: number) => {
    dispatch(
      setPageZoom({
        readerContext,
        pageZoom: {
          ...pageZoom,
          offsetLeft,
          offsetTop,
        },
      }),
    );
  };

  const onZoomAmountChange = (zoomAmount: number) => {
    dispatch(
      setPageZoom({
        readerContext,
        pageZoom: {
          ...pageZoom,
          zoomAmount: zoomAmount / 100,
        },
      }),
    );
  };

  const onChangeDoublePageMode = (isDoublePage: boolean) => {
    dispatch(setIsDoublePage({ readerContext, isDoublePage }));
  };

  const onCloseBook = () => {
    const defaultLibraryShelfType = library.query?.shelfType ?? ShelfType.AllBooks;

    const urlSearchParams = createSearchParams({
      [TeacherLibrarySandboxPageQueryParams.ShelfType]: defaultLibraryShelfType,
    }).toString();

    if (!!studentProfileId) {
      navigate({
        pathname: routesDictionary.myStudents.details.library.url(studentProfileId),
        search: urlSearchParams,
      });
    } else {
      navigate({
        pathname: routesDictionary.library.url,
        search: urlSearchParams,
      });
    }
  };

  return {
    onLoadBook,
    onGoToFirstPage,
    onGoToPageWithId,
    onToggleFavouriteBook,
    onResetZoom,
    onZoomOffsetChange,
    onZoomAmountChange,
    onChangeDoublePageMode,
    onCloseBook,
  };
};

export default useTeacherOutOfLessonReaderController;
