import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface StudentRequiringGrade {
  id: string;
  name: string;
}

export interface StudentRequiringGradeResponse {
  students: StudentRequiringGrade[];
}

export function useGetStudentsRequiringGrade(options?: Omit<UseQueryOptions<StudentRequiringGradeResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetStudentsRequiringGrade],
    async () => {
      return (await axios.get<StudentRequiringGradeResponse>(`${config.apiUrl}/parent/student-profile/get-students-requiring-grade`)).data;
    },
    options,
  );
}
