import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import useGetBookComprehensionQuestions, {
  PMABookComprehensionQuestionsResponse,
} from '../../../../../../../hooks/api/pma/useGetBookComprehensionQuestions';
import { PMAUnitSubmission, useSubmitPMAUnit } from '../../../../../../../hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '../../../../../../../redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import HootTypography from '../../../../../../components/v2/core/HootTypography';
import { hootTokens } from '../../../../../../theme/v2/tokens';
import { PMAContainer } from '../PMAContainer';
import { PMAFooter } from '../PMAPageFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import { RadioOption } from '../interface';
import useTeacherPMAController from '../useTeacherPMAController';

export interface Layout6Questionnaire {
  title: 'Text Specific Knowledge' | 'Text Structure' | 'Sentence Analysis' | 'Verbal Reasoning' | 'Vocabulary (Overall)';
  subTitle?: string;
  options: RadioOption[];
}

export function PMA_QUESTIONNAIRE_LAYOUT_6(props: { questionnaire: Layout6Questionnaire[] }) {
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const optionFlatMapped = props.questionnaire.flatMap((q) => q.options);

  const [answers, setAnswers] = useState<Module_4_GenericAnswer[]>(
    props.questionnaire.map((q) => ({
      section: q.title,
      answer: null,
      answerValue: null,
    })),
  );

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const dispatch = useAppDispatch();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);

  const pmaController = useTeacherPMAController();

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    enabled: !!assessmentID,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        setAnswers(
          (data.submissionData as Module_4_GenericAnswer[]).map((q) => ({
            section: q.section,
            answer: q.answer,
            answerValue: q.answerValue,
          })),
        );
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const onPreviousUnitClick = () => {
    setShowQuestionnaire(false);
  };

  const handleOptionChange = (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const optionSelected = optionFlatMapped.find((o) => o.text === value);
    if (!optionSelected) {
      dispatch(createFlashMessage({ message: 'There was a problem with processing your selection.' }));
    } else {
      setAnswers((currentState) =>
        currentState.map((a) => (a.section !== section ? a : { ...a, answer: optionSelected.text, answerValue: optionSelected.val })),
      );
      setIsUnitQuestionnaireDirty(true);
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const onContinueClicked = () => {
    setShowQuestionnaire(true);
  };

  const onNextUnitAndSubmissionClick = () => {
    const subData: Module_4_GenericAnswer[] = answers.map((a) => ({
      section: a.section,
      answer: a.answer,
      answerValue: a.answerValue,
      bookId: bookId!,
    }));
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: subData,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const bookId = useSelector((state: RootState) => state.readers.inLessonReader.book?.id);

  const bookComprehensionQuestions = useGetBookComprehensionQuestions(bookId!, { enabled: !!bookId });

  if (!bookComprehensionQuestions.data) {
    return <CircularProgress />;
  }

  const disableSubmissionBtn = answers.some((a) => !a.answer);

  return (
    <PMAContainer showBook>
      {showQuestionnaire ? (
        <Stack flex={1} marginTop="32px" spacing="64px">
          {props.questionnaire.map((q, idx) => (
            <Section onChange={handleOptionChange} q={q} ruberic={bookComprehensionQuestions.data} answer={answers[idx].answer} />
          ))}

          <PMAFooter
            allFormFieldsFilledOut={false}
            onPreviousUnitClick={onPreviousUnitClick}
            setLeaveDialog={setLeaveDialog}
            isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
            disableSubmissionBtn={disableSubmissionBtn}
            onNextUnitAndSubmissionClick={onNextUnitAndSubmissionClick}
          />
          <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
        </Stack>
      ) : (
        <Stack>
          <PMAFooter
            allFormFieldsFilledOut={false}
            setLeaveDialog={setLeaveDialog}
            isUnitQuestionnaireDirty={false}
            disableSubmissionBtn={false}
            onNextUnitAndSubmissionClick={onContinueClicked}
            submitButtonLabel="Continue"
          />
        </Stack>
      )}
    </PMAContainer>
  );
}

function Section(props: {
  q: Layout6Questionnaire;
  onChange: (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  ruberic: PMABookComprehensionQuestionsResponse;
  answer: string | null;
}) {
  const ruberic = () => {
    switch (props.q.title) {
      case 'Text Specific Knowledge':
        return props.ruberic.textSpecificKnowledge;
      case 'Sentence Analysis':
        return props.ruberic.sentenceAnalysis;
      case 'Text Structure':
        return props.ruberic.textStructure;
      case 'Verbal Reasoning':
        return props.ruberic.verbalReasoning;
      default:
        return null;
    }
  };

  const rubericValue = ruberic();

  return (
    <Stack marginTop="24px">
      <Typography variant="titlesmall">{props.q.title}</Typography>
      <Typography sx={{ fontStyle: 'italic', fontWeight: 400 }} variant="titlesmall">
        {props.q.subTitle}
      </Typography>
      {rubericValue ? (
        <Stack sx={{ backgroundColor: hootTokens.surface[2], padding: '24px', borderRadius: '8px', marginY: '16px' }}>
          <ReactMarkdown>{rubericValue}</ReactMarkdown>
        </Stack>
      ) : null}
      <RadioGroup onChange={props.onChange(props.q.title)} name="test" value={props.answer}>
        {props.q.options.map((o) => (
          <FormControlLabel
            value={o.text}
            control={<Radio />}
            label={<HootTypography isPII={false}>{o.text}</HootTypography>}
            sx={{ display: 'flex', alignItems: 'center' }}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}
