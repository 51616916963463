import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { Box, Stack } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { Button } from '../../../../../../components/v2/core/Button';
import { Icon } from '../../../../../../components/v2/core/Icon';
import { hootTokens } from '../../../../../../theme/v2/tokens';
import { PMAContainer } from '../PMAContainer';
import { PMAFooter } from '../PMAPageFooter';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import useTeacherPMAController from '../useTeacherPMAController';

interface Props {
  prompts: PMAQuestionnaireLayout_1_Prompts[];
}

export interface PMAQuestionnaireLayout_1_Prompts {
  sentence: string;
  word: string;
  expectedResponse: string;
  wordsToBold: string;
}

export function PMA_QUESTIONNAIRE_LAYOUT_1(props: Props) {
  const { prompts } = props;
  const [currentIndex, setIndex] = useState(0);
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const [unitQuestionAnswerPair, setUnitQuestionAnswerPair] = useState<UnitQuestionAnswerPair[]>(
    prompts.map<UnitQuestionAnswerPair>((p) => ({
      fullPrompt: p.sentence,
      wordPrompt: p.word,
      answer: PMAStepTestAnswer.NoAnswer,
      expectedAnswer: p.expectedResponse,
    })),
  );
  const dispatch = useAppDispatch();
  const pmaController = useTeacherPMAController();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);

  const allFormFieldsFilledOut = unitQuestionAnswerPair.filter((uqap) => uqap.answer === PMAStepTestAnswer.NoAnswer).length === 0;

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    enabled: !!assessmentID,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        setUnitQuestionAnswerPair(data.submissionData as UnitQuestionAnswerPair[]);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const answers = unitQuestionAnswerPair.map((p) => p.answer);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const allQuestionsAnswered =
    unitQuestionAnswerPair.length > 0 && !unitQuestionAnswerPair.some((pair) => pair.answer === PMAStepTestAnswer.NoAnswer);

  const { onUnitComplete } = useTeacherPMAController();
  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);

  useEffect(() => {
    if (allQuestionsAnswered) {
      onUnitComplete(allQuestionsAnswered);
    }
  }, [allQuestionsAnswered]);

  const prompt = prompts[currentIndex];

  const handleCorrectClicked = () => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) =>
        idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: PMAStepTestAnswer.Correct } : a,
      ),
    );
    setIndex((currentState) => (currentState < prompts.length - 1 ? (currentState += 1) : currentState));
    setIsUnitQuestionnaireDirty(true);
  };

  const handleIncorrectClicked = () => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) =>
        idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: PMAStepTestAnswer.Wrong } : a,
      ),
    );
    setIndex((currentState) => (currentState < prompts.length - 1 ? (currentState += 1) : currentState));
    setIsUnitQuestionnaireDirty(true);
  };

  const handleRowClick = (index: number) => {
    setIndex(index);
    setShowEditAnswerDialog(true);
  };

  const handleEditAnswer = (newAnswer: PMAStepTestAnswer) => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) => (idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: newAnswer } : a)),
    );
    setIndex((currentState) => (currentState < prompts.length - 1 ? (currentState += 1) : currentState));
    setShowEditAnswerDialog(false);
  };

  const handleCancelEdit = () => {
    setShowEditAnswerDialog(false);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    setUnitQuestionAnswerPair([]);
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: unitQuestionAnswerPair,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setUnitQuestionAnswerPair([]);
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };
  const disableSubmissionBtn = !allFormFieldsFilledOut || submitPMAUnitRequest.isLoading;

  return (
    <PMAContainer>
      <Stack>
        <Stack marginTop="32px" direction="row" justifyContent="space-evenly">
          {isUnitComplete ? (
            <UnitCompleted />
          ) : (
            <WordPrompt
              expectedResponse={prompt.expectedResponse}
              sentence={prompt.sentence}
              onCorrectClick={handleCorrectClicked}
              onIncorrectClick={handleIncorrectClicked}
              wordsToBold={prompt.wordsToBold}
            />
          )}
          <ScoreCard prompts={prompts} answers={answers} currentIndex={currentIndex} onRowClick={handleRowClick} />
        </Stack>
        <EditAnswerDialog
          open={showEditAnswerDialog}
          questionLabel={prompts[currentIndex].word}
          defaultAnswer={answers[currentIndex]}
          onSubmit={handleEditAnswer}
          onCancel={handleCancelEdit}
          setIsUnitQuestionnaireDirty={setIsUnitQuestionnaireDirty}
        />
      </Stack>
      <PMAFooter
        allFormFieldsFilledOut={allFormFieldsFilledOut}
        onPreviousUnitClick={onPreviousUnitClick}
        setLeaveDialog={setLeaveDialog}
        isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
        disableSubmissionBtn={disableSubmissionBtn}
        onNextUnitAndSubmissionClick={onNextUnitAndSubmissionClick}
      />
      <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
    </PMAContainer>
  );
}

function UnitCompleted() {
  return (
    <Stack
      flex={1}
      sx={{
        borderRadius: '8px',
        border: `solid 1px ${hootTokens.palette.black}`,
        padding: '24px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        padding="16px 0px 16px 0px"
        sx={{
          backgroundColor: hootTokens.surface[2],
          borderRadius: '8px',
        }}
      >
        <UnitCompleteCard hasBeenSubmitted={false} />
      </Stack>
    </Stack>
  );
}

function WordPrompt(props: {
  expectedResponse: string;
  sentence: string;
  wordsToBold: string;
  onCorrectClick: () => void;
  onIncorrectClick: () => void;
}) {
  const { expectedResponse, sentence, wordsToBold, onCorrectClick, onIncorrectClick } = props;

  // Emphasizes the words within the full prompt.
  const formatFullPrompt = (): JSX.Element => {
    const wordsToBoldArray = wordsToBold.split(/\s/);
    const wordsFromSentence = sentence.split(/\s/);

    return (
      <HootTypography variant="displaysmall" textAlign="center" fontWeight={400} isPII={false}>
        {wordsFromSentence.map((w, idx) => (
          <Fragment key={`${w}-${idx}`}>
            {wordsToBoldArray.includes(w.replace(/[\.,?!]/g, '')) ? <span style={{ fontWeight: 700 }}>{`${w} `}</span> : `${w} `}
          </Fragment>
        ))}
      </HootTypography>
    );
  };

  return (
    <Stack
      flex={1}
      sx={{
        borderRadius: '8px',
        border: `solid 1px ${hootTokens.palette.black}`,
        padding: '24px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        padding="16px 0px 16px 0px"
        sx={{
          backgroundColor: hootTokens.surface[2],
          borderRadius: '8px',
          textAlign: 'center',
          px: 1,
          py: 2,
        }}
      >
        <HootTypography variant="headlinesmall" isPII={false}>
          {formatFullPrompt()}
        </HootTypography>
      </Stack>
      <Stack marginTop="16px" alignItems="center">
        <HootTypography variant="labelsmall" isPII={false}>
          Expected Response
        </HootTypography>
        <Box
          sx={{
            backgroundColor: hootTokens.surface[2],
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          <HootTypography variant="labelsmall" isPII={false}>
            {expectedResponse}
          </HootTypography>
        </Box>
      </Stack>
      <Stack marginTop="16px" direction="row" justifyContent="center" spacing="16px">
        <Button color="error.190" startIcon={<Icon name="close_filled_square" />} variant="contained" onClick={onIncorrectClick}>
          Incorrect
        </Button>
        <Button
          color="success.190"
          startIcon={<Icon htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />}
          variant="contained"
          onClick={onCorrectClick}
        >
          Correct
        </Button>
      </Stack>
    </Stack>
  );
}

function ScoreCard(props: {
  prompts: PMAQuestionnaireLayout_1_Prompts[];
  answers: PMAStepTestAnswer[];
  currentIndex: number;
  onRowClick: (index: number) => void;
}) {
  const { prompts, answers, currentIndex, onRowClick } = props;
  return (
    <Stack marginLeft="120px" flex={1} padding="24px" spacing="24px">
      <HootTypography variant="tableheadingactive" isPII={false}>
        SCORECARD
      </HootTypography>
      {prompts.map((w, idx) => (
        <ScoreCardRow key={w.word} index={idx + 1} isCurrentWord={idx === currentIndex} word={w.word} answer={answers[idx]} onClick={onRowClick} />
      ))}
    </Stack>
  );
}

function ScoreCardRow(props: { index: number; isCurrentWord: boolean; word: string; answer: PMAStepTestAnswer; onClick: (index: number) => void }) {
  const { index, isCurrentWord, word, answer, onClick } = props;
  const handleRowClick = () => {
    onClick(index - 1);
  };

  function Prefix() {
    if (isCurrentWord) {
      return <Icon sx={{ height: '16px' }} name="play" />;
    }

    return `${index}.`;
  }

  function PostFixIcon() {
    switch (answer) {
      case PMAStepTestAnswer.NoAnswer:
        return null;
      case PMAStepTestAnswer.Correct:
        return <Icon sx={{ height: '24px', width: '24x' }} htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />;
      case PMAStepTestAnswer.Wrong:
        return <Icon sx={{ height: '20px', width: '20px' }} htmlColor={hootTokens.palette.error[80]} name="close_filled_square" />;
    }
  }

  return (
    <Stack
      sx={{ cursor: answer !== PMAStepTestAnswer.NoAnswer ? 'pointer' : undefined }}
      direction="row"
      alignItems="center"
      spacing="16px"
      onClick={answer !== PMAStepTestAnswer.NoAnswer ? handleRowClick : undefined}
    >
      <HootTypography
        sx={{ color: answer === PMAStepTestAnswer.NoAnswer ? hootTokens.palette.neutral[140] : hootTokens.palette.black }}
        variant="bodylarge"
        isPII={false}
      >
        <Prefix /> {word}
      </HootTypography>
      <PostFixIcon />
    </Stack>
  );
}
