import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import PublicRouterLayoutV2 from '@hoot/ui/components/v2/layout/PublicRouterLayout';
import ChangePasswordV2 from '@hoot/ui/pages/v2/public/change-password/ChangePasswordV2';
import DistrictLoginPage from '@hoot/ui/pages/v2/public/login/DistrictLoginPage';
import LoginPage from '@hoot/ui/pages/v2/public/login/LoginPage';

const PublicRouter = () => {
  const location = useLocation();

  interface ExternalRedirectProps {
    to: string;
  }

  const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };

  return (
    <>
      <Routes>
        <Route element={<PublicRouterLayoutV2 />}>
          <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
          <Route path={routesDictionary.changePassword.path} element={<ChangePasswordV2 />} />
          <Route path={routesDictionary.login.path}>
            <Route path={routesDictionary.login.district.path} element={<DistrictLoginPage />} />
            <Route index element={<LoginPage />} />
          </Route>
          <Route path={routesDictionary.freeLesson.path} element={<ExternalRedirect to={routesDictionary.getInTouch.url} />} />
          {/*TODO: As part of the B2C Revamp project, we'll likely be removing the free lesson flow from the app.*/}
          {/*For now I'm just going to disable the routes, and we can clean everything up*/}
          {/*in the app later once Hubspot flows are setup.*/}

          {/*<Route path={routesDictionary.freeLesson.path} element={<FreeLessonRegistrationLayout />}>*/}
          {/*  <Route path={routesDictionary.freeLesson.registration.path} element={<FreeLessonRegistrationWizard />} />*/}
          {/*  <Route path={routesDictionary.freeLesson.login.path} element={<FreeLessonLogin />} />*/}
          {/*  <Route index element={<FreeLessonSignUp />} />*/}
          {/*</Route>*/}
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={routesDictionary.login.url}
              state={{
                redirectPath: location.pathname,
                redirectSearch: location.search,
              }}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

export default PublicRouter;
