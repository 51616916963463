import { TLRecord } from '@tldraw/tlschema';
import { isEqual } from 'lodash';
import {
  Editor,
  HistoryEntry,
  RecordId,
  TLDrawShape,
  TLDrawShapeProps,
  TLGeoShape,
  TLLineShapeProps,
  TLShape,
  TLShapeId,
  TLStore,
  TLUnknownShape,
  VecModel,
} from 'tldraw';
import { WhiteboardDelta } from '@hoot/events/interfaces/whiteboard-delta';

/**
 * Essentially converts a TLDraw HistoryEntry into compressed payload with only the values that we really care about.
 * This is somewhat of a rudimentary implementation, but our whiteboard only supports limited tools, so for our
 * use-case, this is OK. If we start to support more tools going forward, this implementation should probably be improved.
 */
export const assembleWhiteboardDelta = (historyEntry: HistoryEntry<TLRecord>): WhiteboardDelta | undefined => {
  if (historyEntry.source !== 'user') return undefined;

  const { added, updated, removed } = historyEntry.changes;

  // Assemble new shapes added. For these, we need to send the entire object, but just this one time.
  const newPutRecords: Record<RecordId<TLRecord>, TLRecord> = { ...added };

  // Assemble all shape IDs removed.
  const newRemoveIds = Object.values(removed).map((x) => x.id);

  // Initialize patched shapes as an empty object for now. We'll assemble these further down.
  const newPatchedShapes: Record<TLShapeId, Partial<TLShape>> = {};
  const newPatchedDrawShapes: Record<
    TLShapeId,
    {
      drawShapeDiff: Partial<TLDrawShape>;
      drawPropsDiff: Partial<TLDrawShapeProps>;
      appendPoints: VecModel[] | undefined;
      replacePoints: VecModel[] | undefined;
    }
  > = {};

  // For each _updated_ (patch) shape, we need to figure out how exactly to send this over the wire. We don't just simply
  // want to send the entire shape b/c there is unnecessary overhead with that approach. We only want to send the
  // properties of the shape that have changed; we only want to send the diff.
  Object.values(updated).forEach(([prev, next]) => {
    if (prev.typeName === 'shape' && next.typeName === 'shape') {
      // If we're modifying a draw shape made up of just one line segment (shapes drawn with the pencil are only ever made up of a single line segment)...
      if (
        (prev as TLShape).type === 'draw' &&
        (next as TLShape).type === 'draw' &&
        (prev as TLDrawShape).props.segments.length === 1 &&
        (next as TLDrawShape).props.segments.length === 1
      ) {
        const prevDrawShape = prev as TLDrawShape;
        const nextDrawShape = next as TLDrawShape;

        // Get the diff of the shape from what it was to what it is now.
        const drawShapeDiff = shallowDiff(prevDrawShape, nextDrawShape);
        const drawPropsDiff = shallowDiff(prevDrawShape.props, nextDrawShape.props);

        let appendPoints: VecModel[] | undefined = undefined;
        let replacePoints: VecModel[] | undefined = undefined;

        // If the prev draw shape has fewer points than the new draw shape, then just send the new points over the wire.
        if (prevDrawShape.props.segments[0].points.length < nextDrawShape.props.segments[0].points.length) {
          appendPoints = nextDrawShape.props.segments[0].points.slice(prevDrawShape.props.segments[0].points.length);
        } else if (!isEqual(prevDrawShape.props.segments[0].points, nextDrawShape.props.segments[0].points)) {
          // Else if the points are just totally different, then we have to send all points (this can happen when we resize a draw shape).
          replacePoints = nextDrawShape.props.segments[0].points;
        }
        newPatchedDrawShapes[nextDrawShape.id] = {
          drawShapeDiff,
          drawPropsDiff,
          appendPoints,
          replacePoints,
        };
      } else if (Object.keys(prev).length === Object.keys(next).length && Object.keys(prev.props).length === Object.keys(next.props).length) {
        // Else if the modified shape is _not_ a draw shape, and it has the same number of keys as it did previously,
        // then just send the delta (this will be executed for all shape translations and resizes (excluding draw shape resizes; all points are
        // actually modified in this case).
        const shapeDiff = shallowDiff(prev, next);
        const propsDiff = shallowDiff(prev.props, next.props);

        // Make sure we're handling LineShape points if modified.
        if (next.type === 'line') {
          (propsDiff as Partial<TLLineShapeProps>).points = { ...(next.props as TLLineShapeProps).points };
        }
        newPatchedShapes[next.id] = {
          ...shapeDiff,
          props: propsDiff,
        };
      } else {
        console.warn(
          `Unsupported shape diff of type ${next.type}! If you see this message, it means we forgot to handle converting this shape into a much smaller payload to send over the wire.`,
        );
        // For all other shape mods, send the full shape.
        newPutRecords[next.id] = next;
      }
    } else {
      // For all other record mods that aren't shapes, send the full record (we _should_ only be dealing with shape records for our use case with the WB though).
      newPutRecords[next.id] = next;
    }
  });
  return {
    put: newPutRecords,
    remove: newRemoveIds,
    shapePatch: newPatchedShapes,
    drawShapePatch: newPatchedDrawShapes,
  };
};

export const mergeIncomingChanges = (store: TLStore, changes: WhiteboardDelta) => {
  try {
    store.mergeRemoteChanges(() => {
      // Apply changes to the whiteboard.
      const { put, shapePatch, drawShapePatch, remove } = changes;

      // Handle new records.
      for (const record of Object.values(put)) {
        store.put([record]);
      }
      // Handle patched shapes
      for (const [id, patch] of Object.entries(shapePatch)) {
        store.update(id as TLShapeId, (existingShape) => {
          return {
            ...existingShape,
            ...patch,
            props: {
              ...existingShape.props,
              ...patch.props,
            },
          };
        });
      }
      // Handle patched DrawShapes.
      for (const [id, patch] of Object.entries(drawShapePatch)) {
        store.update(id as TLShapeId, (record) => {
          if (!record || record.typeName !== 'shape') {
            console.error(`Error appending draw points. Record ID ${record?.id} is not a shape; it has a typeName of "${record?.typeName}".`);
            return record;
          }
          const shape = record as TLShape;
          if (shape.type !== 'draw') {
            console.error(`Error appending draw points. Shape ID ${shape.id} is not a DrawShape; it has a type of "${shape.type}".`);
            return record;
          }
          const existingDrawShape = shape as TLDrawShape;
          if (existingDrawShape.props.segments.length !== 1) {
            console.error(`Error appending draw points. DrawShape ID ${shape.id} has more than one line segment.`);
            return record;
          }
          const { drawShapeDiff, drawPropsDiff, replacePoints, appendPoints } = patch;

          const points = replacePoints ?? [...existingDrawShape.props.segments[0].points, ...(appendPoints ?? [])];
          const updatedDrawShape: TLDrawShape = {
            ...existingDrawShape,
            ...drawShapeDiff,
            props: {
              ...existingDrawShape.props,
              ...drawPropsDiff,
              segments: [
                {
                  type: existingDrawShape.props.segments[0].type,
                  points,
                },
              ],
            },
          };
          return updatedDrawShape;
        });
      }
      // Handle removed records.
      for (const id of Object.values(remove)) {
        store.remove([id]);
      }
    });
  } catch (e) {
    // Should probably let the user know something went wrong, and allow manually "refreshing" the whiteboard.
    console.error('Tldraw merge error.', e);
  }
};

export const keepDrawPointsInsideFrame = <T extends TLDrawShape>(drawShape: T, frame: TLGeoShape): T => {
  // The min and max coordinates of the frame in absolute space.
  const frameBoundsAbsMinX = frame.x;
  const frameBoundsAbsMaxX = frame.x + frame.props.w;
  const frameBoundsAbsMinY = frame.y;
  const frameBoundsAbsMaxY = frame.y + frame.props.h;

  // The starting position of the entire modified shape in absolute space.
  let shapeAbsOriginX = drawShape.x;
  let shapeAbsOriginY = drawShape.y;

  const updatedSegments = drawShape.props.segments.map((segment) => {
    // For each segment, ensure that last drawn point is within bounds.
    const points = [...segment.points];

    // If there aren't any points in this segment, then bail.
    if (!points?.length || points.length < 1) return segment;

    const lastRelPoint = points[points.length - 1];

    // Based on the origin, figure out what the min and max relative coordinates can be.
    const relMaxX = frameBoundsAbsMaxX - shapeAbsOriginX;
    const relMinX = frameBoundsAbsMinX - shapeAbsOriginX;
    const relMaxY = frameBoundsAbsMaxY - shapeAbsOriginY;
    const relMinY = frameBoundsAbsMinY - shapeAbsOriginY;

    // Ensure the last point is within bounds.
    points[points.length - 1] = {
      x: lastRelPoint.x > relMaxX ? relMaxX : lastRelPoint.x < relMinX ? relMinX : lastRelPoint.x,
      y: lastRelPoint.y > relMaxY ? relMaxY : lastRelPoint.y < relMinY ? relMinY : lastRelPoint.y,
      z: lastRelPoint.z,
    };
    return {
      ...segment,
      points,
    };
  });
  return {
    ...drawShape,
    props: {
      ...drawShape.props,
      segments: updatedSegments,
    },
  };
};

export const keepShapeInsideFrame = <T extends TLUnknownShape>(shape: T, frame: TLGeoShape, editor: Editor): T => {
  // The min and max coordinates of the frame in absolute space.
  const frameBoundsAbsMinX = frame.x;
  const frameBoundsAbsMaxX = frame.x + frame.props.w;
  const frameBoundsAbsMinY = frame.y;
  const frameBoundsAbsMaxY = frame.y + frame.props.h;

  // The starting position of the entire modified shape in absolute space.
  let shapeAbsOriginX = shape.x;
  let shapeAbsOriginY = shape.y;

  const shapeUtils = editor.getShapeUtil(shape);
  const shapeGeometry = shapeUtils.getGeometry(shape);
  const shapeRelBounds = shapeGeometry.getBounds();

  const shapeAbsMinX = shapeAbsOriginX + shapeRelBounds.minX;
  const shapeAbsMaxX = shapeAbsOriginX + shapeRelBounds.maxX;
  const shapeAbsMinY = shapeAbsOriginY + shapeRelBounds.minY;
  const shapeAbsMaxY = shapeAbsOriginY + shapeRelBounds.maxY;

  let newAbsOriginX = shapeAbsOriginX;
  let newAbsOriginY = shapeAbsOriginY;

  // If shape has gone past the LEFT boundary
  if (shapeAbsMinX < frameBoundsAbsMinX) {
    newAbsOriginX = frameBoundsAbsMinX - shapeRelBounds.minX;
  }
  // If shape has gone past the RIGHT boundary
  if (shapeAbsMaxX > frameBoundsAbsMaxX) {
    newAbsOriginX = frameBoundsAbsMaxX - shapeRelBounds.maxX;
  }
  // If shape has gone past the TOP boundary
  if (shapeAbsMinY < frameBoundsAbsMinY) {
    newAbsOriginY = frameBoundsAbsMinY - shapeRelBounds.minY;
  }
  // If shape has gone past the BOTTOM boundary
  if (shapeAbsMaxY > frameBoundsAbsMaxY) {
    newAbsOriginY = frameBoundsAbsMaxY - shapeRelBounds.maxY;
  }
  return {
    ...shape,
    x: newAbsOriginX,
    y: newAbsOriginY,
  };
};

/**
 * Returns all key-values pairs in next that are different from prev.
 * Don't export this function!
 */
const shallowDiff = <T extends {}>(prev: T, next: T): Partial<T> => {
  if (prev === next) {
    return {};
  }
  let result: Partial<T> = {};
  Object.entries(next).forEach(([key, nextVal]) => {
    // Ignore objects and arrays.
    if (Array.isArray(nextVal) || typeof nextVal === 'object') {
      return;
    }
    const prevVal = (prev as any)[key];
    if (!isEqual(prevVal, nextVal)) {
      (result as any)[key] = nextVal;
    }
  });
  return result;
};
