import { SvgIcon, SvgIconProps } from '@mui/material';

const HootReadingAssessmentIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="black" />
    <path d="M15.54 7.576V16H13.488V12.532H10.296V16H8.244V7.576H10.296V10.876H13.488V7.576H15.54Z" fill="white" />
  </SvgIcon>
);

export default HootReadingAssessmentIcon;
