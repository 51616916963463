import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, IconButton, Stack, Typography, keyframes, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { hootTokens } from '../../../theme/v2/tokens';
import { Button } from '../core/Button';
import { Checkbox } from '../core/Checkbox';
import { Icon } from '../core/Icon';

/** ENUMS */

const _AVAILABILITY_EXCEPTION_COLOR = hootTokens.palette.warning[160];
const AVAILABILITY_COLOR = hootTokens.palette.neutral[160];
const SCHEDULED_COLOR = hootTokens.palette.secondary[160];
const CANCELLED_COLOR = hootTokens.palette.error[120];
const COMPLETED_COLOR = hootTokens.palette.success[160];

const TIME_FORMAT = 'h:mm a';

const enum ZIndexLayer {
  Bottom = 100,
  Middle = 200,
  Top = 300,
  Header = 400,
}

/** INTERFACES  */

type ShiftStatus = 'SCHEDULED' | 'COMPLETED' | 'CANCELLED' | 'PUBLISHED';

export interface SelectedItem {
  ids: string[];
  type: 'LESSON' | 'SHIFT';
}

export interface ShiftTimeSlot {
  id: string;
  startAt: DateTime;
  endsAt: DateTime;
  status: ShiftStatus;
}

export interface AvailabilityTimeSlot {
  startAt: string;
  endAt: string;
  zone: string;
  dayOfWeek: number;
}

export interface LessonTimeSlot {
  id: string;
  prefixedStudentNumber: string;
  prefixedLessonNumber: string;
  startAt: DateTime;
  endsAt: DateTime;
  status: ScheduledLessonStatus;
}

export interface AvailabilityExceptionSlot {
  startsAt: DateTime;
  endsAt: DateTime;
}

/** CONSTANTS */

const calendarYAxisLabels = [
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
];

const HEADER_HEIGHT = 58;
const CELL_HEIGHT = 64;

const COLUMN_HEIGHT = CELL_HEIGHT * calendarYAxisLabels.length;

const ONE_MINUTE_PX_HEIGHT = CELL_HEIGHT / 30;

/** COMPONENTS */

export function WeeklySchedule(props: {
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  availability: AvailabilityTimeSlot[];
  availabiltyExceptions?: AvailabilityExceptionSlot[];
  shifts: ShiftTimeSlot[];
  lessons: LessonTimeSlot[];
  selectedItem?: SelectedItem;
  onSelected: (dayOfWeek: DateTime) => void;
  onNewEventClick?: () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
  isLoading?: boolean;
}) {
  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map((d) => props.startOfWeek.plus({ day: d }));

  const [showMyAvailability, setShowMyAvailability] = useState(true);
  const [useStudentNumbers, setUseStudentNumbers] = useState(false);

  const onPrevClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.minus({ week: 1 }));
  };

  const onNextClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.plus({ week: 1 }));
  };

  const handleShowMyAvailabilityClick = () => {
    setShowMyAvailability((currentState) => !currentState);
  };

  const handleUseStudentNumbersClick = () => {
    setUseStudentNumbers((currentState) => !currentState);
  };

  const lessons = props.showCancelledEvents
    ? props.lessons
    : props.lessons.filter((l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.status));

  const title = `${props.startOfWeek.toFormat('LLL d')} - ${props.startOfWeek.plus({ day: 6 }).toFormat('d')}`;

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Header title={title} daysOfWeek={daysOfWeek} onPrevClick={onPrevClick} onNextClick={onNextClick} onNewEventClick={props.onNewEventClick} />
      <Stack position="relative" direction="row">
        {props.isLoading ? <LoadingAnimation /> : null}
        <YAxisLabels />

        {daysOfWeek.map((dayOfWeek, idx) => (
          <TimeSlots
            selectedItem={props.selectedItem}
            onSelected={props.onSelected}
            dayOfWeek={dayOfWeek}
            idx={idx}
            availability={showMyAvailability ? props.availability : []}
            availabilityExceptions={props.availabiltyExceptions}
            lessons={lessons}
            shifts={props.shifts}
            useStudentNumbers={useStudentNumbers}
          />
        ))}
      </Stack>
      <Footer
        showCancelledEvents={props.showCancelledEvents}
        showMyAvailability={showMyAvailability}
        useStudentNumbers={useStudentNumbers}
        onShowCancelledEventsClick={props.onShowCancelledEventsClick}
        onShowMyAvailabiltyClick={handleShowMyAvailabilityClick}
        onUseStudentNumbersClick={handleUseStudentNumbersClick}
      />
    </Stack>
  );
}

function Header(props: { title: string; onPrevClick: () => void; onNextClick: () => void; daysOfWeek: DateTime[]; onNewEventClick?: () => void }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileHeader {...props} />;
  } else {
    return <DesktopHeader {...props} />;
  }
}

function DesktopHeader(props: {
  title: string;
  onPrevClick: () => void;
  onNextClick: () => void;
  daysOfWeek: DateTime[];
  onNewEventClick?: () => void;
}) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: hootTokens.palette.white,
        zIndex: ZIndexLayer.Header,
        boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
      }}
    >
      <Stack justifyContent="center" direction="row">
        <Box flex={1} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <IconButton onClick={props.onPrevClick}>
            <Icon name="chevron" />
          </IconButton>
          <Typography variant="displaysmall">{props.title}</Typography>
          <IconButton onClick={props.onNextClick}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" flex={1}>
          {props.onNewEventClick ? (
            <Button onClick={props.onNewEventClick} variant="contained" startIcon={<Icon htmlColor={hootTokens.palette.white} name="add" />}>
              New Event
            </Button>
          ) : undefined}
        </Stack>
      </Stack>
      <Stack marginTop="32px" flex={1} direction="row" height={`${HEADER_HEIGHT}px`}>
        <Stack flex={1} />
        {props.daysOfWeek.map((dayOfWeek) => (
          <HeaderLabel dayOfWeek={dayOfWeek} />
        ))}
      </Stack>
    </Stack>
  );
}

function MobileHeader(props: {
  title: string;
  onPrevClick: () => void;
  onNextClick: () => void;
  daysOfWeek: DateTime[];
  onNewEventClick?: () => void;
}) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: hootTokens.palette.white,
        zIndex: ZIndexLayer.Header,
        boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <IconButton onClick={props.onPrevClick}>
            <Icon name="chevron" />
          </IconButton>
          <Typography variant="titlelarge">{props.title}</Typography>
          <IconButton onClick={props.onNextClick}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack marginTop="32px" flex={1} direction="row" height={`${HEADER_HEIGHT}px`}>
        <Stack flex={1} />
        {props.daysOfWeek.map((dayOfWeek) => (
          <HeaderLabel dayOfWeek={dayOfWeek} />
        ))}
      </Stack>
    </Stack>
  );
}

function HeaderLabel(props: { dayOfWeek: DateTime }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isToday = props.dayOfWeek.toISODate() === DateTime.now().toISODate();

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      {isMobile ? (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '4px' : undefined,
            borderRadius: isToday ? '4px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('dd').toUpperCase()}</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '16px' : undefined,
            borderRadius: isToday ? '8px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('LLL dd').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

function Footer(props: {
  showMyAvailability: boolean;
  showCancelledEvents: boolean;
  useStudentNumbers: boolean;
  onShowMyAvailabiltyClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onShowCancelledEventsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onUseStudentNumbersClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Checkbox label="Show My Availability" checked={props.showMyAvailability} onClick={props.onShowMyAvailabiltyClick} />
        <Checkbox label="Show Cancelled Events" checked={props.showCancelledEvents} onClick={props.onShowCancelledEventsClick} />
        <Checkbox label="Use Student Numbers" checked={props.useStudentNumbers} onClick={props.onUseStudentNumbersClick} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} marginTop={isMobile ? '16px' : undefined} spacing="16px">
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={AVAILABILITY_COLOR} />
          <Typography variant="bodysmall"> Availability</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={SCHEDULED_COLOR} />
          <Typography variant="bodysmall">Published/Scheduled</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={COMPLETED_COLOR} />
          <Typography variant="bodysmall">Completed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={CANCELLED_COLOR} />
          <Typography variant="bodysmall">Cancelled</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function Rectangle(props: { color: string }) {
  return <Box sx={{ height: '15px', width: '15px', borderRadius: '4px', backgroundColor: props.color }} />;
}

function YAxisLabels() {
  return (
    <Column>
      {calendarYAxisLabels.map((yAxisLabel) => (
        <TimeSlot justifyContent="center" alignItems="center">
          <Typography variant="bodysmall">{yAxisLabel}</Typography>{' '}
        </TimeSlot>
      ))}
    </Column>
  );
}

function TimeSlots(props: {
  dayOfWeek: DateTime;
  idx: number;
  availability?: AvailabilityTimeSlot[];
  availabilityExceptions?: AvailabilityExceptionSlot[];
  shifts?: ShiftTimeSlot[];
  lessons?: LessonTimeSlot[];
  selectedItem?: SelectedItem;
  onSelected: (dayOfWeek: DateTime) => void;
  useStudentNumbers: boolean;
}) {
  const endOfDay = props.dayOfWeek.endOf('day');

  const shifts = props.shifts?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const lessons = props.lessons?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const availabilty = props.availability?.filter((a) => a.dayOfWeek === props.dayOfWeek.weekday) ?? [];
  const availabilityExceptions = props.availabilityExceptions?.filter((s) => s.startsAt >= props.dayOfWeek && s.startsAt <= endOfDay) ?? [];

  const overlappingLessons = findOverlappingTimeslots(lessons);

  const handleTimeslotClick = () => {
    props.onSelected(props.dayOfWeek);
  };

  return (
    <Column>
      {availabilty.map((a) => (
        <Availability key={`${a.startAt}-${a.endAt}-${a.dayOfWeek}`} startsAt={a.startAt} endsAt={a.endAt} zone={a.zone} />
      ))}
      {availabilityExceptions?.map((ae) => (
        <AvailabilityException startsAt={ae.startsAt.toFormat(TIME_FORMAT)} endsAt={ae.endsAt.toFormat(TIME_FORMAT)} />
      ))}
      {shifts.map((s) => (
        <Shift
          key={s.id}
          id={s.id}
          selectedItem={props.selectedItem}
          startsAt={s.startAt.toFormat(TIME_FORMAT)}
          endsAt={s.endsAt.toFormat(TIME_FORMAT)}
          status={s.status}
        />
      ))}
      {overlappingLessons.map((l) => (
        <Lesson
          key={l.map((lesson) => lesson.id).join('-')}
          dayOfWeek={props.dayOfWeek}
          selectedItem={props.selectedItem}
          onSelected={props.onSelected}
          timeslots={l}
          useStudentNumbers={props.useStudentNumbers}
        />
      ))}

      {calendarYAxisLabels.map((y, idx) => (
        <TimeSlot key={`${idx}-${y}`} onClick={handleTimeslotClick} />
      ))}
    </Column>
  );
}

function Availability(props: { startsAt: string; endsAt: string; zone: string }) {
  const startTime = DateTime.fromFormat(props.startsAt, TIME_FORMAT, { zone: props.zone });
  const endsAt = DateTime.fromFormat(props.endsAt, TIME_FORMAT, { zone: props.zone });

  const top = Math.max(convertTimeToOffset(startTime.toFormat(TIME_FORMAT)), 0);
  const height = convertTimeToOffset(endsAt.toFormat(TIME_FORMAT)) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          borderRadius: '4px',
          height: '100%',
          width: '100%',
          backgroundColor: hootTokens.palette.neutral['180'],
          border: `solid 2px ${hootTokens.palette.black}`,
        }}
      />
    </Stack>
  );
}

function AvailabilityException(props: { startsAt: string; endsAt: string }) {
  const startTime = DateTime.fromFormat(props.startsAt, TIME_FORMAT);
  const endsAt = DateTime.fromFormat(props.endsAt, TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(startTime.toFormat(TIME_FORMAT)), 0);
  const height = convertTimeToOffset(endsAt.toFormat(TIME_FORMAT)) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          borderRadius: '4px',
          height: '100%',
          width: '100%',
          backgroundColor: hootTokens.palette.error['180'],
          border: `solid 2px ${hootTokens.palette.error['100']}`,
        }}
      />
    </Stack>
  );
}

function Shift(props: { id: string; startsAt: string; endsAt: string; status: ShiftStatus; selectedItem?: SelectedItem }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const top = Math.max(convertTimeToOffset(props.startsAt), 0);
  const height = convertTimeToOffset(props.endsAt) - top;

  const isSelected = props.selectedItem?.ids.some((id) => id === props.id) && props.selectedItem.type === 'SHIFT';

  return (
    <Stack
      sx={{
        position: 'absolute',
        left: isMobile ? '8px' : '16px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        zIndex: ZIndexLayer.Middle,
      }}
    >
      <Stack
        sx={{
          backgroundColor: shiftColor(props.status),
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          width: '8px',
          borderRadius: '4px',
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
        }}
      />

      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '8px',
            borderRadius: '4px',
            border: '3px solid #CBA82C',
            animation: `${pulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

function Lesson(props: {
  dayOfWeek: DateTime;
  selectedItem?: SelectedItem;
  onSelected: (dayOfWeek: DateTime) => void;
  timeslots: LessonTimeSlot[];
  useStudentNumbers: boolean;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const minStartsAt = DateTime.fromMillis(Math.min(...props.timeslots.map((ts) => ts.startAt.toMillis()))).toFormat(TIME_FORMAT);
  const maxEndsAt = DateTime.fromMillis(Math.max(...props.timeslots.map((ts) => ts.endsAt.toMillis()))).toFormat(TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(minStartsAt), 0);
  const height = convertTimeToOffset(maxEndsAt) - top;

  const handleClick = () => {
    props.onSelected(props.dayOfWeek);
  };

  const isSelected =
    props.selectedItem && props.selectedItem.ids.some((id) => props.timeslots.some((i) => i.id === id)) && props.selectedItem.type === 'LESSON';

  function label() {
    if (isMobile) {
      return undefined;
    }
    if (props.timeslots.length === 1) {
      const timeslot = props.timeslots[0];
      return props.useStudentNumbers ? timeslot.prefixedStudentNumber : timeslot.prefixedLessonNumber;
    } else {
      return `Multiple (${props.timeslots.length})`;
    }
  }

  function backgroundColor() {
    if (props.timeslots.length === 1) {
      return lessonColor(props.timeslots[0].status);
    } else {
      if (
        props.timeslots.some(
          (ts) => ts.status === ScheduledLessonStatus.CompletedSuccessfully || ts.status === ScheduledLessonStatus.CompletedUnsuccessfully,
        )
      ) {
        return lessonColor(ScheduledLessonStatus.CompletedSuccessfully);
      } else if (
        props.timeslots.some(
          (ts) =>
            ts.status === ScheduledLessonStatus.InProgress ||
            ts.status === ScheduledLessonStatus.Open ||
            ts.status === ScheduledLessonStatus.OpenWaitingForStudent ||
            ts.status === ScheduledLessonStatus.OpenWaitingForTeacher ||
            ts.status === ScheduledLessonStatus.Scheduled,
        )
      ) {
        return lessonColor(ScheduledLessonStatus.Scheduled);
      } else {
        return lessonColor(ScheduledLessonStatus.Cancelled);
      }
    }
  }

  function backgroundImage() {
    if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Cancelled)) {
      if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Scheduled)) {
        return `-webkit-linear-gradient(30deg, ${lessonColor(ScheduledLessonStatus.Scheduled)} 50%, ${lessonColor(
          ScheduledLessonStatus.Cancelled,
        )} 50%);`;
      }
    }

    return undefined;
  }

  return (
    <Stack
      onClick={handleClick}
      sx={{
        position: 'absolute',
        zIndex: ZIndexLayer.Middle,
        left: '32px',
        right: '16px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        cursor: 'pointer',
      }}
    >
      <Stack
        sx={{
          paddingX: '8px',
          borderRadius: '8px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          color: hootTokens.palette.black,
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          backgroundColor: backgroundColor(),
          backgroundImage: backgroundImage(),
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
        }}
      >
        <Typography variant="bodysmall">{label()}</Typography>
      </Stack>
      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '70px',
            borderRadius: '8px',
            border: '3px solid #CBA82C',
            animation: `${pulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

export function lessonColor(status: ScheduledLessonStatus): string {
  if (status === ScheduledLessonStatus.Cancelled || status === ScheduledLessonStatus.Rescheduled) {
    return hootTokens.palette.error[120];
  } else if (status === ScheduledLessonStatus.CompletedSuccessfully || status === ScheduledLessonStatus.CompletedUnsuccessfully) {
    return hootTokens.palette.success[120];
  } else {
    return hootTokens.palette.secondary[120];
  }
}

export function shiftColor(status: ShiftStatus): string {
  switch (status) {
    case 'CANCELLED':
      return hootTokens.palette.error[120];
    case 'COMPLETED':
      return hootTokens.palette.success[120];
    case 'SCHEDULED':
    case 'PUBLISHED':
      return hootTokens.palette.secondary[120];
  }
}

const TimeSlot = styled(Stack)({
  boxSizing: 'border-box',
  height: `${CELL_HEIGHT}px`,
  boxShadow: '-1px -1px #757575, inset -1px -1px 0 0 #757575',
  zIndex: ZIndexLayer.Bottom,
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Column = styled(Stack)({
  position: 'relative',
  height: `${COLUMN_HEIGHT}px`,
  flex: 1,
  justifyContent: 'space-evenly',
});

const pulseAnimation = keyframes`
    0% {
      opacity: 1;
      transform: scale(1);
    }
    80% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  `;

const shimmerAnimation = keyframes`
  to {
     background-position-x: 0%
  }
`;

const LoadingAnimation = styled(Stack)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(-45deg, ${hootTokens.palette.neutral['180']} 60%, ${hootTokens.palette.neutral['140']} 70%, ${hootTokens.palette.neutral['180']} 80%)`,
  backgroundSize: '300%',
  backgroundPositionX: '100%',
  animation: `${shimmerAnimation} 1s infinite linear`,
});

// since we don't start at midnight, this will offset the time based on the first value in calendarYAxisLabels
// Note: if we don't set the zone in here, the Settings default runs after this is set, so it will be in the user's timezone instead of Winnipeg
const timeOffset = DateTime.fromFormat(`1970-01-01 ${calendarYAxisLabels[0]}`, 'yyyy-LL-dd h:mm a').toMillis();
const topBoundary = DateTime.fromFormat(`1970-01-01 ${calendarYAxisLabels.slice(-1)[0]}`, 'yyyy-LL-dd h:mm a').plus({ minutes: 30 }); // need to go to the end of the slot, so adding extra 30 minutes

// this function calculates the offset from top of the timeslots container
// i.e: 8:00 AM ->    0
//      8:01 AM ->    1
//      8:30 AM ->   30
//      9:00 AM ->   60
//      12:00 AM -> 240
export function convertTimeToOffset(time: string): number {
  const dt = DateTime.fromFormat(`1970-01-01 ${time}`, 'yyyy-LL-dd h:mm a');

  if (dt > topBoundary) {
    return (topBoundary.toMillis() - timeOffset) / 60000;
  }

  return (dt.toMillis() - timeOffset) / 60000;
}

// This function will find all lessons that overlap with each other and group them together
// i.e: 10:00 AM -> 10:20 AM
// i.e: 10:10 AM -> 10:40 AM
// i.e: 10:30 AM -> 10:50 AM
// Would be considered 1 group since 10:10 -> 10:40 overlaps 10:00 and 10:20 AM
export function findOverlappingTimeslots(timeslots: LessonTimeSlot[]) {
  const buckets: LessonTimeSlot[][] = [];
  for (const timeslot of timeslots.sort((a, b) => (a.startAt < b.startAt ? -1 : 1))) {
    const bucket = buckets.find((bucket) =>
      bucket.some(
        (ts) =>
          isBetween(ts.startAt.toMillis(), timeslot.startAt.toMillis(), timeslot.endsAt.toMillis()) ||
          isBetween(timeslot.startAt.toMillis(), ts.startAt.toMillis(), ts.endsAt.toMillis()),
      ),
    );

    if (bucket) {
      bucket.push(timeslot);
    } else {
      buckets.push([timeslot]);
    }
  }

  return buckets;
}

function isBetween(val: number, lower: number, upper: number) {
  return val >= lower && val <= upper;
}
