import { HootSubmissionDataField } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/hoot-submission-data-field';
import { HootSubmissionDataFieldV2 } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/hoot-submission-data-field-v2';
import { Box } from '@mui/material';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useGetAssessmentBook from '@hoot/hooks/api/library/useGetAssessmentBook';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

const wordCountSubmissionKeys = [
  HootSubmissionDataField.U_4_1A_V1_WordCount.toString(),
  HootSubmissionDataField.U_4_1A_V2_WordCount.toString(),
  HootSubmissionDataFieldV2.U_4_1_WordCount,
];

interface BookAutoSuggestProps {
  label: string;
  value: BookSearch;
  watching: Record<string, any>;
  setValue: UseFormSetValue<any>;
}

export function BookAutoSuggest<TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(
  props: UseControllerProps<TFieldValues, TName> & BookAutoSuggestProps,
) {
  const { label, value, setValue, watching } = props;
  const { field } = useController(props);
  const { assessmentId, studentProfileId } = useParams();

  const dispatch = useAppDispatch();

  const bookField = Object.keys(watching).find((key) => wordCountSubmissionKeys.includes(key));

  useGetAssessmentBook(assessmentId!, studentProfileId!, {
    enabled: !!assessmentId && !!studentProfileId && !value,
    retry: 1,
    onSuccess: (book) => {
      if (!!book) {
        field.onChange({ id: book.id, title: book.name });
        if (bookField) {
          setValue(bookField, book.wordCount ?? '', { shouldDirty: true });
        }
      }
    },
    onError: () => {
      dispatch(
        createFlashMessage({
          message: 'There was an error in selecting a book for this student. Please close the assessment and contact help@hootreading.com.',
          variant: 'error',
          isIndefinite: true,
        }),
      );
    },
  });

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <ReadOnlyTextField sx={{ flex: 1 }} label={label} body={field.value?.title ?? 'No book found'} />
    </Box>
  );
}
