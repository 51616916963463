import { Stack } from '@mui/system';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface ILeaveCurrentUnitProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmLeave: () => void;
}

export const LeaveCurrentUnitDialog = (props: ILeaveCurrentUnitProps) => {
  return (
    <BasicAlertDialog
      show={props.open}
      title={'Are You Sure You Want to Leave?'}
      content={
        <Stack gap={3}>
          <HootTypography isPII={false}>If you leave now, you'll lose any unsaved answers from the current unit you're working on.</HootTypography>
          <HootTypography isPII={false}>✅ Completed units are automatically saved.</HootTypography>
          <HootTypography isPII={false}>❌ Incomplete / Partially completed units will not be saved.</HootTypography>
          <HootTypography isPII={false}>Are you sure you want to leave?</HootTypography>
        </Stack>
      }
      primaryAction={{
        label: 'Leave',
        onClick: props.confirmLeave,
        props: { color: 'error.80' },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: () => props.setOpen(false),
      }}
      onDismiss={() => props.setOpen(false)}
    />
  );
};
