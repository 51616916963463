import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface RecentStudentLessonReview {
  scheduledLessonId: string;
  lessonDate: string;
  lessonType: ScheduledLessonType;
  focus: string;
  lessonNumber?: number;
}

async function getRecentStudentLessonReviews(studentProfileId: string): Promise<RecentStudentLessonReview[]> {
  const { data } = await axios.get<RecentStudentLessonReview[]>(`${config.apiUrl}/public/lesson-review/recent-reviews`, {
    params: { studentProfileId },
  });
  return data;
}

export default function useGetRecentStudentLessonReviews(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<RecentStudentLessonReview[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetRecentReviews, studentProfileId], () => getRecentStudentLessonReviews(studentProfileId), options);
}
