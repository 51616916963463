import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface PMABookComprehensionQuestionsResponse {
  textSpecificKnowledge: string;
  textStructure: string;
  sentenceAnalysis: string;
  verbalReasoning: string;
  vocabularyOverall: string;
}

export async function getBookComprehensionQuestions(bookId: string) {
  const { data } = await axios.get<PMABookComprehensionQuestionsResponse>(
    `${config.apiUrl}/public/progress-monitoring-assessment/book/${bookId}/comprehension-questions`,
  );
  return data;
}

export default function useGetBookComprehensionQuestions(
  bookId: string,
  options?: Omit<UseQueryOptions<PMABookComprehensionQuestionsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.PMABookComprehensionQuestions, bookId], () => getBookComprehensionQuestions(bookId), options);
}
