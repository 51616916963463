import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Card, Collapse } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import useGetStudentProfileV2 from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { useEmit } from '@hoot/hooks/useEmit';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import StudentGradeConfirmationDialog from '../../my-students/student-details/assessments/StudentGradeConfirmationDialog';
import TeacherInLessonReaderPage from '../library/reader/TeacherInLessonReaderPage';
import { PMAToolsMenu } from './PMAToolsMenu';
import { Unit1Module1Example, Unit1Module2Example, Unit1Module3Example, Unit1Module4Example } from './UnitExample';
import LeavePMADialog from './dialogs/LeavePMADialog';
import { pmaInstructions, pmaTitles } from './enum';

export function PMAContainer(props: { children: React.ReactNode; showBook?: boolean }) {
  const { lessonId } = useParams();
  const { inLesson } = useActiveLessonState();

  const dispatch = useAppDispatch();

  const { data: student } = useGetStudentProfileV2(inLesson?.studentId ?? '', {
    enabled: !!inLesson?.studentId,
    onError: (ex) => {
      console.error(ex);
      dispatch(createFlashMessage({ message: 'An error occurred while attempting to fetch the student data', variant: 'error' }));
    },
  });
  const doesStudentNotHaveGrade = !student?.grade;
  const [promptStudentGradeDialog, setPromptStudentGradeDialog] = useState<boolean>(doesStudentNotHaveGrade);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);

  const closePmaRequestEmitter = useEmit<void>(EventType.PMACloseRequest, undefined, {
    enabled: false,
  });

  const handleConfirmLeavePMA = () => {
    setShowConfirmDialog(false);
    if (lessonId) {
      closePmaRequestEmitter.queueMessage();
    }
  };

  const handleShowConfirmationClick = (showConfirmation: boolean) => {
    setShowConfirmDialog(showConfirmation);
  };

  const onCloseDialog = () => {
    setPromptStudentGradeDialog(false);
    handleConfirmLeavePMA();
  };

  const onConfirmDialog = () => {
    setPromptStudentGradeDialog(false);
  };

  if (!student || !inLesson) {
    return null;
  }

  return (
    <Stack flex={1} sx={{ padding: 2, overflowY: 'scroll' }}>
      <Stack justifyContent="flex-start">
        <Stack justifyContent="flex-start">
          <Card>
            <Stack sx={{ height: '100%', gap: 1, justifyContent: 'flex-start', alignItems: 'left', paddingBottom: 3 }}>
              <Stack direction="row" justifyContent="space-between">
                <HootTypography isPII={false} variant="titlelarge">
                  Progress Monitoring Assessment
                </HootTypography>
                <Stack direction="row">
                  <PMAToolsMenu onShowConfirmationClick={handleShowConfirmationClick} currentUnitId={currentUnit} />
                </Stack>
              </Stack>
              <HootTypography isPII={false} variant="bodylarge">
                {pmaTitles[currentUnit]}
              </HootTypography>
              <Instructions />
              {props.children}
            </Stack>
          </Card>
        </Stack>
        {props.showBook ? (
          <Stack sx={{ maxHeight: 'calc(100vh - 64px)' }}>
            <TeacherInLessonReaderPage />
          </Stack>
        ) : null}
        <LeavePMADialog open={showConfirmDialog} setOpen={setShowConfirmDialog} confirmLeave={handleConfirmLeavePMA} />
        <StudentGradeConfirmationDialog
          show={promptStudentGradeDialog}
          onConfirm={onConfirmDialog}
          onDismiss={onCloseDialog}
          studentName={student.name}
          studentId={student.id}
          studentGrade={student.grade ?? null}
        />
      </Stack>
    </Stack>
  );
}

function Instructions() {
  const [openInstructions, setOpenInstructions] = useState<boolean>(false);

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);

  const handleClick = () => {
    setOpenInstructions((prevVal) => !prevVal);
  };

  function ShowExample() {
    if (currentUnit === PMAUnitIdentifier.Module_1_Unit_1) {
      return <Unit1Module1Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_2) {
      return <Unit1Module2Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_3) {
      return <Unit1Module3Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_4) {
      return <Unit1Module4Example />;
    }
    return null;
  }

  return (
    <>
      <Button
        startIcon={
          openInstructions ? (
            <Icon name="collapse_filled" sx={{ width: 200, height: 20 }} />
          ) : (
            <Icon name="solid_add_rectangle" htmlColor="primary.0" />
          )
        }
        onClick={handleClick}
        sx={{ width: '150px', gap: '8px' }}
      >
        <HootTypography isPII={false} variant="titlesmall">
          Instructions
        </HootTypography>
      </Button>
      <Collapse in={openInstructions} timeout="auto" unmountOnExit>
        <HootTypography isPII={false} variant="bodylarge">
          {pmaInstructions[currentUnit]}
        </HootTypography>

        <ShowExample />
      </Collapse>
    </>
  );
}
