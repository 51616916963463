import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface LessonSearchRequest {
  fromDate: number;
  toDate: number;
}

export interface LessonSearchResponse {
  id: string;
  prefixedLessonNumber: string;
  startsAt: number;
  durationInMinutes: number;
  studentId: string | undefined;
  studentName: string | undefined;
  prefixedStudentNumber: string;
  status: ScheduledLessonStatus;
  cancellationReason: CancellationReason | null;
}

export async function getLessons(query: LessonSearchRequest) {
  const { data } = await axios.post<GenericPaginatedResponse<LessonSearchResponse>>(`${config.apiUrl}/public/scheduler/lessons`, query);
  return data;
}

export default function useGetLessons(query: LessonSearchRequest) {
  return useQuery([QueryKey.LessonSearch, query], () => getLessons(query));
}
