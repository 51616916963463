import { SvgIcon, SvgIconProps } from '@mui/material';

const CompletedLessonIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#A3B5CB" />
    <path d="M11.796 14.416H14.484V16H9.744V7.576H11.796V14.416Z" fill="black" />
  </SvgIcon>
);

export default CompletedLessonIcon;
