import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button, ButtonProps } from '@hoot/ui/components/v2/core/Button';
import StartAssessmentConfirmationDialog from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/StartAssessmentConfirmationDialog';
import StudentGradeConfirmationDialog from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/StudentGradeConfirmationDialog';

interface StartAssessmentConfirmationDialogProps {
  assessmentIdToResume?: string;
  studentProfileSnippet: StudentProfileSnippetResponse;
  startText?: string;
  resumeText?: string;
  startProps?: Pick<ButtonProps, 'color' | 'size'>;
  resumeProps?: Pick<ButtonProps, 'color' | 'size'>;
}

const LaunchAssessmentButton = (props: StartAssessmentConfirmationDialogProps) => {
  const { assessmentIdToResume, studentProfileSnippet, startText, resumeText, startProps, resumeProps } = props;

  const navigate = useNavigate();

  const [showStartAssessmentConfirmationDialog, setShowStartAssessmentConfirmationDialog] = useState(false);
  const [showStudentGradeConfirmationDialog, setShowStudentGradeConfirmationDialog] = useState(false);

  const onStartOrResume = () => {
    setShowStartAssessmentConfirmationDialog(true);
  };

  const onStartAssessmentConfirmation = () => {
    setShowStartAssessmentConfirmationDialog(false);
    if (!studentProfileSnippet.grade) {
      setShowStudentGradeConfirmationDialog(true);
    } else {
      navigateToAssessment(assessmentIdToResume, studentProfileSnippet);
    }
  };

  const onDismissStartAssessmentConfirmationDialog = () => {
    setShowStartAssessmentConfirmationDialog(false);
  };

  const onDismissStudentGradeConfirmationDialog = () => {
    setShowStudentGradeConfirmationDialog(false);
  };

  const navigateToAssessment = (assessmentIdToResume: string | undefined, studentProfile: StudentProfileSnippetResponse) => {
    if (assessmentIdToResume) {
      navigate(routesDictionary.myStudents.details.assessments.resume.url(studentProfile.id, assessmentIdToResume));
    } else {
      navigate(routesDictionary.myStudents.details.assessments.new.url(studentProfile.id));
    }
  };

  const onConfirm = () => {
    navigateToAssessment(undefined, studentProfileSnippet);
  };

  return (
    <>
      {!!assessmentIdToResume ? (
        <Button variant="contained" {...resumeProps} onClick={onStartOrResume}>
          {resumeText ?? 'Resume'}
        </Button>
      ) : (
        <Button variant="contained" {...startProps} onClick={onStartOrResume}>
          {startText ?? 'Create'}
        </Button>
      )}
      <StartAssessmentConfirmationDialog
        show={showStartAssessmentConfirmationDialog}
        isToResumeExisting={!!assessmentIdToResume}
        studentProfile={studentProfileSnippet}
        onConfirm={onStartAssessmentConfirmation}
        onDismiss={onDismissStartAssessmentConfirmationDialog}
      />
      <StudentGradeConfirmationDialog
        show={showStudentGradeConfirmationDialog}
        onConfirm={onConfirm}
        onDismiss={onDismissStudentGradeConfirmationDialog}
        studentName={studentProfileSnippet.name}
        studentId={studentProfileSnippet.id}
        studentGrade={studentProfileSnippet.grade}
      />
    </>
  );
};

export default LaunchAssessmentButton;
