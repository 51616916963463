import { DailyVideo, useAudioTrack, useDaily, useDailyEvent, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/system';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { STUDENT_VIDEO_HEIGHT, STUDENT_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { DailyMediaState, initialSessionState } from '@hoot/hooks/daily/useDailyMediaState';
import { useShallowMergeMeetingSessionData } from '@hoot/hooks/daily/useShallowMergeMeetingSessionData';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import NetworkVisualizer from '@hoot/ui/components/v2/teacher-blade/ui-components/NetworkVisualizer';
import DailyAudioVisualizer from '@hoot/ui/components/v2/video/DailyAudioVisualizer';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const LocalStudentTile = () => {
  const dailyCallObj = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideoTrack = useVideoTrack(localSessionId);
  const localAudioTrack = useAudioTrack(localSessionId);
  const { mergeMeetingSessionData } = useShallowMergeMeetingSessionData();

  const isVideoEnabled = !localVideoTrack.isOff;
  const isAudioEnabled = !localAudioTrack.isOff;

  useDailyEvent(
    'meeting-session-state-updated',
    useCallback(
      (ev) => {
        if (dailyCallObj) {
          /*
           * Note: This event is triggered after 14-15 minutes when a user is in a lesson by themselves. This is becuase Daily creates a
           * new session after 10 minutes when a user is in a session by themselves (https://docs.daily.co/reference/rest-api/meetings).
           * The data (even though it was initialized) does not exist on this trigger (as it is reset) and the users video and audio
           * tracks are read as 'ON' even though they set them off. If there was no check for the data to not be empty then this
           * would turn on the users camera and mic.
           */
          const { isInitialized, teacherCameraOn, teacherMicrophoneOn } = ev.meetingSessionState.data as DailyMediaState;

          if (!_.isEmpty(ev.meetingSessionState.data)) {
            if (!isInitialized) {
              mergeMeetingSessionData<DailyMediaState>({
                ...initialSessionState,
                isInitialized: true,
                updatedAt: DateTime.now().toMillis(),
              });
            } else {
              dailyCallObj.setLocalVideo(teacherCameraOn ?? isVideoEnabled);
              dailyCallObj.setLocalAudio(teacherMicrophoneOn ?? isAudioEnabled);
            }
          }
        }
      },
      [dailyCallObj, mergeMeetingSessionData, isAudioEnabled, isVideoEnabled],
    ),
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '178px',
        backgroundColor: localVideoTrack.isOff ? hootTokens.surface['1'] : hootTokens.palette.black,
        padding: 2,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...hootTokens.elevation.elevation5,
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        {localSessionId && isVideoEnabled ? (
          <DailyVideo
            sessionId={localSessionId}
            type={'video'}
            width={STUDENT_VIDEO_WIDTH}
            height={STUDENT_VIDEO_HEIGHT}
            fit="cover"
            playableStyle={{
              borderRadius: '8px',
            }}
          />
        ) : null}

        {localVideoTrack.isOff && (
          <Lottie
            lottieFile={LottieFile.Camera}
            loop={false}
            play={true}
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        )}

        <Stack flexDirection="row" position="absolute" top={8} right={8} columnGap={2}>
          {isVideoEnabled && <DailyAudioVisualizer audioTrack={localAudioTrack} />}
          {isVideoEnabled && <NetworkVisualizer />}
        </Stack>
      </Stack>
    </Box>
  );
};

export default LocalStudentTile;
