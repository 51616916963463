import { Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FULL_DATE } from '@hoot/constants/constants';
import {
  GetStudentAssessmentsQuery,
  HootAssessmentV2Response,
  StudentAssessmentsQuerySortKeyEnum,
  useGetAssessmentsV2,
} from '@hoot/hooks/api/assessment/useGetStudentAssessmentsV2';
import { useGetStudentInProgressAssessment } from '@hoot/hooks/api/assessment/useGetStudentInProgressAssessment';
import { HootAssessmentStatus } from '@hoot/models/api/enums/hoot-reading-assessment';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import AssessmentDetailsModal from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/AssessmentDetailsModal';
import LaunchAssessmentButton from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/LaunchAssessmentButton';
import { hootAssessmentStatusLabel } from '../../../hoot-reading-assessment/utils/assessment-utils';

interface AssessmentTableRow {
  id: string;
  startDate: string;
  status: string;
  completionDate: string;
  exitPoint: string;
  action: React.ReactNode;
}

const headers: HeaderData<AssessmentTableRow>[] = [
  { name: 'Id', property: 'id', isHidden: true },
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Status', property: 'status', isSortable: true },
  { name: 'Completion Date', property: 'completionDate', isSortable: true },
  { name: 'Exit Point', property: 'exitPoint', isSortable: true },
  { name: 'Action', property: 'action', isSortable: false },
];

const mobileHeaders: HeaderData<AssessmentTableRow>[] = [
  { name: 'Id', property: 'id', isHidden: true },
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Status', property: 'status', isSortable: true },
  { name: 'Completion Date', property: 'completionDate', isSortable: true },
  { name: 'Exit Point', property: 'exitPoint', isSortable: true, isHidden: true },
  { name: 'Action', property: 'action', isSortable: false, isHidden: true },
];

const AssessmentHistoryCard = () => {
  const { studentProfileId } = useParams();
  const location = useLocation();
  const hraId: string | undefined = location.state?.hraId;
  const unitId: string | undefined = location.state?.unitId;

  const { studentProfileSnippet } = useStudentDetailsContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [query, setQuery] = useState<GetStudentAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentAssessmentsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });
  const [results, setResults] = useState<GenericPaginatedResponse<AssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });
  const [activeAssessment, setActiveAssessment] = useState<HootAssessmentV2Response>();
  const [showAssessmentDetails, setShowAssessmentDetails] = useState<HootAssessmentV2Response>();

  const getInProgressAssessmentRequest = useGetStudentInProgressAssessment(studentProfileId!, {
    retry: false,
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setActiveAssessment(response?.inProgressAssessment ?? undefined);
    },
  });
  const getAssessmentsRequest = useGetAssessmentsV2(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      if (hraId) {
        const selectedAssessment = response.data.find((assessment) => assessment.id === hraId);
        if (selectedAssessment) {
          setShowAssessmentDetails(selectedAssessment);
        }
      }
      setResults({
        ...response,
        data: response.data.map((x) => mapAssessmentResponseToTableRow(x)),
      });
    },
  });

  const isActiveAssessmentInProgress = activeAssessment?.status === HootAssessmentStatus.InProgress;

  const mapAssessmentResponseToTableRow = (assessment: HootAssessmentV2Response): AssessmentTableRow => {
    return {
      id: assessment.id,
      startDate: DateTime.fromMillis(assessment.createdAt).toFormat(FULL_DATE),
      status: hootAssessmentStatusLabel[assessment.status],
      completionDate: assessment.completedAt ? DateTime.fromMillis(assessment.completedAt).toFormat(FULL_DATE) : '-',
      exitPoint: assessment.lastCompletedUnit?.unitName ?? '-',
      action: (
        <Button variant="outlined" size="small" onClick={() => onShowAssessmentDetailsModal(assessment)}>
          Open
        </Button>
      ),
    };
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof AssessmentTableRow) => {
    const getQueryOrderByColumn = (): StudentAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'completionDate':
          return StudentAssessmentsQuerySortKeyEnum.CompletionDate;
        case 'exitPoint':
          return StudentAssessmentsQuerySortKeyEnum.ExitPoint;
        case 'status':
          return StudentAssessmentsQuerySortKeyEnum.Status;
        case 'startDate':
        default:
          return StudentAssessmentsQuerySortKeyEnum.StartDate;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      // If the user clicked on a new column, then sort in ascending order.
      sortDirection:
        queryOrderByColumn !== currentState.orderBy
          ? OrderBy.Asc
          : // Else, flip the sort order.
            currentState.sortDirection === OrderBy.Asc
            ? OrderBy.Desc
            : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof AssessmentTableRow {
    switch (query.orderBy) {
      case StudentAssessmentsQuerySortKeyEnum.Status:
        return 'status';
      case StudentAssessmentsQuerySortKeyEnum.CompletionDate:
        return 'completionDate';
      case StudentAssessmentsQuerySortKeyEnum.ExitPoint:
        return 'exitPoint';
      case StudentAssessmentsQuerySortKeyEnum.StartDate:
      default:
        return 'startDate';
    }
  }

  const onShowAssessmentDetailsModal = (assessment: HootAssessmentV2Response) => {
    setShowAssessmentDetails(assessment);
  };

  const onDismissAssessmentDetailsModal = () => {
    setShowAssessmentDetails(undefined);
  };

  const onAssessmentChanged = (assessment: HootAssessmentV2Response) => {
    const updatedAssessments = [...results.data];
    const updateIndex = updatedAssessments.findIndex((x) => x.id === assessment.id);
    if (updateIndex >= 0) {
      updatedAssessments[updateIndex] = mapAssessmentResponseToTableRow(assessment);
    }
    setResults({
      ...results,
      data: updatedAssessments,
    });
    // Conditionally, update the "active" assessment if that's the one we changed. We need to know if we can start a new
    // assessment, or resume the active one.
    if (activeAssessment?.id === assessment.id) {
      setActiveAssessment(assessment);
    }
  };

  return (
    <>
      <Card isLoading={getAssessmentsRequest.isFetching}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlemedium">
              Hoot Reading Assessment History
            </HootTypography>
            {getInProgressAssessmentRequest.isFetching ? (
              <Skeleton variant="rectangular" sx={{ width: '190px', height: '60px' }} />
            ) : (
              <>
                {isActiveAssessmentInProgress ? (
                  <LaunchAssessmentButton
                    assessmentIdToResume={isActiveAssessmentInProgress ? activeAssessment?.id : undefined}
                    studentProfileSnippet={studentProfileSnippet}
                    startText={'Start Assessment'}
                    startProps={{ size: 'small' }}
                    resumeText={'Resume Assessment'}
                    resumeProps={{ size: 'small' }}
                  />
                ) : null}
              </>
            )}
          </Stack>
          <TableV2
            isPaginated
            isSortable
            data={results.data}
            headers={isMobile ? mobileHeaders : headers}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={results.count}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onSortBy={handleSortBy}
            sortOrder={query.sortDirection ?? OrderBy.Asc}
            sortBy={orderByColumn()}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
          />
        </Stack>
      </Card>
      <AssessmentDetailsModal
        open={!!showAssessmentDetails}
        assessment={showAssessmentDetails}
        onAssessmentChanged={onAssessmentChanged}
        onClose={onDismissAssessmentDetailsModal}
        unitId={unitId}
      />
    </>
  );
};

export default AssessmentHistoryCard;
