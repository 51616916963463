export async function preloadImageQueue(src: string[]): Promise<void> {
  const imageToLoad = src.pop();
  if (imageToLoad) {
    preloadImage(imageToLoad, () => {
      if (src.length > 0) {
        preloadImageQueue(src);
      }
    });
  }
}

export function preloadImage(src: string, onLoaded?: (this: GlobalEventHandlers, ev: Event) => any) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    if (onLoaded) {
      img.onload = onLoaded;
    }

    img.src = src;
  });
}
