import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { TimelineEvent, TimelineLessonReviewFocus, TimelineOutcome } from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/enums';
import { QueryKey } from '../queryKeys';

export interface TimelineCell {
  outcome: TimelineOutcome | TimelineLessonReviewFocus;
  date?: string;
  teacherName?: string;
  lessonReviewId?: string;
  hraId?: string;
  pmaId?: string;
  unit: {
    name: string;
    id: string;
    results?: string;
  };
}

export interface HeaderCell {
  event: TimelineEvent;
  date?: string;
  teacherName?: string;
  lessonReviewId?: string;
  hraId?: string;
  pmaId?: string;
}

interface AssessmentUnit {
  name: string;
  sequence: number;
  cells: TimelineCell[];
}

interface AssessmentModule {
  name: string;
  sequence: number;
  units: AssessmentUnit[];
}

export interface StudentTimelineResponse {
  headerRow: HeaderCell[];
  modules: AssessmentModule[];
}

export enum TimelineFilterType {
  PeriodBased = 'PERIOD_BASED',
  DateBased = 'DATE_BASED',
}

export enum TimelineViewOption {
  EntireCurrentEnrolment = 'ENTIRE_CURRENT_ENROLMENT',
  AllTimeLessonHistory = 'ALL_TIME_LESSON_HISTORY',
  MostRecentHRAUntilNextHRA = 'MOST_RECENT_HRA_UNTIL_NEXT_HRA',
  MostRecentHRAUntilNextHRAOrPMA = 'MOST_RECENT_HRA_UNTIL_NEXT_HRA_OR_PMA',
  None = 'NONE',
}

interface StudentTimelineRequest {
  filterType: TimelineFilterType;
  viewOption: TimelineViewOption;
  fromDate?: number;
  toDate?: number;
}

export async function getStudentTimeline(studentProfileId: string, query: StudentTimelineRequest) {
  const { data } = await axios.get<StudentTimelineResponse>(`${config.apiUrl}/my-students/${studentProfileId}/timeline`, { params: query });
  return data;
}

export default function useGetStudentTimeline(
  studentProfileId: string,
  query: StudentTimelineRequest,
  options?: Omit<UseQueryOptions<StudentTimelineResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentTimeline, studentProfileId, query], () => getStudentTimeline(studentProfileId, query), options);
}
