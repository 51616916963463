import { Box, Dialog, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@hoot/redux/store';
import useTeacherPMAController from '@hoot/ui/pages/v2/teacher/lesson/progress-monitoring-assessment/useTeacherPMAController';
import { Button } from '../core/Button';
import HootTypography from '../core/HootTypography';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';
import Lottie, { LottieFile } from '../lottie/Lottie';

interface Props {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  isResumingAssessment: boolean;
}

const PMANotificationDialog = (props: Props) => {
  const { isOpen, setIsOpen, isResumingAssessment } = props;
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const pmaController = useTeacherPMAController();

  const handleGoToPMAClick = () => {
    pmaController.onNavigateToPMA();
    props.setIsOpen(false);
  };

  return (
    <Dialog open={isOpen}>
      <Stack direction="column" alignItems="center" padding={3}>
        <Stack direction="row" justifyContent="space-evenly" width={'100%'}>
          <Box flex={1} />
          <Stack direction="row" justifyContent="center" flex={3}>
            <HootTypography isPII variant="displaysmall">
              {inLesson?.studentName}
            </HootTypography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" flex={1}>
            <IconButton onClick={() => setIsOpen(false)}>
              <Icon name="close" />
            </IconButton>
          </Stack>
        </Stack>
        <HootTypography isPII={false} variant="headlinemedium" textAlign="center">
          Requires a Progress Monitoring Assessment
        </HootTypography>
        <img src="/images/assessment-clipboard.png" alt="logo" height="355px" width="259px" />

        <Stack rowGap={2} width="100%" justifyContent="center">
          <Button
            onClick={handleGoToPMAClick}
            variant="contained"
            color="success.80"
            fullWidth
            sx={{
              height: '120px',
              color: '#FFF',
            }}
          >
            <Stack alignItems="center" gap={1}>
              <Lottie
                lottieFile={LottieFile.Rocket}
                loop={true}
                play={true}
                style={{
                  width: '64px',
                  height: '64px',
                }}
              />
              <HootTypography variant="titlelarge" isPII={false}>
                {`${isResumingAssessment ? 'Resume' : 'Begin'} Assessment`}
              </HootTypography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PMANotificationDialog;
