import { PMAFormVariant, PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { PMAQuestionnaireLayout_1_Prompts } from './units/PMA_QUESTIONNAIRE_LAYOUT_1';
import { ModuleDetails } from './units/PMA_QUESTIONNAIRE_LAYOUT_3';
import { Layout5Questionnaire } from './units/PMA_QUESTIONNAIRE_LAYOUT_5';
import { Layout6Questionnaire } from './units/PMA_QUESTIONNAIRE_LAYOUT_6';

export const pmaTitles: Record<PMAUnitIdentifier, string> = {
  UNIT_1_1: 'Segmentation',
  UNIT_1_2: 'Blending',
  UNIT_1_3: 'Deletion',
  UNIT_1_4: 'Substitution',
  UNIT_1_5: 'Initial Letter Sounds',
  UNIT_2_1: 'Short Vowels CVC',
  UNIT_2_2: 'Digraphs',
  UNIT_2_3: 'Blends',
  UNIT_2_4: 'Long Vowels',
  UNIT_2_5: 'Basic R-Controlled Vowels',
  UNIT_2_6: 'Basic Vowel Teams',
  UNIT_3_1: 'Advanced Vowels',
  UNIT_3_2: 'Advanced R-Controlled Vowels',
  UNIT_3_3: 'Advanced Consonants',
  UNIT_3_4: 'Affixes',
  UNIT_3_5: 'Syllable Types',
  UNIT_3_6: 'Multisyllabic Words',
  UNIT_4_1: 'Fluency',
  UNIT_4_2: 'Fluency',
  UNIT_4_3: 'Language Comprehension',
  UNIT_4_4: 'Reading Comprehension',
};

export const pmaInstructions: Record<PMAUnitIdentifier, string> = {
  UNIT_1_1: 'Ask the students to break apart the sounds in the prompt. Indicate whether they got the sounds correct.',
  UNIT_1_2: 'Indicate whether the student responded with the correct word.',
  UNIT_1_3: 'Indicate whether the student responded with the correct word.',
  UNIT_1_4: 'Indicate whether the student responded with the correct word.',
  UNIT_1_5:
    'Ask the student to produce the sound for each letter. If they say the letter name, redirect them to say the sound. Indicate which sounds they said correctly.',
  UNIT_2_1: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_2_2: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_2_3: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_2_4: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_2_5: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_2_6: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_1: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_2: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_3: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_4: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_5: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_3_6: 'As the student reads the words, indicate which words they read correctly.',
  UNIT_4_1: 'Please time the student reading the selected passage. Enter the errors and time spent reading.',
  UNIT_4_2:
    'From listening to the student read during the Text Reading portion of the assessment, use the rubrics to assess the student’s phrasing and expression scores.',
  UNIT_4_3:
    "Read the selected text out loud to the student. After you finish reading, ask every question included in the Text Reading Assessment book. Allow and support students to navigate back through the text to best answer these questions. Score student responses using the rubric below. Remember, you'll score vocabulary holistically, thinking about how the student has answered all other questions.",
  UNIT_4_4:
    "After the student has finished reading the selected text, ask every question included in the Text Reading Assessment book. Allow and support students to navigate back through the text to best answer these questions. Score student responses using the rubric below. Remember, you'll score vocabulary holistically, thinking about how the student has answered all other questions.",
};

export const UNIT_1_1_FORM: { [key in PMAFormVariant]?: PMAQuestionnaireLayout_1_Prompts[] } = {
  A: [
    { sentence: 'Tell me all of the sounds in sip.', word: 'sip', expectedResponse: '/s/ /i/ /p/', wordsToBold: 'sip' },
    { sentence: 'Tell me all of the sounds in poke.', word: 'poke', expectedResponse: '/p/ /ō/ /k/', wordsToBold: 'poke' },
  ],
  B: [
    { word: 'lane', sentence: 'Tell me all of the sounds in lane.', expectedResponse: '/l/ /ā/ /n/', wordsToBold: 'lane' },
    { word: 'shot', sentence: 'Tell me all of the sounds in shot.', expectedResponse: '/sh/ /o/ /t/', wordsToBold: 'shot' },
  ],
  C: [
    { word: 'brick', sentence: 'Tell me all of the sounds in brick.', expectedResponse: '/b/ /r/ /i/ /k/', wordsToBold: 'brick' },
    { word: 'tail', sentence: 'Tell me all of the sounds in tail.', expectedResponse: '/t/ /ā/ /l/', wordsToBold: 'tail' },
  ],
};

export const UNIT_1_2_FORM: { [key in PMAFormVariant]?: PMAQuestionnaireLayout_1_Prompts[] } = {
  A: [
    { word: 'dusk', sentence: '/d/ /u/ /s/ /k/ - You say it fast.', expectedResponse: 'dusk', wordsToBold: '' },
    { word: 'path', sentence: '/p/ /a/ /th/ - You say it fast.', expectedResponse: 'path', wordsToBold: '' },
    { word: 'job', sentence: '/j/ /o/ /g/ - You say it fast.', expectedResponse: 'jog', wordsToBold: '' },
  ],
  B: [
    { word: 'ship', sentence: '/sh/ /i/ /p/ - You say it fast.', expectedResponse: 'ship', wordsToBold: '' },
    { word: 'vest', sentence: '/v/ /e/ /s/ /t/ - You say it fast.', expectedResponse: 'vest', wordsToBold: '' },
    { word: 'mat', sentence: '/m/ /a/ /t/ - You say it fast.', expectedResponse: 'mat', wordsToBold: '' },
  ],
  C: [
    { word: 'hen', sentence: '/h/ /e/ /n/ - You say it fast.', expectedResponse: 'hen', wordsToBold: '' },
    { word: 'mint', sentence: '/m/ /i/ /n/ /t/ - You say it fast.', expectedResponse: 'mint', wordsToBold: '' },
    { word: 'rich', sentence: '/r/ /i/ /ch/ - You say it fast.', expectedResponse: 'rich', wordsToBold: '' },
  ],
};

export const UNIT_1_3_FORM: { [key in PMAFormVariant]?: PMAQuestionnaireLayout_1_Prompts[] } = {
  A: [
    { word: 'rush', sentence: 'Say brush. Now say brush, but don’t say /b/.', expectedResponse: 'rush', wordsToBold: 'brush /b/' },
    { word: 'grass', sentence: 'Say grasp. Now say grasp, but don’t say /p/.', expectedResponse: 'grass', wordsToBold: 'grasp /p/' },
  ],
  B: [
    { word: 'plan', sentence: 'Say plant. Now say plant, but don’t say /t/.', expectedResponse: 'plan', wordsToBold: 'plant /t/' },
    { word: 'lip', sentence: 'Say slip. Now say slip, but don’t say /s/.', expectedResponse: 'lip', wordsToBold: 'slip /s/' },
  ],
  C: [
    { word: 'link', sentence: 'Say blink. Now say blink, but don’t say /b/.', expectedResponse: 'link', wordsToBold: 'blink /b/' },
    { word: 'bus', sentence: 'Say bust. Now say bust, but don’t say /t/.', expectedResponse: 'bus', wordsToBold: 'bust /t/' },
  ],
};

export const UNIT_1_4_FORM: { [key in PMAFormVariant]?: PMAQuestionnaireLayout_1_Prompts[] } = {
  A: [
    { word: 'hut', sentence: 'Say hum, but instead of /m/, say /t/.', expectedResponse: 'hut', wordsToBold: 'hum /m/ /t/' },
    { word: 'stiff', sentence: 'Say stuff, but instead of /u/, say /i/.', expectedResponse: 'stiff', wordsToBold: 'stuff /u/ /i/' },
    { word: 'rag', sentence: 'Say wag, but instead of /w/, say /r/.', expectedResponse: 'rag', wordsToBold: 'wag /w/ /r/' },
  ],
  B: [
    { word: 'mash', sentence: 'Say lash, but instead of /l/, say /m/.', expectedResponse: 'mash', wordsToBold: 'lash /l/ /m/' },
    { word: 'beak', sentence: 'Say beat, but instead of /t/, say /k/.', expectedResponse: 'beak', wordsToBold: 'beat /t/ /k/' },
    { word: 'fist', sentence: 'Say fast, but instead of /a/, say /i/.', expectedResponse: 'fist', wordsToBold: 'fast /a/ /i/' },
  ],
  C: [
    { word: 'ripe', sentence: 'Say rope, but instead of /ō/, say /ī/.', expectedResponse: 'ripe', wordsToBold: 'rope /ō/ /ī/' },
    { word: 'coat', sentence: 'Say boat, but instead of /b/, say /c/.', expectedResponse: 'coat', wordsToBold: 'boat /b/ /c/' },
    { word: 'snack', sentence: 'Say snag, but instead of /g/, say /k/.', expectedResponse: 'snack', wordsToBold: 'snag /g/ /k/' },
  ],
};

export const UNIT_1_5_FORM: { [key in PMAFormVariant]?: string[] } = {
  A: ['s', 'm', 'a', 't', 'p', 'e', 'r', 'i', 'f', 'o', 'd', 'l', 'c', 'u', 'h', 'g', 'b', 'k', 'j', 'n', 'w', 'v', 'y', 'qu', 'z', 'x'],
  B: ['s', 'a', 't', 'm', 'f', 'i', 'p', 'd', 'e', 'r', 'o', 'h', 'u', 'l', 'g', 'n', 'c', 'j', 'b', 'y', 'w', 'x', 'k', 'v', 'qu', 'z'],
  C: ['t', 's', 'a', 'm', 'r', 'i', 'p', 'e', 'd', 'o', 'l', 'f', 'h', 'u', 'g', 'c', 'k', 'j', 'b', 'n', 'qu', 'v', 'w', 'y', 'z', 'x'],
};

export const UNIT_2_1_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'I met a sick pig at the vet.', prompt: 'met', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I met a sick pig at the vet.', prompt: 'sick', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I met a sick pig at the vet.', prompt: 'pig', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I met a sick pig at the vet.', prompt: 'vet', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'miss', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'mom', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'but', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'get', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'hug', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I miss my mom, but I get a hug from Dad.', prompt: 'Dad', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I rub the mud off my leg with a rag.', prompt: 'rub', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I rub the mud off my leg with a rag.', prompt: 'mud', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I rub the mud off my leg with a rag.', prompt: 'off', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I rub the mud off my leg with a rag.', prompt: 'leg', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I rub the mud off my leg with a rag.', prompt: 'rag', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sit on the dock with a fat pack of gum.', prompt: 'sit', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sit on the dock with a fat pack of gum.', prompt: 'dock', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sit on the dock with a fat pack of gum.', prompt: 'fat', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sit on the dock with a fat pack of gum.', prompt: 'pack', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sit on the dock with a fat pack of gum.', prompt: 'gum', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I sip a pop with fizz and tuck it in the bag.', prompt: 'sip', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sip a pop with fizz and tuck it in the bag.', prompt: 'pop', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sip a pop with fizz and tuck it in the bag.', prompt: 'fizz', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sip a pop with fizz and tuck it in the bag.', prompt: 'tuck', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I sip a pop with fizz and tuck it in the bag.', prompt: 'bag', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I beg my pal to run to the hill and back.', prompt: 'beg', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I beg my pal to run to the hill and back.', prompt: 'pal', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I beg my pal to run to the hill and back.', prompt: 'run', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I beg my pal to run to the hill and back.', prompt: 'hill', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I beg my pal to run to the hill and back.', prompt: 'back', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_2_2_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'wish', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'thin', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'pink', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'ring', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'such', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My wish is for a thin pink ring, such as this.', prompt: 'this', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I check the latch when I shut it.', prompt: 'check', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I check the latch when I shut it.', prompt: 'latch', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I check the latch when I shut it.', prompt: 'when', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I check the latch when I shut it.', prompt: 'shut', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I hang the net and then chuck the junk on the bench.', prompt: 'hang', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hang the net and then chuck the junk on the bench.', prompt: 'then', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hang the net and then chuck the junk on the bench.', prompt: 'chuck', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hang the net and then chuck the junk on the bench.', prompt: 'junk', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hang the net and then chuck the junk on the bench.', prompt: 'bench', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'When I ran on the path by the shed, I fell in the patch and got a red rash.', prompt: 'When', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'When I ran on the path by the shed, I fell in the patch and got a red rash.', prompt: 'path', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'When I ran on the path by the shed, I fell in the patch and got a red rash.', prompt: 'shed', answer: PMAStepTestAnswer.NoAnswer },
    {
      fullPrompt: 'When I ran on the path by the shed, I fell in the patch and got a red rash.',
      prompt: 'patch',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    { fullPrompt: 'When I ran on the path by the shed, I fell in the patch and got a red rash.', prompt: 'rash', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I dash in a shop with my pal and fetch a pop.', prompt: 'dash', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dash in a shop with my pal and fetch a pop.', prompt: 'shop', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dash in a shop with my pal and fetch a pop.', prompt: 'with', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dash in a shop with my pal and fetch a pop.', prompt: 'fetch', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'dunk', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'that', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'long', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'chip', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'rich', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I will dunk that long chip in the rich whip.', prompt: 'whip', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_2_3_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'The black flaps on the tent lift in the wind.', prompt: 'black', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The black flaps on the tent lift in the wind.', prompt: 'flaps', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The black flaps on the tent lift in the wind.', prompt: 'tent', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The black flaps on the tent lift in the wind.', prompt: 'lift', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The black flaps on the tent lift in the wind.', prompt: 'wind', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drag the sled past the truck ramp.', prompt: 'drag', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drag the sled past the truck ramp.', prompt: 'sled', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drag the sled past the truck ramp.', prompt: 'past', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drag the sled past the truck ramp.', prompt: 'truck', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drag the sled past the truck ramp.', prompt: 'ramp', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I did a jump trick to brag, but it was a risk.', prompt: 'jump', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I did a jump trick to brag, but it was a risk.', prompt: 'trick', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I did a jump trick to brag, but it was a risk.', prompt: 'brag', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I did a jump trick to brag, but it was a risk.', prompt: 'risk', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'snag', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'hand', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'prop', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'skin', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'bled', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I snag my hand on the prop and my skin bled. Help!', prompt: 'Help', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I plan to swim and sift in the sand.', prompt: 'plan', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I plan to swim and sift in the sand.', prompt: 'swim', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I plan to swim and sift in the sand.', prompt: 'sift', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I plan to swim and sift in the sand.', prompt: 'sand', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'dusk', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'quit', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'crops', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'rest', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'stiff', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'At dusk, I quit the job in the crops to rest in the stiff grass.', prompt: 'grass', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_2_4_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'My cute dog will hide his bone by the side gate.', prompt: 'cute', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My cute dog will hide his bone by the side gate.', prompt: 'hide', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My cute dog will hide his bone by the side gate.', prompt: 'bone', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My cute dog will hide his bone by the side gate.', prompt: 'side', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My cute dog will hide his bone by the side gate.', prompt: 'gate', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I take a lone bite of cake as I nuke my lunch', prompt: 'take', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I take a lone bite of cake as I nuke my lunch', prompt: 'lone', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I take a lone bite of cake as I nuke my lunch', prompt: 'bite', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I take a lone bite of cake as I nuke my lunch', prompt: 'cake', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I take a lone bite of cake as I nuke my lunch', prompt: 'nuke', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'In June I gave my mate a code of runes.', prompt: 'June', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In June I gave my mate a code of runes.', prompt: 'gave', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In June I gave my mate a code of runes.', prompt: 'mate', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In June I gave my mate a code of runes.', prompt: 'code', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In June I gave my mate a code of runes.', prompt: 'runes', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hike with the tame mule on a rope to the cove.', prompt: 'hike', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hike with the tame mule on a rope to the cove.', prompt: 'tame', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hike with the tame mule on a rope to the cove.', prompt: 'mule', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hike with the tame mule on a rope to the cove.', prompt: 'rope', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hike with the tame mule on a rope to the cove.', prompt: 'cove', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'poke', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'holes', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'ripe', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'lime', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'use', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I poke holes in a ripe lime to use when I bake.', prompt: 'bake', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hate it when that dude tells a rude joke.', prompt: 'hate', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hate it when that dude tells a rude joke.', prompt: 'dude', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hate it when that dude tells a rude joke.', prompt: 'rude', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hate it when that dude tells a rude joke.', prompt: 'joke', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_2_5_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'The dark dirt is best for my herbs and ferns.', prompt: 'dark', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The dark dirt is best for my herbs and ferns.', prompt: 'dirt', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The dark dirt is best for my herbs and ferns.', prompt: 'for', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The dark dirt is best for my herbs and ferns.', prompt: 'herbs', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The dark dirt is best for my herbs and ferns.', prompt: 'ferns', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I blur a mark on the torn card as art.', prompt: 'blur', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I blur a mark on the torn card as art.', prompt: 'mark', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I blur a mark on the torn card as art.', prompt: 'torn', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I blur a mark on the torn card as art.', prompt: 'card', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I blur a mark on the torn card as art.', prompt: 'art', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I hurl the firm dart with my arm and it lands in the cork.', prompt: 'hurl', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hurl the firm dart with my arm and it lands in the cork.', prompt: 'firm', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hurl the firm dart with my arm and it lands in the cork.', prompt: 'dart', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hurl the firm dart with my arm and it lands in the cork.', prompt: 'arm', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I hurl the firm dart with my arm and it lands in the cork.', prompt: 'cork', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drop a tip in the jar on the bar when I go for my perm to get curls.', prompt: 'jar', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drop a tip in the jar on the bar when I go for my perm to get curls.', prompt: 'bar', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drop a tip in the jar on the bar when I go for my perm to get curls.', prompt: 'for', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drop a tip in the jar on the bar when I go for my perm to get curls.', prompt: 'perm', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I drop a tip in the jar on the bar when I go for my perm to get curls.', prompt: 'curls', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'My dog with black fur will bark at a horn or a bird.', prompt: 'fur', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My dog with black fur will bark at a horn or a bird.', prompt: 'bark', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My dog with black fur will bark at a horn or a bird.', prompt: 'horn', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My dog with black fur will bark at a horn or a bird.', prompt: 'or', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My dog with black fur will bark at a horn or a bird.', prompt: 'bird', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'Part of my job at the port is hard, but it has perks to spur me on.', prompt: 'Part', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'Part of my job at the port is hard, but it has perks to spur me on.', prompt: 'port', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'Part of my job at the port is hard, but it has perks to spur me on.', prompt: 'hard', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'Part of my job at the port is hard, but it has perks to spur me on.', prompt: 'perks', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'Part of my job at the port is hard, but it has perks to spur me on.', prompt: 'spur', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_2_6_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'The team will aim to meet at five to tow the load.', prompt: 'team', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The team will aim to meet at five to tow the load.', prompt: 'aim', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The team will aim to meet at five to tow the load.', prompt: 'meet', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The team will aim to meet at five to tow the load.', prompt: 'tow', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The team will aim to meet at five to tow the load.', prompt: 'load', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I might weep and wail from the heat of this day.', prompt: 'might', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I might weep and wail from the heat of this day.', prompt: 'weep', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I might weep and wail from the heat of this day.', prompt: 'wail', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I might weep and wail from the heat of this day.', prompt: 'heat', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I might weep and wail from the heat of this day.', prompt: 'day', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I see a long weed and foam in the night sea.', prompt: 'see', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a long weed and foam in the night sea.', prompt: 'weed', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a long weed and foam in the night sea.', prompt: 'foam', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a long weed and foam in the night sea.', prompt: 'night', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a long weed and foam in the night sea.', prompt: 'sea', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I bring the main meal and pay for my own pie.', prompt: 'main', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I bring the main meal and pay for my own pie.', prompt: 'meal', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I bring the main meal and pay for my own pie.', prompt: 'pay', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I bring the main meal and pay for my own pie.', prompt: 'own', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I bring the main meal and pay for my own pie.', prompt: 'pie', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I read as I soak my feet in the low bay.', prompt: 'read', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I read as I soak my feet in the low bay.', prompt: 'soak', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I read as I soak my feet in the low bay.', prompt: 'feet', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I read as I soak my feet in the low bay.', prompt: 'low', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I read as I soak my feet in the low bay.', prompt: 'bay', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The maid gets paid to dust the light and keep it neat.', prompt: 'maid', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The maid gets paid to dust the light and keep it neat.', prompt: 'paid', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The maid gets paid to dust the light and keep it neat.', prompt: 'light', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The maid gets paid to dust the light and keep it neat.', prompt: 'keep', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The maid gets paid to dust the light and keep it neat.', prompt: 'neat', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_3_1_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'The bruise is from when I stood up with a jolt and hit my head.', prompt: 'bruise', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bruise is from when I stood up with a jolt and hit my head.', prompt: 'stood', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bruise is from when I stood up with a jolt and hit my head.', prompt: 'jolt', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bruise is from when I stood up with a jolt and hit my head.', prompt: 'head', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'pause', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'point', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'count', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'wild', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'blooms', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I pause and point to count the wild blooms on the lawn.', prompt: 'lawn', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I see a small brown fawn, so I hold still and do not make noise.', prompt: 'small', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a small brown fawn, so I hold still and do not make noise.', prompt: 'brown', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a small brown fawn, so I hold still and do not make noise.', prompt: 'fawn', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a small brown fawn, so I hold still and do not make noise.', prompt: 'hold', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I see a small brown fawn, so I hold still and do not make noise.', prompt: 'noise', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I put a scoop of fruit in my mouth for my health.', prompt: 'put', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I put a scoop of fruit in my mouth for my health.', prompt: 'scoop', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I put a scoop of fruit in my mouth for my health.', prompt: 'fruit', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I put a scoop of fruit in my mouth for my health.', prompt: 'mouth', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I put a scoop of fruit in my mouth for my health.', prompt: 'health', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'The bread cools as the stew cooks and boils.', prompt: 'bread', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bread cools as the stew cooks and boils.', prompt: 'cools', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bread cools as the stew cooks and boils.', prompt: 'stew', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bread cools as the stew cooks and boils.', prompt: 'cooks', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The bread cools as the stew cooks and boils.', prompt: 'boils', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am most proud of how I taught my sis to talk.', prompt: 'most', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am most proud of how I taught my sis to talk.', prompt: 'proud', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am most proud of how I taught my sis to talk.', prompt: 'how', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am most proud of how I taught my sis to talk.', prompt: 'taught', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am most proud of how I taught my sis to talk.', prompt: 'talk', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_3_2_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'I spot a bear, a hare and pair of deer near the path.', prompt: 'bear', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I spot a bear, a hare and pair of deer near the path.', prompt: 'hare', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I spot a bear, a hare and pair of deer near the path.', prompt: 'pair', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I spot a bear, a hare and pair of deer near the path.', prompt: 'deer', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I spot a bear, a hare and pair of deer near the path.', prompt: 'near', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The score I earn is worse than my peer, but it is fair.', prompt: 'score', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The score I earn is worse than my peer, but it is fair.', prompt: 'earn', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The score I earn is worse than my peer, but it is fair.', prompt: 'worse', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The score I earn is worse than my peer, but it is fair.', prompt: 'peer', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The score I earn is worse than my peer, but it is fair.', prompt: 'fair', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    {
      fullPrompt: 'I pay the fare and set up my chair in the square to hear the band and cheer for them.',
      prompt: 'fare',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I pay the fare and set up my chair in the square to hear the band and cheer for them.',
      prompt: 'chair',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I pay the fare and set up my chair in the square to hear the band and cheer for them.',
      prompt: 'square',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I pay the fare and set up my chair in the square to hear the band and cheer for them.',
      prompt: 'hear',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I pay the fare and set up my chair in the square to hear the band and cheer for them.',
      prompt: 'cheer',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    { fullPrompt: 'The worst chore is to search for gear to wear in the snow.', prompt: 'worst', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The worst chore is to search for gear to wear in the snow.', prompt: 'chore', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The worst chore is to search for gear to wear in the snow.', prompt: 'search', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The worst chore is to search for gear to wear in the snow.', prompt: 'gear', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'The worst chore is to search for gear to wear in the snow.', prompt: 'wear', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I stop and stare at a flare in the air on a clear day.', prompt: 'stare', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I stop and stare at a flare in the air on a clear day.', prompt: 'flare', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I stop and stare at a flare in the air on a clear day.', prompt: 'air', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I stop and stare at a flare in the air on a clear day.', prompt: 'clear', answer: PMAStepTestAnswer.NoAnswer },

    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'learn',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'sheer',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'earth',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'yearn',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'more',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'As I learn of the sheer size of the earth, I yearn to see more of the world.',
      prompt: 'world',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
};

export const UNIT_3_3_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'I dance with grace on the bridge in a lace dress with fringe.', prompt: 'dance', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dance with grace on the bridge in a lace dress with fringe.', prompt: 'grace', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dance with grace on the bridge in a lace dress with fringe.', prompt: 'bridge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dance with grace on the bridge in a lace dress with fringe.', prompt: 'lace', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I dance with grace on the bridge in a lace dress with fringe.', prompt: 'fringe', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I held the knife wrong when I cut a wedge of fudge and got my thumb.', prompt: 'knife', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I held the knife wrong when I cut a wedge of fudge and got my thumb.', prompt: 'wrong', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I held the knife wrong when I cut a wedge of fudge and got my thumb.', prompt: 'wedge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I held the knife wrong when I cut a wedge of fudge and got my thumb.', prompt: 'fudge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I held the knife wrong when I cut a wedge of fudge and got my thumb.', prompt: 'thumb', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    { fullPrompt: 'I wrote a pledge to go to France when I am twice my age.', prompt: 'wrote', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wrote a pledge to go to France when I am twice my age.', prompt: 'pledge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wrote a pledge to go to France when I am twice my age.', prompt: 'France', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wrote a pledge to go to France when I am twice my age.', prompt: 'twice', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wrote a pledge to go to France when I am twice my age.', prompt: 'age', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In the race, we use our knees to climb the cage and trudge in the mud.', prompt: 'race', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In the race, we use our knees to climb the cage and trudge in the mud.', prompt: 'knees', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In the race, we use our knees to climb the cage and trudge in the mud.', prompt: 'climb', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In the race, we use our knees to climb the cage and trudge in the mud.', prompt: 'cage', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'In the race, we use our knees to climb the cage and trudge in the mud.', prompt: 'trudge', answer: PMAStepTestAnswer.NoAnswer },
  ],
  C: [
    { fullPrompt: 'I wring out my wet hair and try to comb the gnarls that will not budge.', prompt: 'wring', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wring out my wet hair and try to comb the gnarls that will not budge.', prompt: 'comb', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wring out my wet hair and try to comb the gnarls that will not budge.', prompt: 'gnarls', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I wring out my wet hair and try to comb the gnarls that will not budge.', prompt: 'budge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'knew', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'mince', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'sage', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'splice', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'fridge', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I knew to mince the sage spice and add broth from the fridge to the rice.', prompt: 'rice', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_3_4_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    {
      fullPrompt: 'I was calmly brushing my unkempt hair when she knocked on the door and yelled my name.',
      prompt: 'calmly',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was calmly brushing my unkempt hair when she knocked on the door and yelled my name.',
      prompt: 'brushing',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was calmly brushing my unkempt hair when she knocked on the door and yelled my name.',
      prompt: 'unkept',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was calmly brushing my unkempt hair when she knocked on the door and yelled my name.',
      prompt: 'knocked',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was calmly brushing my unkempt hair when she knocked on the door and yelled my name.',
      prompt: 'yelled',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is unwise to distract your mates by chatting in the hallway when it is time to report back to your classes.',
      prompt: 'unwise',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is unwise to distract your mates by chatting in the hallway when it is time to report back to your classes.',
      prompt: 'distract',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is unwise to distract your mates by chatting in the hallway when it is time to report back to your classes.',
      prompt: 'chatting',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is unwise to distract your mates by chatting in the hallway when it is time to report back to your classes.',
      prompt: 'report',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is unwise to distract your mates by chatting in the hallway when it is time to report back to your classes.',
      prompt: 'classes',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
  B: [
    { fullPrompt: 'I planned to unpack the dusty boxes in the mud room but it was locked.', prompt: 'planned', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I planned to unpack the dusty boxes in the mud room but it was locked.', prompt: 'unpack', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I planned to unpack the dusty boxes in the mud room but it was locked.', prompt: 'dusty', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I planned to unpack the dusty boxes in the mud room but it was locked.', prompt: 'boxes', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I planned to unpack the dusty boxes in the mud room but it was locked.', prompt: 'locked', answer: PMAStepTestAnswer.NoAnswer },
    {
      fullPrompt: 'I am thinking that if I inspect it closely, I can resell it at a discount.',
      prompt: 'thinking',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I am thinking that if I inspect it closely, I can resell it at a discount.',
      prompt: 'inspect',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I am thinking that if I inspect it closely, I can resell it at a discount.',
      prompt: 'closely',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I am thinking that if I inspect it closely, I can resell it at a discount.',
      prompt: 'resell',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I am thinking that if I inspect it closely, I can resell it at a discount.',
      prompt: 'discount',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
  C: [
    {
      fullPrompt: 'I walked in slowly, so as not to disrupt the kids sleeping on the couches. ',
      prompt: 'walked',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I walked in slowly, so as not to disrupt the kids sleeping on the couches. ',
      prompt: 'slowly',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I walked in slowly, so as not to disrupt the kids sleeping on the couches. ',
      prompt: 'distrupt',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I walked in slowly, so as not to disrupt the kids sleeping on the couches. ',
      prompt: 'sleeping',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I walked in slowly, so as not to disrupt the kids sleeping on the couches. ',
      prompt: 'couches',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'You can import the file and watch the replay of the shocking game that just ended to see it unfold.',
      prompt: 'import',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'You can import the file and watch the replay of the shocking game that just ended to see it unfold.',
      prompt: 'replay',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'You can import the file and watch the replay of the shocking game that just ended to see it unfold.',
      prompt: 'shocking',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'You can import the file and watch the replay of the shocking game that just ended to see it unfold.',
      prompt: 'ended',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'You can import the file and watch the replay of the shocking game that just ended to see it unfold.',
      prompt: 'unfold',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
};

export const UNIT_3_5_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    { fullPrompt: 'In July, we like to grill shellfish with butter at sunset on our campsite. ', prompt: 'July', answer: PMAStepTestAnswer.NoAnswer },
    {
      fullPrompt: 'In July, we like to grill shellfish with butter at sunset on our campsite. ',
      prompt: 'shellfish',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'In July, we like to grill shellfish with butter at sunset on our campsite. ',
      prompt: 'butter',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'In July, we like to grill shellfish with butter at sunset on our campsite. ',
      prompt: 'sunset',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'In July, we like to grill shellfish with butter at sunset on our campsite. ',
      prompt: 'campsite',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'My mission is to capture a green beetle in an open jar with a napkin on top.',
      prompt: 'mission',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'My mission is to capture a green beetle in an open jar with a napkin on top.',
      prompt: 'capture',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'My mission is to capture a green beetle in an open jar with a napkin on top.',
      prompt: 'beetle',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'My mission is to capture a green beetle in an open jar with a napkin on top.',
      prompt: 'open',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'My mission is to capture a green beetle in an open jar with a napkin on top.',
      prompt: 'napkin',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
  B: [
    {
      fullPrompt: 'It is a fiction book set in the future with a robot that is pals with a lazy turtle.',
      prompt: 'fiction',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is a fiction book set in the future with a robot that is pals with a lazy turtle.',
      prompt: 'future',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is a fiction book set in the future with a robot that is pals with a lazy turtle.',
      prompt: 'robot',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is a fiction book set in the future with a robot that is pals with a lazy turtle.',
      prompt: 'lazy',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'It is a fiction book set in the future with a robot that is pals with a lazy turtle.',
      prompt: 'turtle',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I saw a tadpole and a silver catfish when I was at my cabin in the forest.',
      prompt: 'tadpole',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I saw a tadpole and a silver catfish when I was at my cabin in the forest.',
      prompt: 'silver',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I saw a tadpole and a silver catfish when I was at my cabin in the forest.',
      prompt: 'catfish',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    { fullPrompt: 'I saw a tadpole and a silver catfish when I was at my cabin in the forest.', prompt: 'cabin', answer: PMAStepTestAnswer.NoAnswer },
    {
      fullPrompt: 'I saw a tadpole and a silver catfish when I was at my cabin in the forest.',
      prompt: 'forest',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
  C: [
    { fullPrompt: 'I play with puppets and do a nature puzzle with my little sister.', prompt: 'puppets', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I play with puppets and do a nature puzzle with my little sister.', prompt: 'nature', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I play with puppets and do a nature puzzle with my little sister.', prompt: 'puzzle', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I play with puppets and do a nature puzzle with my little sister.', prompt: 'little', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I play with puppets and do a nature puzzle with my little sister.', prompt: 'sister', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My passion is to make circus costumes with tiny plastic hats.', prompt: 'passion', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My passion is to make circus costumes with tiny plastic hats.', prompt: 'circus', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My passion is to make circus costumes with tiny plastic hats.', prompt: 'costumes', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My passion is to make circus costumes with tiny plastic hats.', prompt: 'tiny', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'My passion is to make circus costumes with tiny plastic hats.', prompt: 'plastic', answer: PMAStepTestAnswer.NoAnswer },
  ],
};

export const UNIT_3_6_FORM: { [key in PMAFormVariant]?: ModuleDetails[] } = {
  A: [
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'silliest',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'candies',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'mindless',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'animated',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'projector',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'Our silliest nights are when we eat hard candies and watch mindless animated shows on the projector for entertainment.',
      prompt: 'entertainment',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    { fullPrompt: 'I am unwilling to foolishly run near the breakable furniture.', prompt: 'unwilling', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am unwilling to foolishly run near the breakable furniture.', prompt: 'foolishly', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am unwilling to foolishly run near the breakable furniture.', prompt: 'breakable', answer: PMAStepTestAnswer.NoAnswer },
    { fullPrompt: 'I am unwilling to foolishly run near the breakable furniture.', prompt: 'furniture', answer: PMAStepTestAnswer.NoAnswer },
  ],
  B: [
    {
      fullPrompt: 'I was speechless from amazement when I saw the flexible ladies twist and turn rapidly.',
      prompt: 'speechless',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was speechless from amazement when I saw the flexible ladies twist and turn rapidly.',
      prompt: 'amazement',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was speechless from amazement when I saw the flexible ladies twist and turn rapidly.',
      prompt: 'flexible',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was speechless from amazement when I saw the flexible ladies twist and turn rapidly.',
      prompt: 'ladies',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I was speechless from amazement when I saw the flexible ladies twist and turn rapidly.',
      prompt: 'rapidly',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'The sailor carefully made the decision that the winds were too unstable and that it was best to wait for a sunnier day.',
      prompt: 'sailor',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'The sailor carefully made the decision that the winds were too unstable and that it was best to wait for a sunnier day.',
      prompt: 'carefully',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'The sailor carefully made the decision that the winds were too unstable and that it was best to wait for a sunnier day.',
      prompt: 'decision',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'The sailor carefully made the decision that the winds were too unstable and that it was best to wait for a sunnier day.',
      prompt: 'unstable',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'The sailor carefully made the decision that the winds were too unstable and that it was best to wait for a sunnier day.',
      prompt: 'sunnier',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
  C: [
    {
      fullPrompt: 'I felt such happiness when we completely decorated the house for parties and occasions. ',
      prompt: 'happiness',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I felt such happiness when we completely decorated the house for parties and occasions. ',
      prompt: 'completely',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I felt such happiness when we completely decorated the house for parties and occasions. ',
      prompt: 'decorated',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I felt such happiness when we completely decorated the house for parties and occasions. ',
      prompt: 'parties',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'I felt such happiness when we completely decorated the house for parties and occasions. ',
      prompt: 'occasions',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'On my luckiest day, I drove a fast convertible car on the fresh pavement at a safe location with an instructor.',
      prompt: 'luckiest',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'On my luckiest day, I drove a fast convertible car on the fresh pavement at a safe location with an instructor.',
      prompt: 'convertible',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'On my luckiest day, I drove a fast convertible car on the fresh pavement at a safe location with an instructor.',
      prompt: 'pavement',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'On my luckiest day, I drove a fast convertible car on the fresh pavement at a safe location with an instructor.',
      prompt: 'location',
      answer: PMAStepTestAnswer.NoAnswer,
    },
    {
      fullPrompt: 'On my luckiest day, I drove a fast convertible car on the fresh pavement at a safe location with an instructor.',
      prompt: 'instructor',
      answer: PMAStepTestAnswer.NoAnswer,
    },
  ],
};

export const UNIT_4_2_QUESTIONNAIRE: Layout5Questionnaire[] = [
  {
    title: 'Phrasing',
    options: [
      {
        text: 'Reads word-by-word, or in short phrases, but these are infrequent and/or are not words you would cluster together in line with good syntax.',
        val: 1,
      },
      {
        text: 'Reads in two-word phrases with some three- or four-word phrases. Some word-by-word reading still occurs. Word groupings may be awkward and unrelated to the larger context of the sentence.',
        val: 2,
      },
      {
        text: 'Reads mainly in three- or four-word phrases. Syntax is attended to when possible (pausing for periods at the end of sentences, etc.)',
        val: 3,
      },
      { text: 'Reads mainly in longer phrases, in line with the different punctuation present in the text.', val: 4 },
    ],
  },
  {
    title: 'Expression',
    options: [
      {
        text: 'Expressive interpretation of the text is not yet evident. The student does not change their voice, tone and overall prosody in line with the meaning of the words they are reading.',
        val: 1,
      },
      {
        text: 'Expressive interpretation of the text appears once or twice, although emphasis may be paid to certain words or phrases over others in a seemingly random manner. However, the student is demonstrating understanding that they can alter their expression in response to the text.',
        val: 2,
      },
      {
        text: 'Expressive interpretation is evident through some of the reading. The student is making meaning from some parts of the text as they read, adjusting their voice, tone and prosody during these times.',
        val: 3,
      },
      {
        text: 'Expressive interpretation is evident through most of the reading. It is clear the student is making meaning from the text as they read and is adjusting their voice, tone and overall prosody to convey meaning.',
        val: 4,
      },
    ],
  },
];

export const UNIT_4_3_QUESTIONNAIRE: Layout6Questionnaire[] = [
  {
    title: 'Text Specific Knowledge',
    options: [
      {
        text: 'Provides incorrect or extremely basic information, doesn’t return to the text to get better information when prompted, or identifies irrelevant information.',
        val: 1,
      },
      {
        text: 'Partial information provided, may return to text for more info when prompted or rely on memory. More precise information was available to report, but the student did not do so',
        val: 2,
      },
      {
        text: 'Specific information provided, including names and other relevant details, returns to text as required to gather or confirm precise information.',
        val: 3,
      },
    ],
  },
  {
    title: 'Text Structure',
    options: [
      {
        text: 'Is unable to discuss genre and text-specific features, elements and overall structure. Is unclear on the likely purpose for reading this text or how the text’s organization supports that purpose.',
        val: 1,
      },
      {
        text: 'Demonstrates partial understanding of genre and text-specific features, elements and overall structure. Can provide a basic purpose for reading, but may struggle to connect how the organization of the text supports their understanding and reading experience.',
        val: 2,
      },
      {
        text: 'Demonstrates understanding of the way the text is organized and their purpose for reading. Can correctly identify and utilize relevant text features or genre-based elements in the text to build comprehension.',
        val: 3,
      },
    ],
  },
  {
    title: 'Sentence Analysis',
    options: [
      {
        text: 'Could not answer sentence-level question or displays confusion over pronouns, direction words or other sentence features.',
        val: 1,
      },
      {
        text: 'Has difficulty in answering the sentence-level question, or demonstrates partial understanding of the focus sentence.',
        val: 2,
      },
      {
        text: 'Answered question correctly, showing command of syntax and sentence comprehension.',
        val: 3,
      },
    ],
  },
  {
    title: 'Verbal Reasoning',
    options: [
      {
        text: 'Little to no evidence of verbal reasoning, unable to connect text information and events to logical predictions, inferences, or implications that are not stated in the text explicitly, even with prompting.',
        val: 1,
      },
      {
        text: 'Basic verbal reasoning skills demonstrated through connecting text evidence to reasonable or logical inferences, but may be unable to explain their thinking or require a good amount of scaffolding to answer this question.',
        val: 2,
      },
      {
        text: 'Makes reasonable predictions or inferences and is able to explain what in the text informed their thinking. Demonstrates metacognition, or monitoring their own thinking.',
        val: 3,
      },
    ],
  },
  {
    title: 'Vocabulary (Overall)',
    subTitle: 'Consider the student’s use of vocabulary holistically.',
    options: [
      {
        text: 'Uses general words, does not provide specific vocabulary provided in the text. Words used may reflect some misunderstanding of meaning of words in the text. Example from a book about kangaroos: The mom kangaroo carries the baby in her stomach.',
        val: 1,
      },
      {
        text: 'Uses some specific vocabulary words from the text correctly and/or uses synonyms or other words that show they have grasped the meaning of text-specific vocabulary words. Example from a book about kangaroos: The mom kangaroo carries the baby kangaroo (what was it called?) in her sack.',
        val: 2,
      },
      {
        text: 'Uses names and/or other specific vocabulary words as used in the text and uses them correctly to show meaning has been grasped. Example from a book about kangaroos: The mother kangaroo carries the joey in her pouch.',
        val: 3,
      },
    ],
  },
];

export const UNIT_4_4_QUESTIONNAIRE: Layout6Questionnaire[] = [
  {
    title: 'Text Specific Knowledge',
    options: [
      {
        text: 'Provides incorrect or extremely basic information, doesn’t return to the text to get better information when prompted, or identifies irrelevant information.',
        val: 1,
      },
      {
        text: 'Partial information provided, may return to text for more info when prompted or rely on memory. More precise information was available to report, but the student did not do so',
        val: 2,
      },
      {
        text: 'Specific information provided, including names and other relevant details, returns to text as required to gather or confirm precise information.',
        val: 3,
      },
    ],
  },
  {
    title: 'Text Structure',
    options: [
      {
        text: 'Is unable to discuss genre and text-specific features, elements and overall structure. Is unclear on the likely purpose for reading this text or how the text’s organization supports that purpose.',
        val: 1,
      },
      {
        text: 'Demonstrates partial understanding of genre and text-specific features, elements and overall structure. Can provide a basic purpose for reading, but may struggle to connect how the organization of the text supports their understanding and reading experience.',
        val: 2,
      },
      {
        text: 'Demonstrates understanding of the way the text is organized and their purpose for reading. Can correctly identify and utilize relevant text features or genre-based elements in the text to build comprehension.',
        val: 3,
      },
    ],
  },
  {
    title: 'Sentence Analysis',
    options: [
      {
        text: 'Could not answer sentence-level question or displays confusion over pronouns, direction words or other sentence features.',
        val: 1,
      },
      {
        text: 'Has difficulty in answering the sentence-level question, or demonstrates partial understanding of the focus sentence.',
        val: 2,
      },
      {
        text: 'Answered question correctly, showing command of syntax and sentence comprehension.',
        val: 3,
      },
    ],
  },
  {
    title: 'Verbal Reasoning',
    options: [
      {
        text: 'Little to no evidence of verbal reasoning, unable to connect text information and events to logical predictions, inferences, or implications that are not stated in the text explicitly, even with prompting.',
        val: 1,
      },
      {
        text: 'Basic verbal reasoning skills demonstrated through connecting text evidence to reasonable or logical inferences, but may be unable to explain their thinking or require a good amount of scaffolding to answer this question.',
        val: 2,
      },
      {
        text: 'Makes reasonable predictions or inferences and is able to explain what in the text informed their thinking. Demonstrates metacognition, or monitoring their own thinking.',
        val: 3,
      },
    ],
  },
  {
    title: 'Vocabulary (Overall)',
    subTitle: 'Consider the student’s use of vocabulary holistically.',
    options: [
      {
        text: 'Uses general words, does not provide specific vocabulary provided in the text. Words used may reflect some misunderstanding of meaning of words in the text. Example from a book about kangaroos: The mom kangaroo carries the baby in her stomach.',
        val: 1,
      },
      {
        text: 'Uses some specific vocabulary words from the text correctly and/or uses synonyms or other words that show they have grasped the meaning of text-specific vocabulary words. Example from a book about kangaroos: The mom kangaroo carries the baby kangaroo (what was it called?) in her sack.',
        val: 2,
      },
      {
        text: 'Uses names and/or other specific vocabulary words as used in the text and uses them correctly to show meaning has been grasped. Example from a book about kangaroos: The mother kangaroo carries the joey in her pouch.',
        val: 3,
      },
    ],
  },
];
