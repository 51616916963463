import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SHORT_MONTH_DAY_YEAR } from '@hoot/constants/constants';
import useGetStudentTimeline, { TimelineFilterType, TimelineViewOption } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import {
  eventVisibilityLabels,
  focusVisibilityLabels,
  periodBasedLabels,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/common';
import EventVisibilityMenu, { EventOptionsType } from './EventVisibilityMenu';
import FocusVisibilityMenu, { FocusOptionType } from './FocusVisibilityMenu';
import TimelineFilterDialog, { TimelineFilterFormValues } from './TimelineFilterDialog';
import TimelineGrid from './TimelineGrid';
import TimelineLegendDialog from './TimelineLegendDialog';
import { EventVisibilityOption, FilterTypeValues, FocusVisibilityOption, PeriodBasedValues } from './enums';

type ChipType = 'FOCUS' | 'EVENT';

const StudentTimelinePage = () => {
  const { studentProfileId } = useParams();
  const [isTlFilterDialogOpen, setIsTlFilterDialogOpen] = useState<boolean>(false);
  const [tlFilterOptions, setTlFilterOptions] = useState<Pick<TimelineFilterFormValues, 'startDate' | 'endDate' | 'viewOption'>>({
    startDate: null, // TODO: SC-11936 - Set the defaults for these as required.
    endDate: null,
    viewOption: null,
  });

  const [isFocusVisibilityOpen, setIsFocusVisibilityOpen] = useState<HTMLButtonElement | null>(null);
  const [focusOptions, setFocusOptions] = useState<FocusOptionType>({
    [FocusVisibilityOption.PreWordReading]: false, // TODO: SC-11936 - Set the defaults for these as required.
    [FocusVisibilityOption.EarlyWordReading]: false,
    [FocusVisibilityOption.ComplexReading]: false,
    [FocusVisibilityOption.TextLanguage]: false,
    [FocusVisibilityOption.TextReading]: false,
    [FocusVisibilityOption.OtherAreas]: false,
  });

  const [isEventVisibilityOpen, setIsEventVisibilityOpen] = useState<HTMLButtonElement | null>(null);
  const [eventOptions, setEventOptions] = useState<EventOptionsType>({
    [EventVisibilityOption.Hra]: true,
    [EventVisibilityOption.Pma]: true,
    [EventVisibilityOption.Absence]: false,
    [EventVisibilityOption.Cancellation]: false,
    [EventVisibilityOption.Lessons]: true,
  });

  const [isTimelineLegendOpen, setIsTimelineLegendOpen] = useState<boolean>(false);

  const toggleTimelineFilter = () => setIsTlFilterDialogOpen((prev) => !prev);

  const onFocusVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => setIsFocusVisibilityOpen(e.currentTarget);
  const onDismissFocusVisibility = () => setIsFocusVisibilityOpen(null);

  const onEventVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => setIsEventVisibilityOpen(e.currentTarget);
  const onDismissEventVisibility = () => setIsEventVisibilityOpen(null);

  const toggleLegendDialog = () => setIsTimelineLegendOpen((prev) => !prev);

  // TODO: SC-11936
  // Filter logic will be added in a following ticket. The defaults here are just for testing. This time range should be able to retrieve all the assessments generated for the student.
  const { data: timelineData, isFetching } = useGetStudentTimeline(
    studentProfileId ?? '',
    {
      filterType: TimelineFilterType.DateBased,
      viewOption: TimelineViewOption.None,
      fromDate: DateTime.fromISO('2020-01-01').toMillis(),
      toDate: DateTime.fromISO('2024-12-31').toMillis(),
    },
    { enabled: !!studentProfileId },
  );

  const handleTimelineFilterApply = (timelineFilter: TimelineFilterFormValues) => {
    if (timelineFilter.filterType === FilterTypeValues.DateBased) {
      setTlFilterOptions({
        startDate: timelineFilter.startDate,
        endDate: timelineFilter.endDate,
        viewOption: null,
      });
    }

    if (timelineFilter.filterType === FilterTypeValues.PeriodBased) {
      setTlFilterOptions({
        startDate: null,
        endDate: null,
        viewOption: timelineFilter.viewOption,
      });
    }
  };

  const handleDeleteChip = (chipType: ChipType, option: FocusVisibilityOption | EventVisibilityOption) => {
    if (chipType === 'FOCUS') {
      setFocusOptions((prevOptions) => ({
        ...prevOptions,
        [option]: false,
      }));
    } else if (chipType === 'EVENT') {
      setEventOptions((prevOptions) => ({
        ...prevOptions,
        [option]: false,
      }));
    }
  };

  return (
    <>
      <PageLayout>
        <Card title="Student Timeline" isLoading={isFetching}>
          <Stack gap={2}>
            <Stack direction="row" gap={2}>
              <Button onClick={toggleTimelineFilter} startIcon={<Icon name="calendar" />} variant="contained" color="neutral.180">
                Timeline Filter
              </Button>
              <Button onClick={onFocusVisibilityClick} startIcon={<Icon name="show" />} variant="contained" color="neutral.180">
                Focus Visibility
              </Button>
              <Button onClick={onEventVisibilityClick} startIcon={<Icon name="show" />} variant="contained" color="neutral.180">
                Event Visibility
              </Button>
              <Button onClick={toggleLegendDialog} variant="contained" color="neutral.180">
                {isTimelineLegendOpen ? 'Hide Legend' : 'Show Legend'}
              </Button>
            </Stack>

            {/* Chip row(s) */}
            <Box display="flex" flexWrap="wrap" gap={2}>
              {/* Timeline Filter Chips */}
              {tlFilterOptions.startDate && tlFilterOptions.endDate && (
                <Chip
                  label={`Timeline Date: 
                    ${tlFilterOptions.startDate.toFormat(SHORT_MONTH_DAY_YEAR)} - 
                    ${tlFilterOptions.endDate.toFormat(SHORT_MONTH_DAY_YEAR)}
                  `}
                  onDelete={() =>
                    setTlFilterOptions((prev) => ({
                      ...prev,
                      startDate: null,
                      endDate: null,
                    }))
                  }
                  deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                  sx={{
                    background: '#000',
                    color: '#FFF',
                  }}
                />
              )}
              {tlFilterOptions.viewOption && (
                <Chip
                  label={`Timeline Option:
                    ${periodBasedLabels[tlFilterOptions.viewOption as PeriodBasedValues]}
                  `}
                  onDelete={() =>
                    setTlFilterOptions((prev) => ({
                      ...prev,
                      viewOption: null,
                    }))
                  }
                  deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                  sx={{
                    background: '#000',
                    color: '#FFF',
                  }}
                />
              )}

              {/* Focus Chips */}
              {Object.entries(focusOptions).map(
                ([key, checked]) =>
                  checked && (
                    <Chip
                      key={key}
                      label={`Focus: ${focusVisibilityLabels[key as FocusVisibilityOption]}`}
                      onDelete={() => handleDeleteChip('FOCUS', key as FocusVisibilityOption)}
                      deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                      sx={{
                        background: '#000',
                        color: '#FFF',
                      }}
                    />
                  ),
              )}

              {/* Event Chips */}
              {Object.entries(eventOptions).map(
                ([key, checked]) =>
                  checked && (
                    <Chip
                      key={key}
                      label={`Event: ${eventVisibilityLabels[key as EventVisibilityOption]}`}
                      onDelete={() => handleDeleteChip('EVENT', key as EventVisibilityOption)}
                      deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                      sx={{
                        background: '#000',
                        color: '#FFF',
                      }}
                    />
                  ),
              )}
            </Box>
          </Stack>

          <Stack mt={6} mb={3} gap={2} sx={{ overflowX: 'auto' }}>
            <TimelineGrid data={timelineData} studentProfileId={studentProfileId!} />
          </Stack>
        </Card>
      </PageLayout>

      {/* Dialogs & Menus */}
      <TimelineFilterDialog open={isTlFilterDialogOpen} onDismiss={toggleTimelineFilter} onApply={handleTimelineFilterApply} />
      <FocusVisibilityMenu
        anchorElement={isFocusVisibilityOpen}
        onDismiss={onDismissFocusVisibility}
        focusOptions={focusOptions}
        setFocusOptions={setFocusOptions}
      />
      <EventVisibilityMenu
        anchorElement={isEventVisibilityOpen}
        onDismiss={onDismissEventVisibility}
        eventOptions={eventOptions}
        setEventOptions={setEventOptions}
      />
      <TimelineLegendDialog open={isTimelineLegendOpen} onDismiss={toggleLegendDialog} />
    </>
  );
};

export default StudentTimelinePage;
