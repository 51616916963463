import { UserProfileType } from './user';

export interface StudentProfile {
  id: string;
  userId: string;
  name: string;
  number: number;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt: number;
  birthYear: number;
  interests: string;
  focusChallenges: FocusChallenges;
  schedulePreferences: string;
  pronouns: string;
  parentNotes: string;
  status: StudentStatus;
  leadInitiationDate: Date;
  conversionDate: Date;
  notConvertedReason: NotConvertedReason;
  pauseEndDate: Date;
  lapsedDate: Date;
  lapsedReason: LapsedReason;
  studentType: StudentType;
}

export type LapsedReason =
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'FUNDING_PERIOD'
  | 'PROGRAM_EXPIRATION'
  | 'GRADUATED'
  | 'TEACHER_SCHEDULING'
  | 'OTHER';

export type NotConvertedReason =
  | 'TOO_OLD'
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_SCHEDULING'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'PRIVACY'
  | 'OTHER';

export type StudentStatus = 'ACTIVE' | 'LEAD' | 'COLD' | 'PAUSED' | 'LAPSED';

export enum StudentStatusEnum {
  Active = 'ACTIVE',
  Lead = 'LEAD',
  Cold = 'COLD',
  Paused = 'PAUSED',
  Lapsed = 'LAPSED',
}

export enum FocusChallenges {
  Yes = 'YES',
  No = 'NO',
}

export enum ReadingStrengthsGoals {
  Phonics = 'PHONICS',
  Decoding = 'DECODING',
  Expression = 'EXPRESSION',
  UsingPunctuation = 'USING_PUNCTUATION',
  Fluency = 'FLUENCY',
  ComprehensionSummarizing = 'COMPREHENSION_SUMMARIZING',
  ComprehensionPredicting = 'COMPREHENSION_PREDICTING',
  ComprehensionMakingInferences = 'COMPREHENSION_MAKING_INFERENCES',
  Vocabulary = 'VOCABULARY',
  Other = 'OTHER',
}

export enum MathStrengthsGoals {
  Subitization = 'SUBITIZATION',
  Counting = 'COUNTING',
  OralCounting = 'ORAL_COUNTING',
  NumberRecognition = 'NUMBER_RECOGNITION',
  Sequencing = 'SEQUENCING',
  Money = 'MONEY',
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
  Multiplication = 'MULTIPLICATION',
  Division = 'DIVISION',
  Fractions = 'FRACTIONS',
  Time = 'TIME',
  Geometry = 'GEOMETRY',
  Other = 'OTHER',
}

export enum StudentType {
  HootDecodableAccess = 'HOOT_DECODABLE_ACCESS',
  HootTutoring = 'HOOT_TUTORING',
}

export enum PasswordStatus {
  Set = 'SET',
  NotSet = 'NOT_SET',
}
