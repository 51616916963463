import { Stack, Typography } from '@mui/material';
import { hootTokens } from '../../../../../theme/v2/tokens';

export function Unit1Module1Example() {
  return <Example text={['Tell me all the sounds in "rest."', '/r/ /e/ /s/ /t/', 'Correct']} />;
}

export function Unit1Module2Example() {
  return <Example text={['/m/ /a/ /p/. Now you say it fast.', 'map', 'Correct']} />;
}

export function Unit1Module3Example() {
  return <Example text={['Say storm. Now say storm, but don’t say /m/. What word do you get?', 'storm', 'Correct']} />;
}

export function Unit1Module4Example() {
  return <Example text={['Say beat. Now say beat, but instead of /ē/, say /ō/. What word do you get?', 'boat', 'Correct']} />;
}

function Example(props: { text: string[] }) {
  return (
    <Stack
      width="263px"
      spacing="16px"
      sx={{
        backgroundColor: hootTokens.surface[2],
        borderRadius: '8px',
        padding: '24px',
      }}
    >
      {props.text.map((t, idx) => (
        <Stack direction="row" justifyContent={idx % 2 === 0 ? 'flex-start' : 'flex-end'}>
          {idx % 2 === 0 ? <StudentSpeechBubble text={t} /> : <TeacherSpeechBubble text={t} />}
        </Stack>
      ))}
    </Stack>
  );
}

function TeacherSpeechBubble(props: { text: string }) {
  const { text } = props;
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        padding: '8px',
        backgroundColor: hootTokens.palette.warning[180],
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation1,
      }}
    >
      <Typography variant="labelsmall">{text}</Typography>
    </Stack>
  );
}

function StudentSpeechBubble(props: { text: string }) {
  const { text } = props;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{
        padding: '8px',
        backgroundColor: hootTokens.palette.success[180],
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation1,
      }}
    >
      <Typography variant="labelsmall">{text}</Typography>
    </Stack>
  );
}
