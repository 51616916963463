import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@hoot/constants/featureFlags';
import { useGetStudentGrade } from '@hoot/hooks/api/assessment/useGetStudentGrade';
import useSearchStudentTeacherPMAs, { PublicSearchStudentTeacherPMAsRequest } from '@hoot/hooks/api/pma/useSearchStudentTeacherPMAs';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { ProgressMonitoringAssessmentStatus } from '@hoot/models/api/enums/pma-enums';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { RootState } from '@hoot/redux/store';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import StudentGradeConfirmationDialog from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/StudentGradeConfirmationDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { StudentAssessmentsQuerySortKeyEnum, useGetAssessmentsV2 } from '../../../../../hooks/api/assessment/useGetStudentAssessmentsV2';
import { HootAssessmentStatus } from '../../../../../models/api/enums/hoot-reading-assessment';
import { OrderBy } from '../../../../../models/api/enums/queryEnums';
import { Button } from '../../core/Button';
import HootTypography from '../../core/HootTypography';
import { Icon } from '../../core/Icon';
import HRANotificationDialog from '../../dialogs/HRANotificationDialog';
import PMANotificationDialog from '../../dialogs/PMANotificationDialog';

const LessonNotification = () => {
  const { isFeatureEnabled } = useFeatureFlags();

  const isSC10263Active = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const { activePage } = useActiveLessonState();

  const isLessonPMAType = inLesson?.type === ScheduledLessonType.ProgressMonitoring;
  const isLessonHRAType = isSC10263Active && inLesson?.type === ScheduledLessonType.Assessment;

  if (activePage !== ActiveLessonPage.Library) {
    return null;
  }

  return (
    <>
      {isLessonPMAType ? <PMANotification /> : null}
      {isLessonHRAType ? <HRANotification /> : null}
    </>
  );
};

function PMANotification() {
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const { activePage } = useActiveLessonState();

  // === PMA Notification Logic Start ===
  const [isPMANotificationModalOpen, setIsPMANotificationModalOpen] = useState<boolean>(false);

  // PMA
  const searchStudentTeacherPMAsQuery: PublicSearchStudentTeacherPMAsRequest = {
    studentProfileId: inLesson?.studentId ?? '',
    teacherId: inLesson?.teacherId ?? '',
  };

  const { data: studentTeacherPMAs } = useSearchStudentTeacherPMAs(searchStudentTeacherPMAsQuery, {
    enabled: !!inLesson,
  });

  const inProgressPMA = studentTeacherPMAs?.progressMonitoringAssessments.find((pma) => pma.status === ProgressMonitoringAssessmentStatus.InProgress);
  const completedTodayPMA = studentTeacherPMAs?.progressMonitoringAssessments.find(
    (pma) =>
      pma.status === ProgressMonitoringAssessmentStatus.Completed && DateTime.now().toISODate() === DateTime.fromMillis(pma.updatedAt).toISODate(),
  );

  const shouldNotifyForPMA = !!inLesson && (!!inProgressPMA || !completedTodayPMA);

  const notificationCount = shouldNotifyForPMA ? 1 : 0;

  // === PMA Notification Logic End ===

  if (notificationCount === 0 || activePage === ActiveLessonPage.PMA) {
    return null;
  }

  const onViewClick = () => {
    setIsPMANotificationModalOpen(true);
  };

  return (
    <>
      <Stack
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '8px',
          boxShadow: hootTokens.elevation.elevation2,
          padding: '24px',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack gap={1} sx={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent={'center'} gap={1}>
            <Icon name="bell_notification" />
            <HootTypography isPII={false} variant="titlesmall" textAlign="center">
              {`${notificationCount} Notification(s)`}
            </HootTypography>
          </Stack>
          <Button
            onClick={onViewClick}
            variant="contained"
            color="warning.140"
            fullWidth
            sx={{
              px: '16px',
              py: '8px',
            }}
          >
            View
          </Button>
        </Stack>
      </Stack>

      <PMANotificationDialog isOpen={isPMANotificationModalOpen} setIsOpen={setIsPMANotificationModalOpen} isResumingAssessment={!!inProgressPMA} />
    </>
  );
}

function HRANotification() {
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const [isGradeConfirmationDialogOpen, setIsGradeConfirmationDialogOpen] = useState<boolean>(false);

  // === HRA Notification Logic Start ===
  const [isHRANotificationModalOpen, setIsHRANotificationModalOpen] = useState<boolean>(false);

  // HRA
  const assessments = useGetAssessmentsV2(inLesson?.studentId!, {
    page: 1,
    pageSize: 30,
    orderBy: StudentAssessmentsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });

  // Check if student has grade set
  const { data, refetch: refetchGrade } = useGetStudentGrade(inLesson?.studentId ?? '', { enabled: !!inLesson?.studentId });

  const studentGrade = data?.grade ?? null;

  const inProgressAssement = assessments.data?.data.find((a) => a.status === HootAssessmentStatus.InProgress);
  const completedToday = assessments.data?.data.find(
    (a) => a.status === HootAssessmentStatus.Completed && DateTime.fromMillis(a.updatedAt).startOf('day') === DateTime.now().startOf('day'),
  );

  // === HRA Notification Logic End ===

  const onViewClick = () => {
    if (studentGrade) {
      setIsHRANotificationModalOpen(true);
    } else {
      setIsGradeConfirmationDialogOpen(true);
    }
  };

  const onConfirmGrade = () => {
    refetchGrade();
    setIsGradeConfirmationDialogOpen(false);
    setIsHRANotificationModalOpen(true);
  };

  if (completedToday) {
    return null;
  }

  return (
    <Stack
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation2,
        padding: '24px',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack gap={1} sx={{ width: '100%' }}>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          <Icon name="bell_notification" />
          <HootTypography isPII={false} variant="titlesmall" textAlign="center">
            Notification
          </HootTypography>
        </Stack>
        <Button
          onClick={onViewClick}
          variant="contained"
          color="warning.140"
          fullWidth
          sx={{
            px: '16px',
            py: '8px',
          }}
        >
          View
        </Button>
      </Stack>
      <HRANotificationDialog
        isOpen={isHRANotificationModalOpen}
        isResumingAssessment={!!inProgressAssement}
        setIsOpen={onViewClick}
        key={`hra-notification-dialog-${isHRANotificationModalOpen}`}
      />
      <StudentGradeConfirmationDialog
        show={isGradeConfirmationDialogOpen}
        onConfirm={onConfirmGrade}
        onDismiss={() => setIsGradeConfirmationDialogOpen(false)}
        studentName={inLesson!.studentName}
        studentId={inLesson!.studentId}
        studentGrade={studentGrade}
      />
    </Stack>
  );
}

export default LessonNotification;
