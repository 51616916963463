import { Stack, Toolbar, Tooltip } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import React, { useRef, useState } from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { UserProfileType } from '@hoot/models/api/user';
import { ReaderContext, readerKeyLookup, useReaders } from '@hoot/redux/reducers/readingSlice';
import { NewAppVersionAvailableBanner } from '@hoot/ui/components/v2/core/NewAppVersionAvailableBanner';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import { Coords, ZoomControl } from '@hoot/ui/components/v2/library/ZoomControl';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';
import StartWhiteboardDialog from '@hoot/ui/pages/v2/teacher/lesson/library/StartWhiteboardDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useProfile from '../../../../hooks/useProfile';
import TeacherBookPreviewModal from '../../../pages/v2/teacher/library/TeacherBookPreviewModal';
import BasicAlertDialog from '../dialogs/BasicAlertDialog';
import { InfoIcon } from '../icons/InfoFilledIcon';
import HootTypography from './HootTypography';
import { Icon } from './Icon';
import IconButton from './IconButton';
import { JoinLessonBanner } from './JoinLessonBanner';

export interface ReadingAppBarProps {
  readerContext: ReaderContext;
  studentProfileId?: string;
  onToggleFavouriteBook: (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => void;
  onToggleDoublePage: () => void;
  onResetZoom: () => void;
  onZoomOffsetChange: (offsetLeft: number, offsetTop: number) => void;
  onZoomAmountChange: (value: number) => void;
  onCloseBook: () => void;
  onOpenWhiteboard?: (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => void;
}

const TeacherReaderAppBar = (props: ReadingAppBarProps) => {
  const {
    readerContext,
    studentProfileId,
    onResetZoom,
    onZoomAmountChange,
    onZoomOffsetChange,
    onCloseBook,
    onToggleDoublePage,
    onToggleFavouriteBook,
    onOpenWhiteboard,
  } = props;

  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const [showLeaveReaderModal, setShowLeaveReaderModal] = useState<boolean>(false);
  const [showBookInfoModal, setShowBookInfoModal] = useState<boolean>(false);
  const [showZoomControl, setShowZoomControl] = useState<boolean>(false);
  const [showStartWhiteboardDialog, setShowStartWhiteboardDialog] = useState<boolean>(false);
  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);
  const zoomBoxCoordsRef = useRef<Coords>({ x: 0, y: 0 });

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const { profile } = useProfile();

  const readerKey = readerKeyLookup[readerContext];
  const reader = useReaders()[readerKey];

  const { book, isDoublePage, pageIndex, pageZoom } = reader;
  const { zoomAmount } = pageZoom;

  const toolsMenuItems = (): MenuItem[] => {
    const toolItemsArray = [
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      { id: 'zoom', label: 'Zoom', icon: <Icon name="zoom_in" />, onClick: onZoomClick },
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
    ];

    if (readerContext === ReaderContext.InLessonReader) {
      toolItemsArray.splice(
        -1,
        0,
        {
          id: 'notepad',
          label: 'Notepad',
          icon: <Icon name="pen" />,
          onClick: toggleShowNotepad,
        },
        {
          id: 'celebrations',
          label: 'Celebrations',
          icon: <Icon name="hoot_wheel" />,
          onClick: toggleCelebrationsDialog,
        },
      );
      toolItemsArray.splice(-1, 0, {
        id: 'whiteboard',
        label: 'Whiteboard',
        icon: <Icon name="whiteboard" />,
        onClick: onWhiteboardClick,
      });
    }
    return toolItemsArray;
  };

  if (!book) {
    return null;
  }

  const title = book.title;

  const isFirstPage = pageIndex === 0;
  const disableDoublePage = !book.isDoublePageEnabled || (book.isStandardDoublePage && isFirstPage);

  const doublePageToolTip = () => {
    if (!book.isDoublePageEnabled) {
      return 'This feature is not available for decodables';
    }

    if (disableDoublePage) {
      return 'Double Page Disabled on First Page';
    }

    return isDoublePage ? 'Show Single Page View' : 'Show Double Page View';
  };

  // Modal Event Handlers
  const handleCancelLeaveReaderModal = () => {
    setShowLeaveReaderModal(false);
  };

  const onInfoClick = () => {
    setShowBookInfoModal(true);
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const onZoomClick = () => {
    setShowZoomControl((currentState) => !currentState);
    dismissToolsMenu();
  };

  const onCloseClick = () => {
    setShowLeaveReaderModal(true);
  };

  const onWhiteboardClick = () => {
    setShowStartWhiteboardDialog(true);
    dismissToolsMenu();
  };

  return (
    <>
      <MuiAppBar
        position="static"
        sx={{
          borderRadius: '8px',
          borderBottom: 'unset',
          boxShadow: hootTokens.elevation.elevation1,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            displayPrint: 'none',
            padding: '16px',
          }}
        >
          <Stack direction="row" alignItems="center" flex={1}>
            <HootTypography variant="titlelarge" isPII={false}>
              {title}
            </HootTypography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
            {/* Tools Menu */}
            <Tooltip title="Show Tools Menu">
              <span>
                <IconButton onClick={showToolsMenu}>
                  <Icon name="divider_caliper" />
                </IconButton>
              </span>
            </Tooltip>
            <PopoverMenu
              id="tools-menu"
              variant="dark"
              anchorEl={toolsIconButtonElement}
              open={!!toolsIconButtonElement}
              onClose={dismissToolsMenu}
              items={toolsMenuItems()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />

            {/* Single/Double Page */}
            <Tooltip title={doublePageToolTip()}>
              <span>
                <IconButton onClick={onToggleDoublePage} disabled={disableDoublePage}>
                  <Icon name={isDoublePage ? 'book_double' : 'single_page'} color={disableDoublePage ? 'primary.80' : 'primary'} />
                </IconButton>
              </span>
            </Tooltip>

            {/* Book Info */}
            <Tooltip title="Show Book Info">
              <span>
                <IconButton onClick={onInfoClick}>
                  <InfoIcon />
                </IconButton>
              </span>
            </Tooltip>

            {/* Favourite */}
            <ToggleFavouriteBookIconButton
              book={book}
              profileId={studentProfileId ?? profile!.id}
              profileType={studentProfileId ? UserProfileType.Student : UserProfileType.Teacher}
              onToggleFavouriteBook={onToggleFavouriteBook}
            />

            {/* Close Button */}
            <Tooltip title="Return to Library">
              <span>
                <IconButton onClick={onCloseClick}>
                  <Icon name="close_filled_square" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Toolbar>
      </MuiAppBar>

      {/* Banners */}
      <JoinLessonBanner />
      <NewAppVersionAvailableBanner />

      {/* Dialogs */}
      {showLeaveReaderModal ? <LeaveReaderModal onCancel={handleCancelLeaveReaderModal} onTakeMeBackToLibrary={onCloseBook} /> : null}

      {showBookInfoModal ? (
        <Stack sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <TeacherBookPreviewModal
            show
            book={book!}
            studentProfileId={studentProfileId}
            isInLesson={readerContext === ReaderContext.InLessonReader}
            onDismiss={() => setShowBookInfoModal(false)}
            onToggleFavouriteBook={onToggleFavouriteBook}
          />
        </Stack>
      ) : null}

      {showZoomControl ? (
        <ZoomControl
          onClose={() => setShowZoomControl(false)}
          pages={book.pages}
          onReset={onResetZoom}
          onZoomChange={onZoomAmountChange}
          onOffsetChange={onZoomOffsetChange}
          zoomValue={zoomAmount * 100}
          zoomBoxCoordsRef={zoomBoxCoordsRef}
          onToggleDoublePage={onToggleDoublePage}
          readerContext={readerContext}
        />
      ) : null}

      {onOpenWhiteboard && showStartWhiteboardDialog && (
        <StartWhiteboardDialog
          show={showStartWhiteboardDialog}
          onOpenWhiteboard={onOpenWhiteboard}
          onDismiss={() => setShowStartWhiteboardDialog(false)}
        />
      )}
    </>
  );
};

interface ToggleFavouriteBookIconButtonProps {
  book: BookResponse;
  profileId: string;
  profileType: UserProfileType;
  onToggleFavouriteBook: (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => void;
}

const ToggleFavouriteBookIconButton = (props: ToggleFavouriteBookIconButtonProps) => {
  const { book, profileId, profileType, onToggleFavouriteBook } = props;

  const isFavourite = book.shelf[profileId];

  const _onToggleFavouriteBook = () => {
    const markAsFav = !book!.shelf[profileId];
    onToggleFavouriteBook(book.id, profileId, profileType, markAsFav);
  };

  const getLabel = () => {
    switch (profileType) {
      case UserProfileType.Student:
        return isFavourite ? 'Remove from Student Favorites' : 'Add to Student Favorites';
      case UserProfileType.Teacher:
        return isFavourite ? 'Remove from my Favorites list' : 'Add to my Favorites list';
    }
  };

  return (
    <Tooltip title={getLabel()}>
      <span>
        <IconButton onClick={_onToggleFavouriteBook}>
          <Icon name={isFavourite ? 'favourite_filled' : 'favourite'} />{' '}
        </IconButton>
      </span>
    </Tooltip>
  );
};

function LeaveReaderModal(props: { onCancel: () => void; onTakeMeBackToLibrary: () => void }) {
  return (
    <BasicAlertDialog
      primaryAction={{
        label: 'Yes, take me back to library',
        onClick: () => props.onTakeMeBackToLibrary(),
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: () => props.onCancel(),
      }}
      title="Are you sure you want to return to library?"
      onDismiss={() => props.onCancel()}
      show={true}
      showClose
      maxWidth="sm"
      content={<HootTypography isPII={false}></HootTypography>}
    />
  );
}

export default TeacherReaderAppBar;
