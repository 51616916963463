import { Stack, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TEXT_INPUT_DEBOUNCE_TIME_MS } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { LessonReviewStateResponseMessage, SetLessonReviewStateMessage } from '@hoot/events/messages/lesson-review-state.message';
import { LessonReviewSubmittedNotificationMessage } from '@hoot/events/messages/lesson-review-submitted-notification.message';
import useGetRecentStudentLessonReviews from '@hoot/hooks/api/lesson-reviews/useGetRecentStudentLessonReviews';
import useGetLessonReviewState from '@hoot/hooks/api/lesson/useGetLessonReviewState';
import { LessonReviewStateRequest, useSaveLessonReviewState } from '@hoot/hooks/api/lesson/useSaveLessonReviewState';
import useProfile from '@hoot/hooks/useProfile';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import { useSocket } from '@hoot/ui/context/SocketContext';
import TextField, { TextFieldProps } from '../core/TextField';

export interface NotepadDialogProps {
  show: boolean;
  onHide: () => void;
}

const NotepadDialog = (props: NotepadDialogProps) => {
  const { show, onHide } = props;
  const useSaveLessonReviewStateRequest = useSaveLessonReviewState();
  const [notepadTextInput, setNotepadTextInput] = useState<string>('');
  const [hasLessonReviewBeenSubmitted, setHasLessonReviewBeenSubmitted] = useState<boolean>(false);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const dispatch = useAppDispatch();
  const { socket } = useSocket();
  const { profile } = useProfile();

  useGetLessonReviewState(inLesson?.lessonId ?? '', {
    enabled: !!inLesson,
    onSuccess: (data) => {
      if (!!data) {
        setNotepadTextInput(data.notepadText);
      }
    },
  });

  useGetRecentStudentLessonReviews(inLesson?.studentId ?? '', {
    enabled: !!inLesson?.studentId,
    onSuccess: (data) => {
      for (const lessonReview of data) {
        if (lessonReview.scheduledLessonId === inLesson?.lessonId) {
          setHasLessonReviewBeenSubmitted(true);
        }
      }
    },
  });

  const timerRef = useRef<number>();

  useEffect(() => {
    function handleUpdateLessonReviewNoteDisplay(response: LessonReviewStateResponseMessage) {
      if (response.lessonId === inLesson?.lessonId) {
        setNotepadTextInput(response.notepadText);
      }
    }

    socket.on(EventType.UpdateLessonReviewNoteDisplay, handleUpdateLessonReviewNoteDisplay);

    return () => {
      socket.off(EventType.UpdateLessonReviewNoteDisplay, handleUpdateLessonReviewNoteDisplay);
    };
  }, [socket, inLesson?.lessonId]);

  useEffect(() => {
    function handleLessonReviewSubmittedNotification(response: LessonReviewSubmittedNotificationMessage) {
      if (inLesson?.lessonId === response.reviewLessonId) {
        setHasLessonReviewBeenSubmitted(true);
      }
    }

    socket.on(EventType.LessonReviewSubmittedNotification, handleLessonReviewSubmittedNotification);

    return () => {
      socket.off(EventType.LessonReviewSubmittedNotification, handleLessonReviewSubmittedNotification);
    };
  }, [socket, inLesson?.lessonId]);

  if (!inLesson) {
    return null;
  }

  const onSubmit = () => {
    const request: LessonReviewStateRequest = {
      notepadText: notepadTextInput,
      lessonId: inLesson.lessonId,
      studentProfileId: inLesson.studentId,
    };

    useSaveLessonReviewStateRequest.mutate(request, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: 'Notes successfully saved', variant: 'dark' }));
      },
      onError: (ex) => {
        console.error(ex);
        dispatch(
          createFlashMessage({
            message: "An error occurred while attempting to save the lesson's notepad text.",
            variant: 'error',
          }),
        );
      },
    });
  };

  const _onTextInputChanged: TextFieldProps['onChange'] = (event) => {
    setNotepadTextInput(event.target.value);

    timerRef.current = window.setTimeout(() => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!!inLesson && !!profile) {
        const requestMessage: SetLessonReviewStateMessage = {
          notepadText: event.target.value,
          lessonId: inLesson.lessonId,
          studentProfileId: inLesson.studentId,
          teacherProfileId: profile.id,
        };

        socket.emit(EventType.SetLessonReviewState, requestMessage);
      }
    }, TEXT_INPUT_DEBOUNCE_TIME_MS);
  };

  const saveAndClose = () => {
    onSubmit();
    onHide();
  };

  return (
    <>
      {show && (
        <FloatingDialog width="308px" height="600px" title="Notepad" onClose={saveAndClose}>
          <Stack height={'100%'} alignItems={'space-between'} gap={4}>
            <TextField
              variant="outlined"
              fullWidth
              id="notes"
              label="Notes"
              onChange={_onTextInputChanged}
              value={notepadTextInput}
              multiline
              rows={17}
              inputProps={{
                style: {
                  cursor: hasLessonReviewBeenSubmitted ? 'not-allowed' : 'default',
                },
              }}
              disabled={hasLessonReviewBeenSubmitted}
            />

            <Tooltip title={hasLessonReviewBeenSubmitted ? 'Cannot edit a note on an already submitted Lesson Review.' : undefined}>
              <div style={{ display: 'flex', cursor: hasLessonReviewBeenSubmitted ? 'not-allowed' : 'default' }}>
                <Button variant="contained" type="button" onClick={saveAndClose} disabled={hasLessonReviewBeenSubmitted} sx={{ width: '100%' }}>
                  Save and Close
                </Button>
              </div>
            </Tooltip>
          </Stack>
        </FloatingDialog>
      )}
    </>
  );
};

export default NotepadDialog;
