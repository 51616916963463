import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Option } from '@hoot/models/formOption';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { Select } from '@hoot/ui/components/v2/core/Select';

interface IProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (form: FormativeAssessmentBooksFilterForm) => void;
  bookUnitsOptions: Option[];
  bookUnit?: string;
  bookNumber?: string;
  bookLevel?: string;
}

export interface FormativeAssessmentBooksFilterForm {
  bookLevel?: string;
  bookUnit?: string;
  bookNumber?: string;
}

const FormativeAssessmentBooksFilterDialog = (props: IProps) => {
  const { show, onDismiss, onApply, bookLevel, bookUnitsOptions, bookUnit, bookNumber } = props;

  const [showUnitFilter, setShowUnitFilter] = useState<boolean>(!!bookUnit);
  const [showNumberFilter, setShowNumberFilter] = useState<boolean>(!!bookNumber);
  const filterCount = [showUnitFilter, showNumberFilter].filter((f) => !!f).length;

  const numberOptions = [1, 2];

  const { handleSubmit, control, reset, setValue } = useForm<FormativeAssessmentBooksFilterForm>({
    defaultValues: {
      bookUnit: bookUnit ?? '',
      bookLevel: bookLevel ?? '',
      bookNumber: bookNumber ?? '',
    },
  });

  const handleDismiss = () => {
    reset();
    setShowUnitFilter(false);
    setShowNumberFilter(false);
    onDismiss();
  };

  const onSubmit = (form: FormativeAssessmentBooksFilterForm) => {
    onApply(form);
    onDismiss();
  };

  const onClear = () => {
    reset({
      bookUnit: '',
      bookLevel: '',
      bookNumber: '',
    });
    setShowUnitFilter(false);
    setShowNumberFilter(false);
  };

  const toggleUnitFilter = () => {
    setShowUnitFilter((current) => !current);
    setValue('bookUnit', '');
  };

  const toggleNumberFilter = () => {
    setShowNumberFilter((current) => !current);
    setValue('bookNumber', '');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="formative-assessment-book-filter-form">
      <Dialog open={show} onClose={handleDismiss} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Filter
          <IconButton onClick={handleDismiss}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: '8px' }}>
          <HootTypography isPII={false} variant="titlesmall" sx={{ padding: '0px 0px 8px 8px' }}>
            Select filters
          </HootTypography>
          <Stack direction="row" justifyContent="space-between" padding="8px" gap={2}>
            <Card sx={{ width: '60%' }}>
              <Stack gap={2}>
                <Controller
                  name="bookUnit"
                  key="bookUnit"
                  control={control}
                  rules={{
                    required: {
                      value: showUnitFilter,
                      message: 'Required field. Please try again.',
                    },
                  }}
                  render={() => <Checkbox label="Book Units" checked={showUnitFilter} onClick={toggleUnitFilter} />}
                />
                <Checkbox label="Book Number" checked={showNumberFilter} onClick={toggleNumberFilter} />
              </Stack>
            </Card>
            <Stack gap={2} width={'100%'}>
              {showUnitFilter ? (
                <Controller
                  name="bookUnit"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Select label="Book Unit" fullWidth value={value} onChange={onChange} error={!!error} helperText={error?.message}>
                      {value === '' ? <option value={undefined} /> : null}
                      {bookUnitsOptions?.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                />
              ) : null}
              {showNumberFilter ? (
                <Controller
                  name="bookNumber"
                  key="bookNumber"
                  control={control}
                  rules={{
                    required: {
                      value: showNumberFilter,
                      message: 'Required field. Please try again.',
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Select label="Book Number" fullWidth value={value} onChange={onChange} error={!!error} helperText={error?.message}>
                      {value === '' ? <option value={undefined} /> : null}
                      {numberOptions?.map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                />
              ) : null}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismiss} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onClear} variant="outlined">
            Clear
          </Button>
          <Button type="submit" variant="contained" form="formative-assessment-book-filter-form">
            Apply ({filterCount})
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default FormativeAssessmentBooksFilterDialog;
