import { useLocation, useNavigate } from 'react-router-dom';
import useStudentLibraryGetBook from '@hoot/hooks/api/student-library/useStudentLibraryGetBook';
import useToggleStudentFavouriteBook from '@hoot/hooks/api/student-library/useToggleStudentFavouriteBook';
import useProfile from '@hoot/hooks/useProfile';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { ReaderContext, setBook, setIsDoublePage, setReaderBookFromResponse, useReaders } from '@hoot/redux/reducers/readingSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { StudentLibraryPageNavigationStateKey } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';
import { preloadImageQueue } from '../../../../../../utils/preloadImage';

const readerContext = ReaderContext.StudentSpecificLibraryReader;

interface UseStudentSoloReaderControllerProps {
  bookId: string;
}

const useStudentSoloReaderController = (props: UseStudentSoloReaderControllerProps) => {
  const { bookId } = props;

  // Note: the source of truth for page index is in redux unlike the single/double page view which uses the query params.
  // This is because there is some extra safeguard logic that is executed when we apply a new page index in redux (we need to
  // ensure that the page spec'd in the query params is actually a number and is within the bounds of the book pages).
  const { studentSpecificLibraryReader } = useReaders();
  const { book } = studentSpecificLibraryReader;

  const profileId = useProfile().profile!.id;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { toggleFullscreen: _toggleFullscreen } = useFullscreen();

  const getBookRequest = useStudentLibraryGetBook(bookId, {
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      preloadImageQueue(response.pages.map((p) => p.url));
      // Set the book that we're reading in redux. The Reader component depends on this.
      dispatch(
        setBook({
          readerContext,
          book: {
            ...response,
          },
        }),
      );
      dispatch(
        setReaderBookFromResponse({
          readerContext,
          book: { ...response },
          // Go to bookmarked page if there is one.
          defaultPageId: response.studentBookmarkPageId ?? undefined,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        createFlashMessage({
          variant: 'error',
          message: err?.response?.data?.message ?? 'An error occurred loading book.',
        }),
      );
    },
  });

  const toggleStudentFavouriteBookRequest = useToggleStudentFavouriteBook({
    onSuccess: (response) => {
      dispatch(
        setBook({
          readerContext,
          book: {
            ...response,
          },
        }),
      );
      dispatch(
        createFlashMessage({
          message: `${response.title} ${response.shelf[profileId] ? 'has been added to your favorites' : 'has been removed from your favorites'}.`,
        }),
      );
    },
    onError: (err) => {
      console.log('err', err.response);
      dispatch(
        createFlashMessage({
          variant: 'error',
          message: err?.response?.data?.message ?? 'An error occurred toggling favourite book.',
        }),
      );
    },
  });

  const loadBook = () => {
    getBookRequest.refetch();
  };

  const toggleFullscreen = async () => {
    await _toggleFullscreen();
  };

  const closeBook = () => {
    const prevLibraryPageUrlSearchQuery = location.state?.[StudentLibraryPageNavigationStateKey.LibraryPageUrlSearchQuery];
    navigate({ pathname: routesDictionary.library.url, search: prevLibraryPageUrlSearchQuery });
  };

  const onChangeDoublePageMode = (isDoublePage: boolean) => {
    dispatch(setIsDoublePage({ readerContext, isDoublePage }));
  };

  const toggleFavouriteBook = (bookId: string) => {
    toggleStudentFavouriteBookRequest.mutate({ bookId });
  };

  const onGoToFirstPage = () => {
    dispatch(
      setReaderBookFromResponse({
        readerContext,
        book: book!,
        defaultPageId: undefined,
      }),
    );
  };

  const onGoToPageWithId = (pageId: string) => {
    dispatch(
      setReaderBookFromResponse({
        readerContext: ReaderContext.StudentSpecificLibraryReader,
        book: book!,
        defaultPageId: pageId,
      }),
    );
  };

  return {
    loadBook,
    closeBook,
    onChangeDoublePageMode,
    toggleFavouriteBook,
    toggleFullscreen,
    onGoToFirstPage,
    onGoToPageWithId,
  };
};

export default useStudentSoloReaderController;
