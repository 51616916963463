import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';

interface LessonReviewHistoryResponse {
  date: string;
  lessonType: ScheduledLessonType;
  focuses: string;
  submittedById: string | null;
  lessonReviewId?: string;
  lessonNoteId?: string;
}

export interface LessonReviewHistoryQuery {
  page: number;
  pageSize: number;
  orderBy: OrderBy;
  orderColumn: LessonReviewHistoryOrderColumn;
}

export enum LessonReviewHistoryOrderColumn {
  Date = 'date',
  LessonType = 'lesson_type',
}

async function getReviewHistory(
  studentProfileId: string,
  query: LessonReviewHistoryQuery,
): Promise<GenericPaginatedResponse<LessonReviewHistoryResponse>> {
  const { data } = await axios.get<GenericPaginatedResponse<LessonReviewHistoryResponse>>(
    `${config.apiUrl}/public/lesson-review/review-history/${studentProfileId}`,
    { params: query },
  );
  return data;
}

export default function useGetReviewHistory(
  studentProfileId: string,
  query: LessonReviewHistoryQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<LessonReviewHistoryResponse>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetReviewHistory, query], () => getReviewHistory(studentProfileId, query), options);
}
