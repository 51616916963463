import { Menu, MenuItem } from '@mui/material';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { eventVisibilityLabels } from './common';
import { EventVisibilityOption } from './enums';

export type EventOptionsType = {
  [key in EventVisibilityOption]: boolean;
};

interface EventVisibilityMenuProps {
  anchorElement: HTMLButtonElement | null;
  onDismiss: () => void;
  eventOptions: EventOptionsType;
  setEventOptions: React.Dispatch<React.SetStateAction<EventOptionsType>>;
}

const FocusVisibilityMenu = (props: EventVisibilityMenuProps) => {
  const handleToggle = (option: EventVisibilityOption) => {
    props.setEventOptions((prevState) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  };

  return (
    <Menu id="event-visibility-menu" open={!!props.anchorElement} anchorEl={props.anchorElement} onClose={props.onDismiss}>
      {Object.values(EventVisibilityOption).map((option) => (
        <MenuItem key={option}>
          <Checkbox label={eventVisibilityLabels[option]} checked={props.eventOptions[option]} onChange={() => handleToggle(option)} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FocusVisibilityMenu;
