import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfCreateStudentProfileRequest {
  studentName: string;
  pronouns?: string;
  grade: StudentGrade;
  additionalInformation?: string;
  isCoppaConsentGranted: boolean;
}

export interface FlfCreateStudentProfileResponse {
  studentProfileId: string;
}

export default function useFlfCreateStudentProfileRequest() {
  return useMutation<FlfCreateStudentProfileResponse, AxiosError, FlfCreateStudentProfileRequest>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/student-profile`, request)).data;
  });
}
