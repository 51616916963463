import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';

export interface WhiteboardState {
  whiteboardId: string;
  lessonId: string;
  templateType: WhiteboardTemplateTypeEnum;
  templateThumbnail: string;
  currentTool: WhiteboardTool;
  currentColour: WhiteboardToolColour;
}

export enum WhiteboardTool {
  Select = 'select',
  Draw = 'draw',
  Eraser = 'eraser',
  Text = 'text',
}

export enum WhiteboardToolColour {
  Black = 'black',
  Green = 'green',
  Blue = 'blue',
  Orange = 'orange',
  Red = 'red',
}
