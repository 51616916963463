import { EventType } from '@hoot/events/eventType';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { useAppDispatch } from '@hoot/redux/store';
import { PMASyncMessage } from '../../events/messages/pma-x.message';
import { handleSyncPMA } from '../../redux/reducers/pmaSlice';

const usePMAMessageHandler = () => {
  const dispatch = useAppDispatch();

  // Refresh lesson response handler
  useSocketSubscription<PMASyncMessage>(EventType.PMASyncResponse, {
    onEventReceived: (response) => {
      dispatch(handleSyncPMA(response));
    },
  });
};

export default usePMAMessageHandler;
