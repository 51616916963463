import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { zIndexes } from '@hoot/constants/zIndices';
import StudentLessonReaderPage from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentLessonReaderPage';
import PMATextUnit from '@hoot/ui/pages/v2/student/progress-monitoring-assessment/PMATextUnit';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { RootState } from '../../../../../redux/store';

//Todo Implement Magic Finger - https://app.shortcut.com/hoot-reading/story/11795/pma-in-lesson-magic-finger

const PMAContainer = (props: PropsWithChildren<any>) => {
  const { children } = props;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'relative',
        userSelect: 'none',
        zIndex: zIndexes.bookPageOnReader,
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </Stack>
  );
};

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  isThumbnailView?: boolean;
}

const PMAImage = (imgProps: ImageProps) => {
  const { isThumbnailView, ...rest } = imgProps;
  return (
    <Box
      draggable={false}
      component="img"
      sx={{
        objectFit: 'contain',
        WebkitTouchCallout: 'none', // disabling the save/copy option menu for images when holding your finger on it
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '10px',
        maxWidth: '100%',
        maxHeight: '100%',
        // A note about how these images render:
        // The book page (AKA the image of the book page) needs to _only_ fill as much space as it needs for its
        // width, but must fill the height of its parent container. This will ensure that, regardless of window
        // dimensions, users are _always_ seeing the same scaled images with the correct aspect ratio. This is ultra
        // important b/c we need to ensure that the magic finger coordinates always point "at the same spot" for
        // both users in a lesson (the magic finger container wraps the visible pages (one or two pages depending on
        // if we're in single-page mode or double-page mode).
        width: isThumbnailView ? '100%' : 'auto',
        height: isThumbnailView ? '100%' : 'auto',
        backgroundColor: '#FFF',
      }}
      {...rest}
    />
  );
};

interface PMAUnitProps {
  isThumbnailView?: boolean;
}

const PMAUnit = (props: PMAUnitProps) => {
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);
  const text = useSelector((state: RootState) => state.pma.text);

  const { isThumbnailView } = props;

  if (isUnitComplete) {
    return (
      <PMAContainer>
        <PMAImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} isThumbnailView={isThumbnailView} />
      </PMAContainer>
    );
  }

  switch (currentUnit) {
    case PMAUnitIdentifier.Module_1_Unit_1:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/segmentation.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_2:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/blending.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_3:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/deletion.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_4:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/substitution.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_5:
      return (
        <PMAContainer>
          <PMATextUnit text={text ?? ''} isThumbnailView={!!isThumbnailView} isLetterUnit={true} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_2_Unit_1:
    case PMAUnitIdentifier.Module_2_Unit_2:
    case PMAUnitIdentifier.Module_2_Unit_3:
    case PMAUnitIdentifier.Module_2_Unit_4:
    case PMAUnitIdentifier.Module_2_Unit_5:
    case PMAUnitIdentifier.Module_2_Unit_6:
    case PMAUnitIdentifier.Module_3_Unit_1:
    case PMAUnitIdentifier.Module_3_Unit_2:
    case PMAUnitIdentifier.Module_3_Unit_3:
    case PMAUnitIdentifier.Module_3_Unit_4:
    case PMAUnitIdentifier.Module_3_Unit_5:
    case PMAUnitIdentifier.Module_3_Unit_6:
      return (
        <PMAContainer>
          <PMATextUnit text={text ?? ''} isThumbnailView={!!isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitIdentifier.Module_4_Unit_2:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );

    case PMAUnitIdentifier.Module_4_Unit_1:
    case PMAUnitIdentifier.Module_4_Unit_3:
    case PMAUnitIdentifier.Module_4_Unit_4:
      return isThumbnailView ? null : <StudentLessonReaderPage />;
    default: // end
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
  }
};

interface Props {
  isThumbnailView?: boolean;
}

const StudentPMAPage = (props: Props) => {
  const { isThumbnailView } = props;

  return (
    <Stack sx={{ height: '100%', padding: 2 }}>
      <PMAUnit isThumbnailView={isThumbnailView} />
    </Stack>
  );
};

export default StudentPMAPage;
