import { Stack } from '@mui/system';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import {
  assessmentOutcomesIconDictionary,
  lessonReviewFocusesIconDictionary,
  timelineEventIconDictionary,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/common';
import { TimelineEvent, TimelineLessonReviewFocus, TimelineOutcome } from './enums';

const timelineLegendLabel: Record<string, string> = {
  [TimelineEvent.HRA]: 'Hoot Reading Assessment',
  [TimelineEvent.PMA]: 'Progress Monitoring Assessment',
  [TimelineEvent.CompletedLesson]: 'Completed Lesson',
  [TimelineEvent.ScheduledLesson]: 'Scheduled Lesson',
  [TimelineEvent.StudentAbsence]: 'Student Absence',
  [TimelineEvent.Cancellation]: 'Cancellation',
  [TimelineOutcome.Mastered]: 'Mastered',
  [TimelineOutcome.Passed]: 'Passed',
  [TimelineOutcome.ReviewRequired]: 'Review Required',
  [TimelineOutcome.InstructionRequired]: 'Instruction Required',
  [TimelineOutcome.Blank]: '',
  [TimelineLessonReviewFocus.PreviouslyPassed]: 'Previously Passed/Mastered',
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: 'Lesson Primary Focus',
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: 'Lesson Secondary Focus',
  [TimelineLessonReviewFocus.FormativeAssessment]: 'Formative Assessment',
};

interface TimelineLegendDialogProps {
  open: boolean;
  onDismiss: () => void;
}

const timelineEvents = Object.entries(timelineEventIconDictionary);
const assessmentOutcomes = Object.entries(assessmentOutcomesIconDictionary).filter((o) => o[0] !== TimelineOutcome.Blank);
const lessonReviewFocuses = Object.entries(lessonReviewFocusesIconDictionary);

const TimelineLegendDialog = (props: TimelineLegendDialogProps) => {
  return (
    <>
      {props.open && (
        <FloatingDialog title="Legend" titleVariant="titlelarge" width="340px" onClose={props.onDismiss} dragFromAnywhere>
          <Stack gap={2} mt={2}>
            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Events
              </HootTypography>
              <Stack gap={2}>
                {timelineEvents.map(([label, icon]) => (
                  <Stack key={label} flexDirection="row" gap={2}>
                    {icon}
                    <HootTypography isPII={false}>{timelineLegendLabel[label]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Assessment Outcomes
              </HootTypography>
              <Stack gap={2}>
                {assessmentOutcomes.map(([label, icon]) => (
                  <Stack key={label} flexDirection="row" gap={2}>
                    {icon}
                    <HootTypography isPII={false}>{timelineLegendLabel[label]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Lesson Review Focuses
              </HootTypography>
              <Stack gap={2}>
                {lessonReviewFocuses.map(([label, icon]) => (
                  <Stack key={label} flexDirection="row" gap={2}>
                    {icon}
                    <HootTypography isPII={false}>{timelineLegendLabel[label]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>
          </Stack>
        </FloatingDialog>
      )}
    </>
  );
};

export default TimelineLegendDialog;
