import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { WhiteboardState as WhiteboardRemoteState, WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { RootState } from '../store';

export interface WhiteboardState {
  whiteboard: Whiteboard | null;
}

export interface Whiteboard {
  whiteboardId: string;
  templateType: WhiteboardTemplateTypeEnum;
  templateThumbnail: string;
  currentTool: WhiteboardTool;
  currentColour: WhiteboardToolColour;
}

const initialState: WhiteboardState = {
  whiteboard: null,
};

export const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    handleSyncWhiteboardState: (_, action: PayloadAction<WhiteboardRemoteState | null>) => {
      const whiteboard = action.payload;
      return {
        whiteboard: !whiteboard
          ? null
          : {
              whiteboardId: whiteboard.whiteboardId,
              templateThumbnail: whiteboard.templateThumbnail,
              templateType: whiteboard.templateType,
              currentTool: whiteboard.currentTool,
              currentColour: whiteboard.currentColour,
            },
      };
    },
  },
});

export const { handleSyncWhiteboardState } = whiteboardSlice.actions;

export const useWhiteboardState = () => {
  return useSelector((state: RootState) => state.whiteboard);
};

export default whiteboardSlice.reducer;
