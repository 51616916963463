import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface PMAUnitSubmission {
  studentProfileId: string;
  teacherAccountId: string;
  submissionData: any;
  lessonId: string;
}

async function submitPMAUnit(assessmentId: string, unitId: string, submit: PMAUnitSubmission): Promise<void> {
  const { data } = await axios.post<any>(`${config.apiUrl}/public/progress-monitoring-assessment/${assessmentId}/submit-unit/${unitId}`, submit);
  return data;
}

export function useSubmitPMAUnit(
  assessmentId: string,
  unitId: string,
  options?: Omit<UseMutationOptions<any, AxiosError, PMAUnitSubmission>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((submit) => submitPMAUnit(assessmentId, unitId, submit), options);
}
