import { SvgIcon, SvgIconProps } from '@mui/material';

const ProgressMonitoringAssessmentIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="black" />
    <path
      d="M15.164 10.288C15.164 10.776 15.052 11.224 14.828 11.632C14.604 12.032 14.26 12.356 13.796 12.604C13.332 12.852 12.756 12.976 12.068 12.976H10.796V16H8.744V7.576H12.068C12.74 7.576 13.308 7.692 13.772 7.924C14.236 8.156 14.584 8.476 14.816 8.884C15.048 9.292 15.164 9.76 15.164 10.288ZM11.912 11.344C12.304 11.344 12.596 11.252 12.788 11.068C12.98 10.884 13.076 10.624 13.076 10.288C13.076 9.952 12.98 9.692 12.788 9.508C12.596 9.324 12.304 9.232 11.912 9.232H10.796V11.344H11.912Z"
      fill="white"
    />
  </SvgIcon>
);

export default ProgressMonitoringAssessmentIcon;
