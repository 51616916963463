export enum SessionStorageKey {
  // Used to hold the state of SSO logins.
  EDLINK_SSO_STATE = 'edlink.ssoState',
  // Holds the users current browser session ID.
  HOOT_SESSION_ID = 'hootSessionId',
  // If a user logs in via District/School Login, we use this key to redirect back to that same page.
  DISTRICT_LOGIN_FRIENDLY_ID = 'districtLoginFriendlyId',
  // Redirect users to original path requested after a login.
  LOGIN_REDIRECT_URL = 'loginRedirectUrl',
  // Remembers the last coordinates of FloatingDialog.tsx.
  FLOATING_DIALOG_POSITION = 'floatingDialogPosition',
}
