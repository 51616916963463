import { Popover, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Button } from './core/Button';
import HootTypography from './core/HootTypography';

interface InfoBubbleProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  color: string | undefined;
  title: string;
  content: ReactNode;
  button?: {
    text: string;
    action: () => void;
  };
}

const InfoBubble = (props: InfoBubbleProps) => {
  const { anchorEl, onClose, color, title, content, button } = props;
  return (
    <Popover
      id="info-bubble"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: color,
            overflow: 'visible',
            '&:before': {
              content: '""',
              position: 'absolute',
              bottom: -4,
              width: 10,
              height: 10,
              backgroundColor: 'inherit',
              transform: 'rotate(45deg)',
              borderRadius: '2px',
            },
          },
        },
      }}
    >
      <Stack sx={{ padding: 1, gap: 1 }}>
        <HootTypography isPII={false} variant="bodylarge">
          {title}
        </HootTypography>
        <HootTypography isPII={false} variant="labelsmall">
          {content}
        </HootTypography>
        {button ? (
          <Button variant="contained" size="small" onClick={button.action}>
            {button.text}
          </Button>
        ) : null}
      </Stack>
    </Popover>
  );
};

export default InfoBubble;
