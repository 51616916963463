import { PMAFormVariant, PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PMASyncMessage } from '../../events/messages/pma-x.message';

export interface PMAState {
  assessmentId: string;
  formVariant: PMAFormVariant;
  currentUnit: PMAUnitIdentifier;
  text?: string;
  isUnitComplete: boolean;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
}

const initialState: PMAState = {
  assessmentId: '',
  currentUnit: PMAUnitIdentifier.Module_1_Unit_1,
  formVariant: PMAFormVariant.A,
  isUnitComplete: false,
  isPrevEnabled: false,
  isNextEnabled: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    handleSyncPMA: (state, action: PayloadAction<PMASyncMessage>) => {
      state.assessmentId = action.payload.assessmentId;
      state.currentUnit = action.payload.currentUnit;
      state.formVariant = action.payload.formVariant;
      state.isNextEnabled = action.payload.isNextEnabled;
      state.isPrevEnabled = action.payload.isPrevEnabled;
      state.isUnitComplete = action.payload.isUnitComplete;
      state.text = action.payload.text;
    },
  },
});

export const { handleSyncPMA } = modalsSlice.actions;

export default modalsSlice.reducer;
