import { Unit_4_1_SubmissionData } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { Box, Stack } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { useReaders } from '../../../../../../../redux/reducers/readingSlice';
import ReadOnlyTextField from '../../../../../../components/v2/core/ReadOnlyTextField';
import TextField from '../../../../../../components/v2/core/TextField';
import { PMAContainer } from '../PMAContainer';
import { PMAFooter } from '../PMAPageFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import useTeacherPMAController from '../useTeacherPMAController';

interface Form {
  time: string;
  wordCount: number;
  errors: number;
}

export function PMA_QUESTIONNAIRE_LAYOUT_4() {
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const bookId = useSelector((state: RootState) => state.readers.inLessonReader.book?.id);

  const pmaController = useTeacherPMAController();
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);
  const dispatch = useAppDispatch();

  const { control, watch, reset, formState, setValue, getValues, trigger } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      errors: undefined,
      wordCount: undefined,
    },
  });

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (data.submissionData) {
        const subData = data.submissionData as Unit_4_1_SubmissionData;
        setValue('time', subData.time);
        setValue('errors', subData.errors);
        setValue('wordCount', subData.wcpm);
        setValue('wordCount', subData.wordCount);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const {
    inLessonReader: { book },
  } = useReaders();
  const bookWordCount = book?.wordCount ?? 0;

  useEffect(() => {
    reset({
      wordCount: bookWordCount,
    });
  }, [bookWordCount, reset]);

  const wordCount = watch('wordCount') ?? 0;
  const errors = watch('errors') ?? 0;
  const time = watch('time');

  function extractMinutes() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[0]);
    }
    return 0;
  }

  function extractSeconds() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[1]);
    }
    return 0;
  }

  const timeInMinutes = extractMinutes();
  const timeInSeconds = extractSeconds();

  // Calculation: (Word Count - Errors) * 100 / Word Count
  const accuracyRate = Math.round(wordCount > 0 ? ((wordCount - errors) * 100) / wordCount : 0);

  // Calculation: (Word Count - Errors) * 60 / (Time in minutes * 60 + Time in seconds)
  const wordsCorrectPerMinute = Math.round(
    timeInMinutes > 0 || timeInSeconds > 0 ? ((wordCount - errors) * 60) / (timeInMinutes * 60 + timeInSeconds) : 0,
  );

  const onPreviousUnitClick = () => {
    if (formState.isDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const subData: Unit_4_1_SubmissionData = {
      time: time,
      accuracyRate: accuracyRate,
      bookId: bookId!,
      errors: errors,
      wcpm: wordsCorrectPerMinute,
      wordCount: wordCount,
    };

    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: subData,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const allFormFieldsFilledOut = !!time && !!wordCount && !!errors && _.isEmpty(formState.errors);
  const disableSubmissionBtn = !allFormFieldsFilledOut || submitPMAUnitRequest.isLoading;

  return (
    <PMAContainer showBook>
      <Stack gap={4} paddingY={3}>
        <Stack flex={1} spacing="16px">
          <Stack direction="row" spacing="16px">
            <Controller
              name="time"
              control={control}
              rules={{
                required: 'Time is required.',
                pattern: {
                  value: /^[0-5]?[0-9]:[0-5][0-9]$/,
                  message: 'Time needs to be in the format 59:59',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  placeholder="Minutes"
                  onChange={field.onChange}
                  value={field.value}
                  label="Time"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="wordCount"
              control={control}
              rules={{
                required: 'Word count is required',
                validate: (value) => !value || value >= getValues('errors') || 'Cannot have a smaller word count than the number of errors.',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Word Count"
                  onChange={(val) => {
                    field.onChange(val);
                    trigger('errors');
                  }}
                  type="number"
                  value={field.value}
                  fullWidth
                  label="Word Count"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="errors"
              control={control}
              rules={{
                required: 'Errors is required',
                validate: (value) => !value || value <= getValues('wordCount') || 'Cannot have more errors than word count.',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Errors"
                  onChange={(val) => {
                    field.onChange(val);
                    trigger('wordCount');
                  }}
                  value={field.value}
                  fullWidth
                  type="number"
                  label="Errors"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Stack>
          <Stack spacing="16px" direction="row">
            <Box flex={1}>
              <ReadOnlyTextField label="WCPM" body={wordsCorrectPerMinute === 0 ? '-' : wordsCorrectPerMinute} />
            </Box>
            <Box flex={1}>
              <ReadOnlyTextField label="Accuracy" body={accuracyRate === 0 ? '-' : accuracyRate} />
            </Box>
          </Stack>
        </Stack>
        <PMAFooter
          allFormFieldsFilledOut={formState.isValid}
          onPreviousUnitClick={onPreviousUnitClick}
          setLeaveDialog={setLeaveDialog}
          isUnitQuestionnaireDirty={formState.isDirty}
          disableSubmissionBtn={disableSubmissionBtn}
          onNextUnitAndSubmissionClick={onNextUnitAndSubmissionClick}
        />
        <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
      </Stack>
    </PMAContainer>
  );
}
