import { Stack } from '@mui/system';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';

interface IUnitCompleteProps {
  submitForm?: () => void;
  isSubmitting?: boolean;
  hasBeenSubmitted: boolean;
}

const UnitCompleteCard = (props: IUnitCompleteProps) => {
  const { hasBeenSubmitted } = props;

  return (
    <Stack alignItems="center">
      <ViewStateIllustration
        title={hasBeenSubmitted ? 'This Unit Has Been Submitted!' : 'Unit Ready For Submission!'}
        subtitle={hasBeenSubmitted ? 'You can still make changes and Resubmit' : "Review the responses and Submit and Continue when you're ready."}
        illustration={IllustrationEnum.SetupComplete}
        subtitleSx={{ textAlign: 'center' }}
      />
    </Stack>
  );
};

export default UnitCompleteCard;
