import { Box, CircularProgress, DialogActions, DialogContent, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { getLessonReviewLessonTypeLabel } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewDetailsCard';
import { useLessonReviewWizardContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const LessonReviewIntroductionStep = () => {
  const {
    lessonToReview,
    isLoadingLesson,
    actions: { goToNextStep },
  } = useLessonReviewWizardContext();

  const lessonStartDateLabel = useMemo(() => {
    if (!lessonToReview?.lessonStartTime) {
      return undefined;
    }
    return DateTime.fromMillis(lessonToReview?.lessonStartTime).toFormat('LLLL d, yyyy');
  }, [lessonToReview?.lessonStartTime]);

  return (
    <>
      <DialogContent
        sx={(theme) => ({
          gap: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <ViewStateIllustration
          padding={0}
          illustration={IllustrationEnum.NoDocuments}
          title="Let's Create A Lesson Review!"
          subtitle="Review these details to get started."
          titleSx={{ textAlign: 'center' }}
          subtitleSx={{ textAlign: 'center' }}
        />
        <Box width="100%">
          <Grid container spacing={2}>
            <Grid size={12}>
              <HootTypography isPII={false} variant="titlesmall">
                Lesson Details
              </HootTypography>
            </Grid>
            <Grid size={12}>
              {lessonToReview ? (
                <ReadOnlyTextField label="Student Name" body={lessonToReview.student.name} />
              ) : (
                <Skeleton variant="rectangular" width="100%" height="58px" />
              )}
            </Grid>
            <Grid size={{ sm: 6, xs: 12 }}>
              {lessonToReview ? (
                <ReadOnlyTextField label="Lesson Date" body={lessonStartDateLabel ?? ''} />
              ) : (
                <Skeleton variant="rectangular" width="100%" height="58px" />
              )}
            </Grid>
            <Grid size={{ sm: 6, xs: 12 }}>
              {lessonToReview ? (
                <ReadOnlyTextField label="Lesson Type" body={getLessonReviewLessonTypeLabel(lessonToReview.lessonType)} />
              ) : (
                <Skeleton variant="rectangular" width="100%" height="58px" />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => goToNextStep()} disabled={!lessonToReview || isLoadingLesson} sx={{ width: '160px' }}>
          {isLoadingLesson ? <CircularProgress size={24} color="inherit" /> : 'Begin Review'}
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewIntroductionStep;
