import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderCell, TimelineCell } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import InfoBubble from '@hoot/ui/components/v2/InfoBubble';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { toProperCase } from '@hoot/utils/text-display';
import { timelineColorDictionary } from './common';
import { TimelineEvent, TimelineLessonReviewFocus, TimelineOutcome } from './enums';

interface InfoBubbleProps {
  selectedOutcomeCell: TimelineCell | null;
  selectedEventCell: HeaderCell | null;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

const InfoBubbles = (props: InfoBubbleProps) => {
  const { studentProfileId } = useParams();
  const navigate = useNavigate();

  const { selectedEventCell, selectedOutcomeCell, onClose, anchorEl } = props;

  switch (selectedEventCell?.event) {
    case TimelineEvent.HRA:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          button={{
            text: 'View HRA Details',
            action: () =>
              navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId!), {
                state: { hraId: selectedEventCell.hraId },
              }),
          }}
          color={timelineColorDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.PMA:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          button={{
            text: 'View PMA Details',
            action: () =>
              navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId!), {
                state: { pmaId: selectedEventCell.pmaId },
              }),
          }}
          color={timelineColorDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.ScheduledLesson:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          color={timelineColorDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.CompletedLesson:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedEventCell.event)}
          content={
            <Stack sx={{ gap: 1 }}>
              <HootTypography isPII={false} variant="labelsmall">
                {selectedEventCell.date}
              </HootTypography>
              <HootTypography isPII={false} variant="labelsmall">
                w/ {selectedEventCell.teacherName}
              </HootTypography>
            </Stack>
          }
          button={{
            text: 'View Lesson Review',
            action: () =>
              selectedEventCell.lessonReviewId
                ? navigate(routesDictionary.myStudents.details.reviews.lessonReviewId.url(studentProfileId!, selectedEventCell.lessonReviewId!))
                : undefined,
          }}
          color={timelineColorDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.Cancellation:
    case TimelineEvent.StudentAbsence:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          color={timelineColorDictionary[selectedEventCell.event]}
        />
      );
  }

  switch (selectedOutcomeCell?.outcome) {
    case TimelineOutcome.Blank:
      return null;
    case TimelineOutcome.InstructionRequired:
    case TimelineOutcome.Mastered:
    case TimelineOutcome.Passed:
    case TimelineOutcome.ReviewRequired:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.name}
                </HootTypography>
              ) : null}
              {selectedOutcomeCell.unit.results ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.results}
                </HootTypography>
              ) : null}
            </Stack>
          }
          button={
            selectedOutcomeCell.pmaId || selectedOutcomeCell.hraId
              ? {
                  text: selectedOutcomeCell.hraId ? 'View HRA Details' : 'View PMA Details',
                  action: () =>
                    navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId!), {
                      state: { hraId: selectedOutcomeCell.hraId, pmaId: selectedOutcomeCell.pmaId, unitId: selectedOutcomeCell.unit.id },
                    }),
                }
              : undefined
          }
          color={timelineColorDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.FormativeAssessment:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedOutcomeCell.outcome)}
          content={selectedOutcomeCell.date!}
          button={{
            text: 'View Lesson Review',
            action: () =>
              selectedOutcomeCell.lessonReviewId
                ? navigate(routesDictionary.myStudents.details.reviews.lessonReviewId.url(studentProfileId!, selectedOutcomeCell.lessonReviewId!))
                : undefined,
          }}
          color={timelineColorDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.LessonPrimaryFocus:
    case TimelineLessonReviewFocus.LessonSecondaryFocus:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.name}
                </HootTypography>
              ) : null}
              {selectedOutcomeCell.unit.results ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.results}
                </HootTypography>
              ) : null}
            </Stack>
          }
          button={{
            text: 'View Lesson Review',
            action: () =>
              selectedOutcomeCell.lessonReviewId
                ? navigate(routesDictionary.myStudents.details.reviews.lessonReviewId.url(studentProfileId!, selectedOutcomeCell.lessonReviewId!))
                : undefined,
          }}
          color={timelineColorDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.PreviouslyPassed:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={toProperCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.name}
                </HootTypography>
              ) : null}
              {selectedOutcomeCell.unit.results! ? (
                <HootTypography isPII={false} variant="labelsmall">
                  {selectedOutcomeCell.unit.results}
                </HootTypography>
              ) : null}
            </Stack>
          }
          color={timelineColorDictionary[selectedOutcomeCell.outcome]}
        />
      );
  }
};

export default InfoBubbles;
