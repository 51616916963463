import { SvgIcon, SvgIconProps } from '@mui/material';

const CancellationIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#F26D4F" />
    <path
      d="M7.396 11.776C7.396 10.944 7.576 10.204 7.936 9.556C8.296 8.9 8.796 8.392 9.436 8.032C10.084 7.664 10.816 7.48 11.632 7.48C12.632 7.48 13.488 7.744 14.2 8.272C14.912 8.8 15.388 9.52 15.628 10.432H13.372C13.204 10.08 12.964 9.812 12.652 9.628C12.348 9.444 12 9.352 11.608 9.352C10.976 9.352 10.464 9.572 10.072 10.012C9.68 10.452 9.484 11.04 9.484 11.776C9.484 12.512 9.68 13.1 10.072 13.54C10.464 13.98 10.976 14.2 11.608 14.2C12 14.2 12.348 14.108 12.652 13.924C12.964 13.74 13.204 13.472 13.372 13.12H15.628C15.388 14.032 14.912 14.752 14.2 15.28C13.488 15.8 12.632 16.06 11.632 16.06C10.816 16.06 10.084 15.88 9.436 15.52C8.796 15.152 8.296 14.644 7.936 13.996C7.576 13.348 7.396 12.608 7.396 11.776Z"
      fill="black"
    />
  </SvgIcon>
);

export default CancellationIcon;
