import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { HeaderCell, StudentTimelineResponse, TimelineCell } from '@hoot/hooks/api/my-student/getStudentTimeline';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag from '@hoot/ui/components/v2/core/Tag';
import {
  RapportAndRelationship,
  timelineEventIconDictionary,
  timelineIconsDictionary,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/common';
import InfoBubbles from './InfoBubbles';
import UnitDialog from './UnitDialog';
import { TimelineOutcome } from './enums';

interface TimelineGridProps {
  data: StudentTimelineResponse | undefined;
  studentProfileId: string;
}

const TimelineGrid = (props: TimelineGridProps) => {
  const { data, studentProfileId } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedOutcomeCell, setSelectedOutcomeCell] = useState<TimelineCell | null>(null);
  const [selectedEventCell, setSelectedEventCell] = useState<HeaderCell | null>(null);
  const [unitClicked, setUnitClicked] = useState<string | null>(null);

  const handleHeaderClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: HeaderCell) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventCell(cell);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: TimelineCell) => {
    if (cell.outcome !== TimelineOutcome.Blank) {
      setAnchorEl(event.currentTarget);
      setSelectedOutcomeCell(cell);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedOutcomeCell(null);
    setSelectedEventCell(null);
  };

  const onUnitClick = (unitName: string) => setUnitClicked(unitName);
  const onUnitDialogDismiss = () => setUnitClicked(null);

  const ignoreUnit = unitClicked === RapportAndRelationship;

  return (
    <>
      {data?.modules.map((module, moduleIndex) => (
        <Stack key={moduleIndex} direction="row">
          {/* Vertical module name section */}
          <Tag
            label={module.name}
            sx={{
              alignSelf: moduleIndex === 0 ? 'self-end' : 'center',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
            }}
          />

          {/* Unit name section */}
          <Stack sx={{ paddingLeft: 2, minWidth: '220px' }} alignSelf={moduleIndex === 0 ? 'self-end' : 'center'}>
            {module.units.map((unit, unitIndex) => {
              const ignoreUnit = unit.name === RapportAndRelationship;

              return (
                <Stack key={unitIndex} direction="row" onClick={() => onUnitClick(unit.name)} role="button" sx={{ height: '24px' }}>
                  <HootTypography
                    variant="tableheading"
                    isPII={false}
                    sx={{
                      cursor: ignoreUnit ? 'text' : 'pointer',
                      '&:hover': { textDecoration: ignoreUnit ? 'none' : 'underline' },
                    }}
                  >
                    {unit.name}
                  </HootTypography>
                </Stack>
              );
            })}
          </Stack>

          {/* Table */}
          <Stack sx={{ borderTop: '1px solid #000', borderRight: '1px solid #000' }} alignSelf={'center'}>
            {/* Table header */}
            {moduleIndex === 0 ? (
              <Stack direction="row">
                {data?.headerRow.map((cell, colIndex) => (
                  <Stack
                    key={colIndex}
                    sx={{
                      borderLeft: '1px solid #000',
                      borderBottom: '1px solid #000',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={(event) => handleHeaderClick(event, cell)}
                  >
                    {timelineEventIconDictionary[cell.event]}
                  </Stack>
                ))}
              </Stack>
            ) : null}

            {/* Table rows */}
            {module.units.map((unit, unitIndex) => (
              <Stack direction="row" key={unitIndex}>
                {unit.cells.map((cell, colIndex) => (
                  <Stack
                    key={colIndex}
                    sx={{
                      borderLeft: '1px solid #000',
                      borderBottom: '1px solid #000',
                      '&:hover': {
                        cursor: cell.outcome === TimelineOutcome.Blank ? undefined : 'pointer',
                      },
                    }}
                    onClick={(event) => handleRowClick(event, cell)}
                  >
                    {timelineIconsDictionary[cell.outcome]}
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}

      {/* Dialogs */}
      <InfoBubbles selectedOutcomeCell={selectedOutcomeCell} selectedEventCell={selectedEventCell} anchorEl={anchorEl} onClose={handlePopoverClose} />
      {unitClicked && !ignoreUnit ? <UnitDialog studentProfileId={studentProfileId} unitName={unitClicked} onDismiss={onUnitDialogDismiss} /> : null}
    </>
  );
};

export default TimelineGrid;
