import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { PasswordStatus } from '@hoot/models/api/student';
import CheckList, { CheckListItem } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import ChipGroup from '@hoot/ui/components/v2/core/ChipGroup';
import DatePicker from '@hoot/ui/components/v2/core/DatePicker';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import EnrolmentFilter from '@hoot/ui/pages/v2/district-rep/students/EnrolmentFilter';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export interface DialogFormFields {
  status?: EnrolmentStatus[];
  startDate?: number;
  endDate?: number;
  enrolmentId?: string;
  grade?: StudentGrade[];
  passwordStatus?: PasswordStatus[];
}

export interface FieldsToShow {
  status: boolean;
  startDate: boolean;
  endDate: boolean;
  enrolmentId: boolean;
  grade: boolean;
  passwordStatus: boolean;
}

interface FilterDialogProps {
  show: boolean;
  showFields: FieldsToShow;
  values: DialogFormFields;
  onDismiss: () => void;
  onChange: (updatedFields: DialogFormFields) => void;
}

type DialogFormFieldsKeys = keyof DialogFormFields;

const StudentsFiltersDialog = (props: FilterDialogProps) => {
  const { show, showFields, values, onDismiss, onChange } = props;
  const filterOptions = Object.entries(showFields)
    .filter((e) => !!e[1])
    .map((e) => ({
      label: capitalCase(e[0]),
      value: e[0],
    }));
  const filteredValues = _.pick(values, ['status', 'startDate', 'endDate', 'enrolmentId', 'grade', 'passwordStatus']);

  const [selectedValues, setSelectedValues] = useState<string[]>(
    Object.entries(filteredValues)
      .filter((e) => (Array.isArray(e[1]) && e[1].length > 0) || (!Array.isArray(e[1]) && e[1]))
      .map((e) => e[0]),
  );

  const showStatusFilter = showFields.status && selectedValues.includes('status');
  const showStartDateFilter = showFields.startDate && selectedValues.includes('startDate');
  const showEndDateFilter = showFields.endDate && selectedValues.includes('endDate');
  const showPasswordStatusFilter = showFields.passwordStatus && selectedValues.includes('passwordStatus');
  const showEnrolmentIdFilter = showFields.enrolmentId && selectedValues.includes('enrolmentId');
  const showGradeFilter = showFields.grade && selectedValues.includes('grade');

  const showNoFiltersText =
    !showStatusFilter && !showStartDateFilter && !showEndDateFilter && !showPasswordStatusFilter && !showEnrolmentIdFilter && !showGradeFilter;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<DialogFormFields>({
    defaultValues: {
      status: filteredValues.status ?? undefined,
      startDate: filteredValues.startDate ?? undefined,
      endDate: filteredValues.endDate ?? undefined,
      enrolmentId: filteredValues.enrolmentId ?? undefined,
      passwordStatus: filteredValues.passwordStatus ?? undefined,
      grade: filteredValues.grade ?? undefined,
    },
  });

  const toggleOption = (option: CheckListItem) => {
    const optionDeselected = selectedValues.includes(option.value);

    if (optionDeselected) {
      setSelectedValues(selectedValues.filter((qo) => qo !== option.value));
      setValue(`${option.value as DialogFormFieldsKeys}`, undefined);
    } else {
      setSelectedValues((prevState) => [...prevState, option.value]);
    }
  };

  const onSubmit = async (data: DialogFormFields) => {
    onChange(data);
    onDismiss();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="district-rep-students-filter-form">
      <Dialog open={show} onClose={onDismiss} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Filter
          <IconButton onClick={onDismiss} sx={{ color: hootTokens.palette.black }}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Stack gap={1} width="40%">
              <CheckList title="Select Filters" divider items={filterOptions} selectedValues={selectedValues} toggleOption={toggleOption} />
            </Stack>
            <Stack gap={1} width="60%">
              <HootTypography isPII={false} variant="titlesmall">
                Filters that will be applied
              </HootTypography>
              {showNoFiltersText ? (
                <HootTypography isPII={false} variant="bodysmall">
                  No filters selected
                </HootTypography>
              ) : (
                <>
                  {showStatusFilter ? (
                    <Controller
                      name={'status'}
                      key={'status-student-filter'}
                      control={control}
                      rules={{
                        required: 'A selection is required',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ChipGroup
                          items={Object.entries(EnrolmentStatus).map((option) => {
                            const [key, value] = option;
                            return {
                              value: value,
                              label: capitalCase(key),
                            };
                          })}
                          onChange={onChange}
                          value={value ?? []}
                          isMultiSelect
                          label="Enrolment Status"
                          error={!!errors.status}
                          helperText={errors.status?.message ?? ''}
                          cannotRemoveSelection
                        />
                      )}
                    />
                  ) : null}
                  {showStartDateFilter ? (
                    <Controller
                      name={'startDate'}
                      key={'startDate-student-filter'}
                      control={control}
                      rules={{
                        required: 'Start Date is required',
                      }}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          label="Start Date"
                          value={value ? DateTime.fromMillis(value) : null}
                          onChange={(val) => onChange(val ? val.toMillis() : null)}
                          error={!!errors.startDate}
                          helperText={errors.startDate?.message ?? ''}
                        />
                      )}
                    />
                  ) : null}
                  {showEndDateFilter ? (
                    <Controller
                      name={'endDate'}
                      key={'endDate-student-filter'}
                      control={control}
                      rules={{
                        required: 'End Date is required',
                      }}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          label="End Date"
                          value={value ? DateTime.fromMillis(value) : null}
                          onChange={(val) => onChange(val ? val.toMillis() : undefined)}
                          error={!!errors.endDate}
                          helperText={errors.endDate?.message ?? ''}
                        />
                      )}
                    />
                  ) : null}
                  {showPasswordStatusFilter ? (
                    <Controller
                      name={'passwordStatus'}
                      key={'passwordStatus-student-filter'}
                      control={control}
                      rules={{
                        required: 'A selection is required',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ChipGroup
                          items={Object.entries(PasswordStatus).map((option) => {
                            const [key, value] = option;
                            return {
                              value: value,
                              label: capitalCase(key),
                            };
                          })}
                          onChange={onChange}
                          value={value ?? []}
                          isMultiSelect
                          label="Password Status"
                          error={!!errors.passwordStatus}
                          helperText={errors.passwordStatus?.message ?? ''}
                          cannotRemoveSelection
                        />
                      )}
                    />
                  ) : null}
                  {showEnrolmentIdFilter ? (
                    <Controller
                      name={'enrolmentId'}
                      key={'enrolmentId-student-filter'}
                      control={control}
                      rules={{
                        required: 'Enrolment is required',
                      }}
                      render={({ field: { value, onChange } }) => (
                        <EnrolmentFilter
                          label="Enrolment Id"
                          value={value}
                          onChange={onChange}
                          error={!!errors.enrolmentId}
                          helperText={errors.enrolmentId?.message ?? ''}
                        />
                      )}
                    />
                  ) : null}
                  {showGradeFilter ? (
                    <Controller
                      name={'grade'}
                      key={'grade-student-filter'}
                      control={control}
                      rules={{
                        required: 'A selection is required',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ChipGroup
                          items={Object.entries(StudentGrade).map((option) => {
                            const [_key, value] = option;
                            return {
                              value: value,
                              label: capitalCase(value),
                            };
                          })}
                          onChange={onChange}
                          value={value ?? []}
                          isMultiSelect
                          label="Grade"
                          error={!!errors.grade}
                          helperText={errors.grade?.message ?? ''}
                          cannotRemoveSelection
                        />
                      )}
                    />
                  ) : null}
                </>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss} variant="outlined" size="medium">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="medium" form="district-rep-students-filter-form">
            Apply ({selectedValues.length})
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default StudentsFiltersDialog;
