import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface StudentWithGrade {
  studentProfileId: string;
  grade: StudentGrade;
}

export interface UpdateStudentGradeRequest {
  students: StudentWithGrade[];
}

async function updateStudentGrade(request: UpdateStudentGradeRequest): Promise<void> {
  await axios.post<void>(`${config.apiUrl}/parent/student-profile/update-student-grade`, request);
}

export function useUpdateStudentGrade(options?: Omit<UseMutationOptions<void, AxiosError, UpdateStudentGradeRequest>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((submit) => updateStudentGrade(submit), options);
}
