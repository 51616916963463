import { Menu, MenuItem } from '@mui/material';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { focusVisibilityLabels } from './common';
import { FocusVisibilityOption } from './enums';

export type FocusOptionType = {
  [key in FocusVisibilityOption]: boolean;
};

interface FocusVisibilityMenuProps {
  anchorElement: HTMLButtonElement | null;
  onDismiss: () => void;
  focusOptions: FocusOptionType;
  setFocusOptions: React.Dispatch<React.SetStateAction<FocusOptionType>>;
}

const FocusVisibilityMenu = (props: FocusVisibilityMenuProps) => {
  const handleToggle = (option: FocusVisibilityOption) => {
    props.setFocusOptions((prevState) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  };

  return (
    <Menu id="focus-visibility-menu" open={!!props.anchorElement} anchorEl={props.anchorElement} onClose={props.onDismiss}>
      {Object.values(FocusVisibilityOption).map((option) => (
        <MenuItem key={option}>
          <Checkbox label={focusVisibilityLabels[option]} checked={props.focusOptions[option]} onChange={() => handleToggle(option)} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FocusVisibilityMenu;
