import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PageZoom } from '@hoot/events/interfaces/page-zoom';
import { ReadingState } from '@hoot/events/interfaces/reading.state';
import { BookResponse } from '../../events/interfaces/book-response';
import { preloadImageQueue } from '../../utils/preloadImage';
import { AppDispatch, RootState } from '../store';

export enum ReaderContext {
  SandboxReader = 'SandboxReader',
  StudentSpecificLibraryReader = 'StudentSpecificLibraryReader',
  InLessonReader = 'InLessonReader',
}

type OutOfLessonReaderContext = ReaderContext.SandboxReader | ReaderContext.StudentSpecificLibraryReader;

export const readerKeyLookup: Record<ReaderContext, keyof ReadersState> = {
  [ReaderContext.SandboxReader]: 'sandboxReader',
  [ReaderContext.StudentSpecificLibraryReader]: 'studentSpecificLibraryReader',
  [ReaderContext.InLessonReader]: 'inLessonReader',
};

export interface ReadersState {
  sandboxReader: ReaderBookState;
  studentSpecificLibraryReader: StudentSpecificLibraryReaderBookState;
  inLessonReader: InLessonReaderBook;
}

export interface ReaderBookState {
  book: BookResponse | null;
  isDoublePage: boolean;
  pageIndex: number;
  pageZoom: PageZoom;
}

interface StudentSpecificLibraryReaderBookState extends ReaderBookState {
  studentProfileId: string | null;
}

interface InLessonReaderBook extends StudentSpecificLibraryReaderBookState {
  lessonId: string | null;
}

export const defaultPageZoom: PageZoom = {
  offsetLeft: 0,
  offsetTop: 0,
  zoomAmount: 1,
};

const initialReaderState: ReaderBookState = {
  book: null,
  isDoublePage: false,
  pageIndex: 0,
  pageZoom: {
    ...defaultPageZoom,
  },
};

const initialReadersState: ReadersState = {
  sandboxReader: {
    ...initialReaderState,
  },
  studentSpecificLibraryReader: {
    ...initialReaderState,
    studentProfileId: null,
  },
  inLessonReader: {
    ...initialReaderState,
    studentProfileId: null,
    lessonId: null,
  },
};

export const readingSlice = createSlice({
  name: 'reading',
  initialState: {
    ...initialReadersState,
  },
  reducers: {
    resetAllReaders: () => {
      return { ...initialReadersState };
    },
    resetReader: (state, action: PayloadAction<ReaderContext>) => {
      if (action.payload === ReaderContext.SandboxReader) {
        state.sandboxReader = { ...initialReadersState.sandboxReader };
      } else if (action.payload === ReaderContext.StudentSpecificLibraryReader) {
        state.studentSpecificLibraryReader = { ...initialReadersState.studentSpecificLibraryReader };
      } else if (action.payload === ReaderContext.InLessonReader) {
        state.inLessonReader = { ...initialReadersState.inLessonReader };
      }
    },
    setBook: (state, action: PayloadAction<{ readerContext: OutOfLessonReaderContext; book: BookResponse | null }>) => {
      const { readerContext, book } = action.payload;
      const key = readerKeyLookup[readerContext];
      state[key].book = book;
    },
    setIsDoublePage: (
      state,
      action: PayloadAction<{
        readerContext: OutOfLessonReaderContext;
        isDoublePage: boolean;
      }>,
    ) => {
      const { readerContext, isDoublePage } = action.payload;
      const key = readerKeyLookup[readerContext];
      state[key].isDoublePage = isDoublePage;
    },
    setPageIndex: (
      state,
      action: PayloadAction<{
        readerContext: OutOfLessonReaderContext;
        pageIndex: number;
      }>,
    ) => {
      const { readerContext, pageIndex } = action.payload;
      const key = readerKeyLookup[readerContext];

      // Update the page index if it's different.
      if (state[key].pageIndex !== pageIndex) {
        state[key].pageIndex = pageIndex;

        // If we've changed the page index, then also reset the page zoom.
        state[key].pageZoom = { ...initialReaderState.pageZoom };
      }
    },
    setPageZoom: (
      state,
      action: PayloadAction<{
        readerContext: OutOfLessonReaderContext;
        pageZoom: PageZoom;
      }>,
    ) => {
      const { readerContext, pageZoom } = action.payload;
      const key = readerKeyLookup[readerContext];
      state[key].pageZoom = pageZoom;
    },
    resetPageZoom: (
      state,
      action: PayloadAction<{
        readerContext: OutOfLessonReaderContext;
      }>,
    ) => {
      const { readerContext } = action.payload;
      const key = readerKeyLookup[readerContext];
      state[key].pageZoom = {
        ...initialReadersState[key].pageZoom,
      };
    },
    resetZoom: (state, action: PayloadAction<OutOfLessonReaderContext>) => {
      const key = readerKeyLookup[action.payload];
      state[key].pageZoom = {
        ...initialReaderState.pageZoom,
      };
    },
    setLessonReaderState: (state, action: PayloadAction<ReadingState>) => {
      const { lessonId, studentProfileId, book, isDoublePage, pageIndex, pageZoom } = action.payload;

      state.inLessonReader = {
        lessonId,
        studentProfileId,
        book,
        pageIndex,
        pageZoom,
        isDoublePage,
      };
    },
  },
});

export const { resetAllReaders, resetReader, setBook, setPageIndex, setIsDoublePage, setPageZoom, resetPageZoom, setLessonReaderState } =
  readingSlice.actions;

export const setReaderBookFromResponse = createAsyncThunk<
  void,
  {
    readerContext: OutOfLessonReaderContext;
    book: BookResponse;
    defaultPageId?: string;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('reading/setReaderBook', async ({ readerContext, book, defaultPageId }, thunkApi) => {
  thunkApi.dispatch(setBook({ readerContext, book }));

  const pageIndex = defaultPageId ? book.pages.findIndex((page) => page.id === defaultPageId) : 0;

  thunkApi.dispatch(setPageIndex({ readerContext, pageIndex: pageIndex ?? 0 }));
  thunkApi.dispatch(queueImages({ readerContext, startIndex: pageIndex }));
});

/**
 * Returns the "corrected" page ID of a book based on the intended page index. The page ID may only be "corrected" if
 * reading in double-page mode, and the book is standard-double-page. If this criteria is true, then we ensure that
 * the correct pair of pages remain visible, which means we may need to adjust the page ID (and index).
 */
export const getCorrectedPageId = (book: BookResponse, isDoublePage: boolean, pageIndex: number): string => {
  const { isStandardDoublePage } = book;

  if (!isDoublePage) {
    return book.pages[pageIndex].id;
  }

  // if index is EVEN and isStandardDoublePage is true, when going to double page, set pageIndex to previous page
  // if index is ODD and isStandardDoublePage is false, when going to double page, set pageIndex to previous page
  const shouldUpdatePageIndex = (isStandardDoublePage && pageIndex % 2 === 0) || (!isStandardDoublePage && pageIndex % 2 !== 0);

  // this keeps the correct pairs of pages together (as if it were a physical book)
  return book.pages[shouldUpdatePageIndex ? pageIndex - 1 : pageIndex].id;
};

export const handleLessonReaderUpdated = createAsyncThunk<
  void,
  ReadingState | null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('reading/handleLessonReaderUpdated', async (readerState, thunkApi) => {
  if (readerState) {
    thunkApi.dispatch(setLessonReaderState(readerState));
    thunkApi.dispatch(queueImages({ readerContext: ReaderContext.InLessonReader }));
  } else {
    thunkApi.dispatch(resetReader(ReaderContext.InLessonReader));
  }
});

export const queueImages = createAsyncThunk<void, { readerContext: ReaderContext; startIndex?: number }, { dispatch: AppDispatch; state: RootState }>(
  'reading/queueImages',
  async ({ readerContext, startIndex = 0 }, thunkApi) => {
    const state = thunkApi.getState();

    const key = readerKeyLookup[readerContext];
    const reader = state.readers[key];
    const book = reader.book;

    if (!book) {
      console.error('Error preloading pages. No book loaded.');
      return;
    }

    if (book) {
      const pages = startIndex === 0 ? book.pages : [...book.pages.slice(startIndex, book.pages.length), ...book.pages.slice(0, startIndex)];
      const bookPageQueue = pages.map((p) => p.url);
      preloadImageQueue(bookPageQueue);
    }
  },
);

const pagesToTurn = (isDoublePage: boolean, currentPageIndex: number, isStandardDoublePage: boolean) => {
  if (!isDoublePage) {
    return 1;
  }

  // if index is EVEN and isStandardDoublePage is true, when going to double page, set pageIndex to previous page
  // if index is ODD and isStandardDoublePage is false, when going to double page, set pageIndex to previous page
  const shouldUpdatePageIndex = (isStandardDoublePage && currentPageIndex % 2 === 0) || (!isStandardDoublePage && currentPageIndex % 2 !== 0);

  // In double page mode we still need to take isStandardDoublePage in consideration to decide if we can jump two pages
  return shouldUpdatePageIndex ? 1 : 2;
};

export const getPreviousPageId = (book: BookResponse, isDoublePage: boolean, currentPageIndex: number) => {
  const { isStandardDoublePage } = book;
  const _pagesToTurn = pagesToTurn(isDoublePage, currentPageIndex, isStandardDoublePage);
  const newPageIndex = Math.max(currentPageIndex - _pagesToTurn, 0);
  return book.pages[newPageIndex].id;
};

export const getNextPageId = (book: BookResponse, isDoublePage: boolean, currentPageIndex: number) => {
  const { isStandardDoublePage } = book;
  const _pagesToTurn = pagesToTurn(isDoublePage, currentPageIndex, isStandardDoublePage);
  const newPageIndex = Math.min(currentPageIndex + _pagesToTurn, book.pages.length - (!isStandardDoublePage && isDoublePage ? 2 : 1));
  return book.pages[newPageIndex].id;
};

export const useReaders = () => {
  return useSelector((state: RootState) => state.readers);
};

export default readingSlice.reducer;
