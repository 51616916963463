import { DailyEventObjectFatalError, DailyFatalErrorType } from '@daily-co/daily-js';
import { DailyAudio, useCPULoad, useDailyEvent, useParticipantIds } from '@daily-co/daily-react';
import { Stack } from '@mui/system';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { EventType } from '@hoot/events/eventType';
import { DailyMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import useProfile from '@hoot/hooks/useProfile';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { UserProfileType } from '@hoot/models/api/user';
import { LessonErrorMessage, NetworkQualityLogMessage } from '@hoot/models/daily/logMessages';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useSocket } from '@hoot/ui/context/SocketContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import DailyErrorDialog, { ErrorDetails } from '../dialogs/DailyErrorDialog';
import LocalTeacherTile from './LocalTeacherTile';
import RemoteStudentTile from './RemoteStudentTile';
import LessonNotification from './ui-components/LessonNotification';
import { WaitingForUser } from './ui-components/VideoComponents';

const TeacherBlade = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const { profile } = useProfile();
  const { getUser } = useAuth();
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const { activePage } = useActiveLessonState();

  const teacherNetworkEventLogged = useRef<boolean>(false);
  const studentNetworkEventLogged = useRef<boolean>(false);
  const errorDetailsRef = useRef<ErrorDetails | null>(null);

  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const [errorDialog, setErrorDialog] = useState<boolean>(false);

  const waitingForStudent = remoteParticipantIds.length < 1;
  const authUserId = getUser().id;

  const bladeBackgroundColour =
    activePage === ActiveLessonPage.Reader || activePage === ActiveLessonPage.Whiteboard ? hootTokens.surface[2] : hootTokens.surface[0];

  // Displays the low network quality snackbar and logs the event to the api.
  useDailyEvent('meeting-session-state-updated', (e) => {
    const { teacherDailyNetworkEvent, studentDailyNetworkEvent } = e.meetingSessionState.data as DailyMediaState;

    // If the teacherDailyNetworkEvent state has been set with an error, and we haven't logged it yet..
    if (teacherDailyNetworkEvent && !teacherNetworkEventLogged.current) {
      dispatch(
        createFlashMessage({
          variant: 'light',
          message: 'Your internet connection is unstable. Try moving closer to your router or closing unneeded browser tabs to improve connection.',
          isIndefinite: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
      );

      // Log the api event.
      if (profile && inLesson) {
        const lowNetworkQualityMessage: NetworkQualityLogMessage = {
          profileSenderType: UserProfileType.Teacher,
          lessonId: inLesson?.lessonId,
          userId: authUserId,
          profileId: profile.id,
        };

        socket.emit(EventType.LogDailyLessonNetworkQuality, lowNetworkQualityMessage);
      }

      // We set the flag to true here so that we don't display the pop-up too much or flood the event logs.
      teacherNetworkEventLogged.current = true;
    }

    // If the studentDailyNetworkEvent state has been set with an error, and we haven't logged it yet..
    if (studentDailyNetworkEvent && !studentNetworkEventLogged.current) {
      dispatch(
        createFlashMessage({
          variant: 'light',
          message: 'Your Student has poor internet connection.',
          isIndefinite: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
      );

      // We set the flag to true here so that we don't display the pop-up too much or flood the event logs.
      // This one is specifically for the Student snackbar that shows on the Teachers screen.
      studentNetworkEventLogged.current = true;
    }
  });

  useCPULoad({
    onCPULoadChange: (cpuLoadChangeEvent) => {
      // only handle cases of high cpu
      if (cpuLoadChangeEvent.cpuLoadState === 'high') {
        let errorMessage = `Teacher's hardware experiencing high CPU due to: `;

        switch (cpuLoadChangeEvent.cpuLoadStateReason) {
          case 'decode':
            errorMessage = errorMessage.concat('video decoding. ');
            break;
          case 'encode':
            errorMessage = errorMessage.concat('video encoding. ');
            break;
          default:
            errorMessage = errorMessage.concat('an unknown reason. ');
            break;
        }

        errorMessage = errorMessage.concat(` This may result in poor call quality.`);

        console.log('[DailyTeacherBlade]: high-cpu', errorMessage);

        if (profile?.type && inLesson?.lessonId) {
          const lessonErrorMessage: LessonErrorMessage = {
            profileSenderType: profile.type,
            lessonId: inLesson?.lessonId,
            [profile.type]: {
              profileId: profile.id,
              userId: authUserId,
              errorMessage: errorMessage,
              type: 'high-cpu',
              fatal: false,
            },
          };

          socket.emit(EventType.SyncAndLogLessonDailyError, lessonErrorMessage);
        }
      }
    },
  });

  // We catch the Teachers fatal error here, log the event to the api then display the Error Dialog.
  useDailyEvent(
    'error',
    useCallback(
      (e: DailyEventObjectFatalError) => {
        errorDetailsRef.current = { userType: UserProfileType.Teacher, err: e };

        console.log('[DailyTeacherBlade]: fatal-error', e);

        if (profile?.type && inLesson?.lessonId) {
          const errorMessage: LessonErrorMessage = {
            profileSenderType: profile.type,
            lessonId: inLesson?.lessonId,
            [profile.type]: {
              profileId: profile.id,
              userId: authUserId,
              errorMessage: e.errorMsg,
              type: (e.error?.type as DailyFatalErrorType) ?? e.errorMsg,
              fatal: true,
            },
          };

          socket.emit(EventType.SyncAndLogLessonDailyError, errorMessage);
        }

        setErrorDialog(true);
      },
      [authUserId, inLesson?.lessonId, profile?.id, profile?.type, socket],
    ),
  );

  if (!inLesson) {
    // No current lesson created, bail.
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          width: '232px',
          backgroundColor: bladeBackgroundColour,
        }}
      >
        <Stack flex={1} rowGap={5}>
          <LessonNotification />
          {waitingForStudent ? <WaitingForUser name={inLesson.studentName} imgWidth={TEACHER_VIDEO_WIDTH} imgHeight={167} /> : <RemoteStudentTile />}
          <LocalTeacherTile />
          <DailyAudio maxSpeakers={2} onPlayFailed={(e) => console.error('Failed to play audio from Teacher blade', e)} />
        </Stack>
      </Stack>
      {errorDialog && <DailyErrorDialog errorDetails={errorDetailsRef?.current} isOpen={errorDialog} setDialogOpen={setErrorDialog} />}
    </>
  );
};

export default TeacherBlade;
