import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { Assessment } from '@hoot/hooks/api/assessment/useGetAssessmentById';

export interface SetStudentGradeRequest {
  studentProfileId: string;
  grade: StudentGrade;
}

async function setStudentGrade(request: SetStudentGradeRequest): Promise<void> {
  await axios.post<Assessment>(`${config.apiUrl}/public/hoot-assessment/v2/set-student-grade`, request);
}

export function useSetStudentGrade(options?: Omit<UseMutationOptions<void, AxiosError, SetStudentGradeRequest>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((submit) => setStudentGrade(submit), options);
}
