import { HootHttpHeader } from '@hoot-reading/hoot-core/dist/enums/hoot-http-header';
import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/react';
import { DateTime } from 'luxon';
import { UserProfileType } from '@hoot/models/api/user';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { config } from '../config';
import { ENV_NAME, IS_DEMO, IS_PROD, IS_STAGING, LOCAL_APP_VERSION } from '../constants/constants';
import { Profile } from '../models/api/user';

const SENTRY_HOOT_SESSION_TAG = 'hootSessionId';
const SENTRY_ASSUMED_PROFILE_TYPE_TAG = 'profileType';
const SENTRY_ASSUMED_PROFILE_ID_TAG = 'profileId';

async function initSentry() {
  if (!Sentry.isInitialized()) {
    // Temporarily including the demo env "Demo" for debugging hoot buddy sessions
    const isEnabled = IS_PROD || IS_STAGING || IS_DEMO;
    const replaySessionSampleRate = IS_PROD || IS_DEMO ? 1 : 0;

    Sentry.init({
      dsn: config.sentryDSN,
      replaysSessionSampleRate: replaySessionSampleRate,
      integrations: [
        Sentry.replayIntegration({
          mask: ['[data-sentry-mask]'],
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: true,
          networkDetailAllowUrls: [
            window.location.origin,
            'https://api.hootreading.com',
            'https://api.staging.hootreading.com',
            'http://127.0.0.1:7000',
            'https://gs.daily.co/rooms/check',
          ],
          networkCaptureBodies: true,
          networkRequestHeaders: [
            HootHttpHeader.SessionId,
            HootHttpHeader.RequestId,
            HootHttpHeader.UserId,
            HootHttpHeader.ProfileType,
            HootHttpHeader.ProfileId,
            HootHttpHeader.ReaderAppVersion,
          ],
          beforeAddRecordingEvent: (event: Sentry.ReplayFrameEvent) => {
            // Do not capture fetch/xhr request/response bodies for Hoot API
            // We've whitelisted multiple domains and we want some of them to include the body
            if (event.data.tag === 'performanceSpan' && (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr')) {
              const eventDescription = event.data.payload.description;
              const isHootApi =
                eventDescription.startsWith('http://127.0.0.1:7000') || !!eventDescription.match(/^https:\/\/api\..*\.hootreading\.com\/.*/);

              if (isHootApi && !!event.data.payload.data) {
                if (!!event.data.payload.data.request.body) {
                  event.data.payload.data.request.body = null;
                }
                if (!!event.data.payload.data.response.body) {
                  event.data.payload.data.response.body = null;
                }
              }
            }

            return event;
          },
        }),
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['errors'] }),
      ],
      enabled: isEnabled,
      environment: ENV_NAME,
      release: LOCAL_APP_VERSION,
      sampleRate: 1,
      tracesSampler: (samplingContext) => {
        // TODO: sc-7762 - remove the routes.library.url and replace it with the three other library urls for all books, and favourites
        // Examine provided context data (including parent decision, if any) along
        // with anything in the global namespace to compute the sample rate or
        // sampling decision for this transaction
        const busyRoutes = [
          routesDictionary.home.url,
          routesDictionary.schedule.url,
          routesDictionary.opportunities.url,
          routesDictionary.library.url,
        ];

        if (busyRoutes.includes(samplingContext.transactionContext.name)) {
          return 0.01;
        }

        // Default sample rate
        return 0.1;
      },
      ignoreErrors: [
        'Unknown ufrag', // webRTC error caused by get ready modal, non issue
      ],
    });
  }
}

async function setSentryUser(userId: string, assumedProfileId?: string, assumedProfileType?: UserProfileType) {
  if (Sentry.isInitialized()) {
    if (assumedProfileId && assumedProfileType) {
      Sentry.setUser({
        id: userId,
        assumedProfileId: assumedProfileId,
        assumedProfileType: assumedProfileType,
      });
    } else {
      Sentry.setUser({
        id: userId,
      });
    }
  }
}

async function manuallyStartNewReplay() {
  if (Sentry.isInitialized()) {
    const replay = Sentry.getReplay();

    if (replay) {
      // Stops current replay and starts a new one
      await replay.stop().then(() => {
        try {
          replay.start();
        } catch (err) {
          // Replay already in progress can be ignored
        }
      });
    }
  }
}

async function clearSentryUser() {
  if (Sentry.isInitialized()) {
    Sentry.setUser(null);
  }
}

async function setHootSessionTag(hootBrowserSessionId: string) {
  if (Sentry.isInitialized()) {
    Sentry.setTag(SENTRY_HOOT_SESSION_TAG, hootBrowserSessionId);
  }
}

async function setProfileTags(profile: Profile | undefined) {
  if (Sentry.isInitialized()) {
    Sentry.setTag(SENTRY_ASSUMED_PROFILE_TYPE_TAG, profile ? profile.type : undefined);
    Sentry.setTag(SENTRY_ASSUMED_PROFILE_ID_TAG, profile ? profile.id : undefined);
  }
}

function addBreadcrumb(breadcrumbOpts: Partial<Breadcrumb>) {
  const breadcrumb: Breadcrumb = {
    type: 'default',
    level: 'info',
    timestamp: DateTime.now().toSeconds(),
    ...breadcrumbOpts,
  };
  Sentry.addBreadcrumb(breadcrumb);
}

export const sentry = {
  initSentry,
  setSentryUser,
  clearSentryUser,
  manuallyStartNewReplay,
  setHootSessionTag,
  setProfileTags,
  addBreadcrumb,
};
