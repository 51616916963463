import { TypographyProps } from '@mui/material';
import * as CSS from 'csstype';
import { FunctionComponent, useMemo } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface TagProps {
  color?: TagColor;
  label: string;
  sx?: TypographyProps['sx'];
}

export enum TagColor {
  Neutral = 'Neutral',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

const colorMap: Record<TagColor, CSS.Property.Color> = {
  [TagColor.Neutral]: hootTokens.palette.neutral['180'],
  [TagColor.Info]: hootTokens.palette.secondary['180'],
  [TagColor.Success]: hootTokens.palette.success['180'],
  [TagColor.Warning]: hootTokens.palette.warning['180'],
  [TagColor.Error]: hootTokens.palette.error['160'],
};

const Tag: FunctionComponent<TagProps> = (props) => {
  const { color = TagColor.Neutral, label, sx = {} } = props;

  const backgroundColor = useMemo(() => {
    return colorMap[color] ?? colorMap['Neutral'];
  }, [color]);

  return (
    <HootTypography
      isPII={false}
      variant="labelsmall"
      sx={{
        padding: 1,
        backgroundColor,
        borderRadius: '8px',
        ...sx,
      }}
    >
      {label}
    </HootTypography>
  );
};

export default Tag;
