import { SvgIcon, SvgIconProps } from '@mui/material';

const ReviewRequiredIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#0CB2AD" />
    <path
      d="M13.04 16L11.288 12.82H10.796V16H8.744V7.576H12.188C12.852 7.576 13.416 7.692 13.88 7.924C14.352 8.156 14.704 8.476 14.936 8.884C15.168 9.284 15.284 9.732 15.284 10.228C15.284 10.788 15.124 11.288 14.804 11.728C14.492 12.168 14.028 12.48 13.412 12.664L15.356 16H13.04ZM10.796 11.368H12.068C12.444 11.368 12.724 11.276 12.908 11.092C13.1 10.908 13.196 10.648 13.196 10.312C13.196 9.992 13.1 9.74 12.908 9.556C12.724 9.372 12.444 9.28 12.068 9.28H10.796V11.368Z"
      fill="black"
    />
  </SvgIcon>
);

export default ReviewRequiredIcon;
