import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Skeleton, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HootAssessmentV2Response } from '@hoot/hooks/api/assessment/useGetStudentAssessmentsV2';
import { GetSubmissionDetailsResponse, useGetSubmissionDetails } from '@hoot/hooks/api/assessment/useGetSubmissionDetails';
import { HootAssessmentStatus, HootAssessmentUnitSet } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { Select, SelectProps } from '@hoot/ui/components/v2/core/Select';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import AbandonAssessmentModal from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/AbandonAssessmentModal';
import LaunchAssessmentButton from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/LaunchAssessmentButton';
import { hootAssessmentInstructionIndicatorLabel, hootAssessmentUnitSetLabel } from '../../../hoot-reading-assessment/utils/assessment-utils';

interface AssessmentDetailsModalProps extends Pick<DialogProps, 'open' | 'onClose'> {
  assessment: HootAssessmentV2Response | undefined;
  onAssessmentChanged: (assessment: HootAssessmentV2Response) => void;
  unitId?: string;
}

type UnitSubmission = GetSubmissionDetailsResponse;

interface UnitSetSubmissionsDictionary {
  [unitSet: string]: UnitSubmission[];
}

const AssessmentDetailsModal = (props: AssessmentDetailsModalProps) => {
  const { assessment, onAssessmentChanged, unitId, ...dialogProps } = props;

  const { studentProfileSnippet } = useStudentDetailsContext();
  const [unitSetSubmissions, setUnitSetSubmissions] = useState<UnitSetSubmissionsDictionary>({});
  const [unitSetSelectOptions, setUnitSetSelectOptions] = useState<{ label: string; unitSet: HootAssessmentUnitSet }[]>([]);
  const [selectedUnitSet, setSelectedUnitSet] = useState<HootAssessmentUnitSet | ''>('');
  const [showAbandonAssessmentConfirmationDialog, setShowAbandonAssessmentConfirmationDialog] = useState(false);

  const dispatch = useDispatch();

  const getAssessmentUnitSubmissionsRequest = useGetSubmissionDetails(assessment?.id!, {
    enabled: !!assessment?.id,
    onSuccess: (assessmentUnitSubmissions) => {
      const submissionsGroupedByUnitSet = mapUnitSubmissionsToUnitSetSubmissions(assessmentUnitSubmissions);
      const selectOptions = Object.keys(submissionsGroupedByUnitSet).map((unitSet) => ({
        unitSet: unitSet as HootAssessmentUnitSet,
        label: hootAssessmentUnitSetLabel[unitSet as HootAssessmentUnitSet],
      }));
      setUnitSetSubmissions(submissionsGroupedByUnitSet);
      setUnitSetSelectOptions(selectOptions);
      const defaultUnitSet = (Object.keys(submissionsGroupedByUnitSet)[0] as HootAssessmentUnitSet) ?? '';

      if (unitId) {
        // select set that contains selected unitId
        const groupKey =
          Object.entries(submissionsGroupedByUnitSet).find(([_key, items]) => items.some((item) => item.unit.unitId === unitId))?.[0] || null;
        if (groupKey) {
          setSelectedUnitSet(groupKey as HootAssessmentUnitSet);
        } else {
          setSelectedUnitSet(defaultUnitSet);
        }
      } else {
        setSelectedUnitSet(defaultUnitSet);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ variant: 'error', message: 'Whoops! It looks like an error occurred.' }));
    },
  });

  const _onClose = () => {
    dialogProps.onClose?.({}, 'escapeKeyDown');
  };

  const onChangeSelectedUnitSet: SelectProps['onChange'] = (event) => {
    setSelectedUnitSet(event.target.value as HootAssessmentUnitSet);
  };

  const onShowAbandonAssessmentConfirmationDialog = () => {
    setShowAbandonAssessmentConfirmationDialog(true);
  };

  const onDismissAbandonAssessmentConfirmationDialog = () => {
    setShowAbandonAssessmentConfirmationDialog(false);
    _onClose();
  };

  const selectedUnitSetSubmissions = unitSetSubmissions?.[selectedUnitSet] ?? [];

  const UnitSetSubmissions = () => (
    <Stack gap={2}>
      <HootTypography isPII={false} variant="titlemedium">
        Select a category to display
      </HootTypography>
      <Select label="Drop Down Options" value={selectedUnitSet} onChange={onChangeSelectedUnitSet}>
        {unitSetSelectOptions.map((x) => (
          <option key={`option-${x.unitSet}`} value={x.unitSet}>
            {x.label}
          </option>
        ))}
      </Select>
      {selectedUnitSetSubmissions.map((x) => (
        <UnitSubmissionDetails key={`unit-submission-${x.submissionId}`} unitSubmission={x} />
      ))}
    </Stack>
  );

  return (
    <>
      <Dialog fullWidth maxWidth="sm" {...dialogProps}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Reading Assessment Details
          <IconButton onClick={_onClose}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>{getAssessmentUnitSubmissionsRequest.isFetching ? <LoadingContent /> : <UnitSetSubmissions />}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={_onClose}>
            Close
          </Button>
          {assessment?.status === HootAssessmentStatus.InProgress ? (
            <>
              <Button variant="contained" color="error.80" onClick={onShowAbandonAssessmentConfirmationDialog}>
                End
              </Button>
              <LaunchAssessmentButton
                studentProfileSnippet={studentProfileSnippet}
                assessmentIdToResume={assessment.id}
                resumeProps={{ color: 'success.60' }}
              />
            </>
          ) : null}
        </DialogActions>
      </Dialog>
      {showAbandonAssessmentConfirmationDialog && assessment?.id ? (
        <AbandonAssessmentModal
          open={showAbandonAssessmentConfirmationDialog}
          onClose={onDismissAbandonAssessmentConfirmationDialog}
          onAssessmentChanged={onAssessmentChanged}
          assessmentId={assessment.id}
        />
      ) : null}
    </>
  );
};

const LoadingContent = () => {
  return (
    <Stack gap={2}>
      <Skeleton variant="rectangular" sx={{ width: '227px', height: '24px' }} />
      <Skeleton variant="rectangular" sx={{ width: '100%', height: '55px' }} />
      <Skeleton variant="rectangular" sx={{ width: '227px', height: '21px' }} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '16px',
        }}
      >
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '58px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '58px' }} />
      </Box>
      <Skeleton variant="rectangular" sx={{ width: '227px', height: '21px' }} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '16px',
        }}
      >
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '58px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '58px' }} />
      </Box>
    </Stack>
  );
};

interface UnitSubmissionProps {
  unitSubmission: UnitSubmission;
}

const UnitSubmissionDetails = (props: UnitSubmissionProps) => {
  const { unitSubmission } = props;
  const {
    submissionId,
    unit: { unitName },
    submissionDetails,
  } = unitSubmission;

  return (
    <Stack gap={2}>
      <HootTypography isPII={false} variant="titlesmall">
        {unitName}
      </HootTypography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '16px',
        }}
      >
        {submissionDetails.map((x, index) => (
          <Fragment key={`submissionId-${submissionId}-field-${index}`}>
            <ReadOnlyTextField label={x.label} body={x.value} />
            {x.instructionIndicator ? (
              <ReadOnlyTextField label="Instruction Indicator" body={hootAssessmentInstructionIndicatorLabel[x.instructionIndicator]} />
            ) : null}
            {x.incorrectLetterSounds ? (
              <ReadOnlyTextField
                label="Incorrect Letter Sounds"
                body={x.incorrectLetterSounds}
                variant="filled"
                filledColour="error.190"
                sx={{
                  gridColumn: 'span 2',
                  width: '100%',
                }}
              />
            ) : null}
          </Fragment>
        ))}
      </Box>
    </Stack>
  );
};

const mapUnitSubmissionsToUnitSetSubmissions = (assessmentUnitSubmissions: UnitSubmission[]): UnitSetSubmissionsDictionary => {
  return (
    assessmentUnitSubmissions
      // Only grab submissions that have a non-null unit set.
      .filter((x) => !!x.unit.unitSet)
      // Group each submission by unit set.
      .reduce<UnitSetSubmissionsDictionary>((unitSetAccumulator, currentUnitSubmission) => {
        const unitSet = currentUnitSubmission.unit.unitSet!;
        const unitSetSubmissions = unitSetAccumulator[unitSet] ?? [];

        return {
          ...unitSetAccumulator,
          [unitSet]: [...unitSetSubmissions, currentUnitSubmission],
        };
      }, {})
  );
};

export default AssessmentDetailsModal;
