import { EventType } from '@hoot/events/eventType';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { WhiteboardCloseRequestMessage } from '@hoot/events/messages/whiteboard-close-request.message';
import { WhiteboardRecenterRequestMessage } from '@hoot/events/messages/whiteboard-recenter-request.message';
import { WhiteboardResetRequestMessage } from '@hoot/events/messages/whiteboard-reset-request.message';
import { WhiteboardToolChangeRequestMessage } from '@hoot/events/messages/whiteboard-tool-change-request.message';
import { useEmit } from '@hoot/hooks/useEmit';

interface UseTeacherInLessonWhiteboardControllerProps {
  lessonId: string;
  whiteboardId: string;
}

const useTeacherInLessonWhiteboardController = (props: UseTeacherInLessonWhiteboardControllerProps) => {
  const { lessonId, whiteboardId } = props;

  const closeWhiteboardEmitter = useEmit<WhiteboardCloseRequestMessage>(EventType.WhiteboardCloseRequest, undefined, {
    enabled: false,
  });
  const resetWhiteboardEmitter = useEmit<WhiteboardResetRequestMessage>(EventType.WhiteboardResetRequest, undefined, {
    enabled: false,
  });
  const whiteboardToolChangeEmitter = useEmit<WhiteboardToolChangeRequestMessage>(EventType.WhiteboardToolChangeRequest, undefined, {
    enabled: false,
  });
  const recenterWhiteboardEmitter = useEmit<WhiteboardRecenterRequestMessage>(EventType.WhiteboardRecenterRequest, undefined, {
    enabled: false,
  });

  const closeWhiteboard = () => {
    closeWhiteboardEmitter.queueMessage({
      lessonId,
    });
  };

  const onResetWhiteboard = () => {
    resetWhiteboardEmitter.queueMessage({
      lessonId,
      whiteboardId,
    });
  };

  const onRequestToolChange = (tool: WhiteboardTool, colour: WhiteboardToolColour) => {
    whiteboardToolChangeEmitter.queueMessage({
      lessonId,
      whiteboardId,
      tool,
      colour,
    });
  };

  const onRequestRecenter = () => {
    recenterWhiteboardEmitter.queueMessage({
      lessonId,
      whiteboardId,
    });
  };

  return {
    closeWhiteboard,
    onResetWhiteboard,
    onRequestToolChange,
    /**
     * Sends a request to re-center the camera. We could just do this locally, but the student UX does not have a way to
     * manually invoke this. In the event that the student can not see all content in the whiteboard, the student _should_
     * communicate this to the teacher, at which point the teacher can invoke this function, and _both_ clients will have
     * their whiteboard camera re-centered.
     */
    onRequestRecenter,
  };
};

export default useTeacherInLessonWhiteboardController;
