import { Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Editor } from 'tldraw';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { RootState } from '@hoot/redux/store';
import Whiteboard from '@hoot/ui/components/v2/whiteboard/Whiteboard';
import { useWhiteboardManager } from '@hoot/ui/components/v2/whiteboard/useWhiteboardManager';

const StudentInLessonWhiteboardPage = () => {
  const [editor, setEditor] = useState<Editor>();

  const activeLessonState = useActiveLessonState();
  const lessonId = activeLessonState.inLesson!.lessonId;
  const whiteboardId = activeLessonState.activeWhiteboardId!; // This must exist, else we wouldn't be on this page right now.

  const { isLocked: isStudentLocked } = useStudentMediaState();

  const currentTool = useSelector((state: RootState) => {
    return state.whiteboard.whiteboard?.currentTool ?? WhiteboardTool.Select;
  });
  const currentToolColour = useSelector((state: RootState) => {
    return state.whiteboard.whiteboard?.currentColour ?? WhiteboardToolColour.Black;
  });

  const syncManager = useWhiteboardManager({
    lessonId,
    whiteboardId,
    editor,
    defaultTool: currentTool,
    defaultToolColour: currentToolColour,
    readOnlyMode: isStudentLocked,
  });

  const onEditorReady = (editor: Editor) => {
    setEditor(editor);
    syncManager.onEditorReady(editor);
  };

  return (
    <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center', padding: 3 }}>
      <Whiteboard store={syncManager.store} onEditorReady={onEditorReady} />
    </Stack>
  );
};

export default StudentInLessonWhiteboardPage;
