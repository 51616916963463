import { SvgIcon, SvgIconProps } from '@mui/material';

const ScheduledLessonIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="white" />
    <rect x="0.5" y="0.5" width="23" height="23" stroke="black" strokeOpacity="0.1" />
    <path d="M11.796 14.416H14.484V16H9.744V7.576H11.796V14.416Z" fill="black" />
  </SvgIcon>
);

export default ScheduledLessonIcon;
