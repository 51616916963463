import { SvgIcon, SvgIconProps } from '@mui/material';

const MasteredIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#6CB944" />
    <path d="M16.284 7.576V16H14.232V10.948L12.348 16H10.692L8.796 10.936V16H6.744V7.576H9.168L11.532 13.408L13.872 7.576H16.284Z" fill="black" />
  </SvgIcon>
);

export default MasteredIcon;
