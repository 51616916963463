import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Stack, Tabs, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetBookCollections, { BookCollection } from '@hoot/hooks/api/library/useGetBookCollections';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import useGetStudentProfileV2 from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType } from '@hoot/models/api/library';
import {
  DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE,
  DEFAULT_LIBRARY_LIST_VIEW_PAGE_SIZE,
  LibraryTab,
  PaginationMode,
  libraryTabToShelfTypeDictionary,
} from '@hoot/redux/reducers/librarySlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';
import TeacherLibrarySearchModal from '@hoot/ui/pages/v2/teacher/library/TeacherLibrarySearchModal';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import BookCollectionsFilterModal from './BookCollectionsFilterModal';
import TeacherLibraryFilterDrawer from './TeacherLibraryFilterDrawer';

export const getDefaultLibraryQuery = (paginationMode: PaginationMode, libraryTab?: LibraryTab): QueryLibraryV2 => ({
  page: 1,
  shelfType: libraryTabToShelfTypeDictionary[libraryTab ?? LibraryTab.AllBooks] ?? ShelfType.AllBooks,
  pageSize: paginationMode === PaginationMode.Append ? DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE : DEFAULT_LIBRARY_LIST_VIEW_PAGE_SIZE,
  excludedResourceTypes: [BookType.ProgressMonitoringAssessment],
});

interface TeacherLibraryViewToggleProps {
  currentPaginationMode: PaginationMode;
  onPaginationModeChanged: (paginationMode: PaginationMode) => void;
}

const TeacherLibraryViewToggle = (props: TeacherLibraryViewToggleProps) => {
  const { currentPaginationMode, onPaginationModeChanged } = props;
  const isGalleryView = currentPaginationMode === PaginationMode.Append;

  const handleClick = () => {
    onPaginationModeChanged(currentPaginationMode === PaginationMode.Replace ? PaginationMode.Append : PaginationMode.Replace);
  };

  return (
    <Tooltip title={isGalleryView ? 'Library List View' : 'Library Gallery View'}>
      <span>
        <IconButton onClick={handleClick}>
          {isGalleryView ? <Icon name="list_view" color="primary" /> : <Icon name="gallery_view" color="primary" />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const BookCollections = (props: {
  isDesktop: boolean;
  query: QueryLibraryV2;
  setShowCollectionsModal: (val: boolean) => void;
  clearCollectionsFilter: () => void;
  bookCollectionsList: BookCollection[];
}) => {
  const { isDesktop, query, setShowCollectionsModal, clearCollectionsFilter, bookCollectionsList } = props;

  const isBookCollectionsApplied = query.bookCollectionIds && query.bookCollectionIds.length > 0;

  let appliedCollectionsText = '';

  if (isBookCollectionsApplied) {
    appliedCollectionsText = query.bookCollectionIds && query.bookCollectionIds.length === 1 ? `Collection:` : `Collections:`;

    for (const bookCollectionId of query.bookCollectionIds!) {
      appliedCollectionsText += ` ${bookCollectionsList.find((bc) => bc.id === bookCollectionId)?.name ?? ''},`;
    }
    appliedCollectionsText = appliedCollectionsText.slice(0, -1);
  }

  const handleClearCollectionsClick = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation(); // prevent toggling filters drawer
    clearCollectionsFilter();
  };

  const handleShowCollectionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowCollectionsModal(true);
  };

  return (
    <>
      <Tooltip title={'Book Collections'}>
        <span>
          <IconButton type="button" onClick={() => setShowCollectionsModal(true)}>
            {isBookCollectionsApplied ? <Icon name="collections-coloured" /> : <Icon name="collections" />}
          </IconButton>
        </span>
      </Tooltip>
      {isBookCollectionsApplied && isDesktop ? (
        <Button
          variant="contained"
          size="small"
          color="secondary.60"
          onClick={handleShowCollectionsClick}
          endIcon={<CloseIcon sx={{ color: '#fff' }} onClick={handleClearCollectionsClick} />}
        >
          <HootTypography isPII={false} variant={'labelsmall'} color={'white'}>
            {appliedCollectionsText}
          </HootTypography>
        </Button>
      ) : null}
    </>
  );
};

export const filterCount = (libraryQuery: QueryLibraryV2 | null): number => {
  if (!libraryQuery) {
    return 0;
  }
  const nonFilterKeys = ['page', 'pageSize', 'studentProfileId', 'teacherProfileId', 'shelfType', 'orderBy', 'orderSort', 'title'];
  const filterKeysToIgnore = ['subject', 'language', 'bookCollectionIds', 'isInstructionalLibrary', 'excludedResourceTypes']; //subject and language are default queries and always applied
  const libraryFilterQueries = Object.entries(libraryQuery).filter(([key, _value]) => !nonFilterKeys.includes(key));

  return libraryFilterQueries.filter(([key, value]) => !filterKeysToIgnore.includes(key) && !!value).length;
};

export const hasFilters = (libraryQuery: QueryLibraryV2 | null): boolean => {
  return !libraryQuery || filterCount(libraryQuery) > 0;
};

interface Props {
  selectedTab: LibraryTab;
  libraryQuery: QueryLibraryV2;
  whiteboardTemplateQuery?: string;
  paginationMode: PaginationMode;
  isInLesson: boolean;
  // If we're looking at a student-specific library.
  studentProfileId?: string;
  onLibraryQueryChanged: (newQuery: QueryLibraryV2) => void;
  onWhiteboardTemplateQueryChanged: (query?: string) => void;
  onTabChanged: (tab: LibraryTab) => void;
  onPaginationModeChanged: (paginationMode: PaginationMode) => void;
  onClearFilters: () => void;
}

const TeacherLibraryFilterBar = (props: Props) => {
  const {
    selectedTab,
    libraryQuery,
    whiteboardTemplateQuery,
    paginationMode,
    studentProfileId,
    isInLesson,
    onLibraryQueryChanged,
    onWhiteboardTemplateQueryChanged,
    onTabChanged,
    onPaginationModeChanged,
    onClearFilters,
  } = props;

  const theme = useTheme();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [showCollectionsModal, setShowCollectionsModal] = useState<boolean>(false);
  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  const bookCollections = useGetBookCollections({ enabled: showCollectionsModal });
  const bookCollectionsList = bookCollections.data ? bookCollections.data.collections : [];

  const _filterCount = filterCount(libraryQuery);
  const _hasFilters = _filterCount > 0;

  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const { toggleFullscreen, isFullscreen } = useFullscreen();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenDrawerWidth = useMediaQuery(theme.breakpoints.up('sm'));

  const { data: student, isFetching } = useGetStudentProfileV2(studentProfileId ?? '', { enabled: !!studentProfileId });
  const navigate = useNavigate();

  const toolsMenuItems = (): MenuItem[] => {
    const toolItemsArray = [
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
    ];

    if (isInLesson) {
      toolItemsArray.splice(
        -1,
        0,
        {
          id: 'notepad',
          label: 'Notepad',
          icon: <Icon name="pen" />,
          onClick: toggleShowNotepad,
        },
        {
          id: 'celebrations',
          label: 'Celebrations',
          icon: <Icon name="hoot_wheel" />,
          onClick: toggleCelebrationsDialog,
        },
      );
    }

    return toolItemsArray;
  };

  const onCloseFilterDrawer = () => setShowFilters(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleFiltersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowFilters((currentState) => !currentState);
  };

  const handleSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.currentTarget.blur();
    setShowSearchModal((currentState) => !currentState);
  };

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const onDismissSearchModal = () => {
    setShowSearchModal(false);
  };

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const handleTabChange = (_: React.SyntheticEvent, selectedTab: LibraryTab) => {
    onTabChanged(selectedTab);
  };

  const clearSearch = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation(); // prevent toggling search modal
    onApplySearch(undefined);
  };

  const onApplySearch = (text?: string) => {
    if (selectedTab === LibraryTab.Whiteboard) {
      onWhiteboardTemplateQueryChanged?.(text);
    } else {
      onLibraryQueryChanged({
        ...libraryQuery,
        page: 1,
        title: text,
      });
    }
  };

  const searchBookCollections = (bookCollections?: string[]) => {
    onLibraryQueryChanged({
      ...libraryQuery,
      page: 1,
      bookCollectionIds: bookCollections && bookCollections.length > 0 ? bookCollections : undefined,
    });
  };

  const _onClearFilters = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation(); // prevent toggling filters drawer
    onClearFilters();
  };

  const activeSearchQuery = selectedTab === LibraryTab.Whiteboard ? whiteboardTemplateQuery : libraryQuery.title;

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {isFetching ? (
            <>
              <Skeleton variant="rounded" sx={{ width: '40px', height: '30px', mr: 2, ...hootTokens.text.bodylarge }} />
              <Skeleton variant="text" sx={{ width: '250px', height: '40px', ...hootTokens.text.bodylarge }} />
            </>
          ) : studentProfileId ? (
            <>
              <Tooltip title={isInLesson ? 'Student Profile navigation disabled while in lesson' : 'Back to Student Profile'}>
                <span style={{ cursor: isInLesson ? 'not-allowed' : 'default' }}>
                  <IconButton
                    disabled={isInLesson}
                    type="button"
                    onClick={() => (studentProfileId ? navigate(routesDictionary.myStudents.details.profile.url(studentProfileId)) : null)}
                  >
                    <Icon name="account_filled" htmlColor={`${hootTokens.palette.black}`} />
                  </IconButton>
                </span>
              </Tooltip>
              <HootTypography isPII={true} variant="titlelarge">
                {isDesktop && student ? `${student.name}'s Library` : 'Student Library'}
              </HootTypography>
            </>
          ) : (
            <Stack direction="row" width="100%" alignItems="center" gap="8px">
              <Tooltip title="This space can be used to explore and become familiar with the Hoot Reading library">
                <Box>
                  <Icon name="beaker" />
                </Box>
              </Tooltip>
              <HootTypography isPII={false} variant="titlelarge" width={'100%'}>
                Library Sandbox
              </HootTypography>
            </Stack>
          )}
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              marginLeft: theme.spacing(3),
              gap: theme.spacing(2),
            })}
          >
            {/* Gallery/Table View. */}
            <TeacherLibraryViewToggle currentPaginationMode={paginationMode} onPaginationModeChanged={onPaginationModeChanged} />

            {/* Tools Menu */}
            <Tooltip title="Show Tools Menu">
              <span>
                <IconButton onClick={showToolsMenu}>
                  <Icon name="divider_caliper" />
                </IconButton>
              </span>
            </Tooltip>
            <PopoverMenu
              id="tools-menu"
              variant="dark"
              anchorEl={toolsIconButtonElement}
              open={!!toolsIconButtonElement}
              onClose={dismissToolsMenu}
              items={toolsMenuItems()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />

            {/* Collections */}
            {selectedTab !== LibraryTab.Whiteboard && (
              <BookCollections
                isDesktop={isDesktop}
                query={libraryQuery}
                setShowCollectionsModal={setShowCollectionsModal}
                clearCollectionsFilter={() => searchBookCollections(undefined)}
                bookCollectionsList={bookCollectionsList}
              />
            )}

            {/* Filters */}
            {selectedTab !== LibraryTab.Whiteboard && (
              <>
                {isScreenDrawerWidth ? (
                  <Tooltip title={'Filter'}>
                    <span>
                      {_hasFilters ? (
                        <IconButton type="button" color="secondary.60" onClick={handleFiltersClick}>
                          <Icon name="filter_detailed_filled" htmlColor={hootTokens.palette.secondary['60']} />
                        </IconButton>
                      ) : (
                        <IconButton type="button" onClick={handleFiltersClick}>
                          <Icon name="filter_detailed" />
                        </IconButton>
                      )}
                    </span>
                  </Tooltip>
                ) : null}

                {_hasFilters && isDesktop ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary.60"
                    onClick={handleFiltersClick}
                    endIcon={<CloseIcon sx={{ color: '#fff' }} onClick={_onClearFilters} />}
                  >
                    <HootTypography isPII={false} variant={'labelsmall'} color={'white'}>
                      {' '}
                      Active Filters: {_filterCount}
                    </HootTypography>
                  </Button>
                ) : null}
              </>
            )}

            {/* Search */}
            <Tooltip title={'Search'}>
              <span>
                {!!activeSearchQuery ? (
                  <IconButton type="button" color="secondary.60" onClick={handleSearchClick}>
                    <Icon name="search_filled" htmlColor={hootTokens.palette.secondary['60']} />
                  </IconButton>
                ) : (
                  <IconButton type="button" onClick={handleSearchClick}>
                    <Icon name="search" />
                  </IconButton>
                )}
              </span>
            </Tooltip>
            {activeSearchQuery && isDesktop ? (
              <Button
                variant="contained"
                size="small"
                color="secondary.60"
                onClick={handleSearchClick}
                endIcon={<CloseIcon sx={{ color: '#fff' }} onClick={clearSearch} />}
              >
                <HootTypography isPII={false} variant={'labelsmall'} color={'white'}>
                  Search query: "{activeSearchQuery}"
                </HootTypography>
              </Button>
            ) : null}
          </Box>
        </Box>
      </form>

      <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" sx={{ borderTop: 'unset' }}>
        <Tab key={LibraryTab.AllBooks} label="All Books" value={LibraryTab.AllBooks} isSelected={selectedTab === LibraryTab.AllBooks} />
        <Tab key={LibraryTab.Favorites} label="Favorites" value={LibraryTab.Favorites} isSelected={selectedTab === LibraryTab.Favorites} />
        {/* We only show the "Lesson Plan" tab when viewing a student's library. */}
        {studentProfileId && (
          <Tab key={LibraryTab.LessonPlan} label="Lesson Plan" value={LibraryTab.LessonPlan} isSelected={selectedTab === LibraryTab.LessonPlan} />
        )}
        {isInLesson && (
          <Tab key={LibraryTab.Whiteboard} label="Whiteboard" value={LibraryTab.Whiteboard} isSelected={selectedTab === LibraryTab.Whiteboard} />
        )}
      </Tabs>

      <TeacherLibrarySearchModal
        show={showSearchModal}
        isInLesson={isInLesson}
        defaultSearchCriteria={activeSearchQuery}
        onDismiss={onDismissSearchModal}
        onSearch={onApplySearch}
        searchTitle={selectedTab === LibraryTab.Whiteboard ? 'Search Whiteboard Templates' : undefined}
        searchLabel={selectedTab === LibraryTab.Whiteboard ? 'Search Whiteboard Templates' : undefined}
        searchPlaceholderText={selectedTab === LibraryTab.Whiteboard ? 'Whiteboard Template' : undefined}
      />

      <BookCollectionsFilterModal
        show={showCollectionsModal}
        query={libraryQuery}
        onDismiss={() => setShowCollectionsModal(false)}
        dispatchCollectionsFilter={searchBookCollections}
        bookCollectionsList={bookCollectionsList}
      />

      <TeacherLibraryFilterDrawer
        isOpen={showFilters}
        onCloseFilterDrawer={onCloseFilterDrawer}
        paginationMode={paginationMode}
        query={libraryQuery}
        onLibraryQueryChanged={onLibraryQueryChanged}
      />
    </>
  );
};

export default TeacherLibraryFilterBar;
