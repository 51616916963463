import { Button, Link, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';

const StudentNotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoToDashboardClick = () => {
    navigate(routesDictionary.dashboard.url, { replace: true });
  };

  return (
    <PageLayout>
      <Card sx={{ marginTop: '16px' }}>
        <Stack textAlign={'center'} justifyContent={'center'} sx={{ marginTop: '8px' }}>
          <HootTypography isPII={false} variant="titlelarge">
            Oops! This page wasn't found.
          </HootTypography>
          <HootTypography
            isPII={false}
            variant={'bodylarge'}
            sx={{
              marginTop: '32px',
            }}
          >
            Please return to your Dashboard or contact{' '}
            <Link sx={{ color: 'black' }} href="mailto:help@hootreading.com">
              help@hootreading.com
            </Link>{' '}
            for the answers you need.
          </HootTypography>
          <Stack direction={'row'} gap={3} justifyContent={'center'} sx={{ marginTop: '32px' }}>
            <Button variant="contained" size="large" onClick={handleGoToDashboardClick}>
              Go to Dashboard
            </Button>
          </Stack>
        </Stack>
      </Card>
    </PageLayout>
  );
};

export default StudentNotFoundPage;
