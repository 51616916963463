import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { Grid } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { PMAContainer } from '../PMAContainer';
import { PMAFooter } from '../PMAPageFooter';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import useTeacherPMAController from '../useTeacherPMAController';

const answerRecord: Record<PMAStepTestAnswer, JSX.Element> = {
  [PMAStepTestAnswer.Correct]: <Icon name="checkmark_square" />,
  [PMAStepTestAnswer.Wrong]: <Icon name="close_filled_square" />,
  [PMAStepTestAnswer.NoAnswer]: <></>,
};

export interface ModuleDetails {
  fullPrompt: string;
  prompt: string;
  answer: PMAStepTestAnswer;
}

interface Props {
  prompts: ModuleDetails[];
}

const PMA_QUESTIONNAIRE_LAYOUT_3 = (props: Props) => {
  const { prompts } = props;
  const [unitQuestionAnswerPair, setUnitQuestionAnswerPair] = useState<UnitQuestionAnswerPair[]>(
    prompts.map<UnitQuestionAnswerPair>((p) => ({ fullPrompt: p.fullPrompt, wordPrompt: p.prompt, answer: PMAStepTestAnswer.NoAnswer })),
  );
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const { onUnitComplete } = useTeacherPMAController();
  const dispatch = useAppDispatch();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);

  const pmaController = useTeacherPMAController();

  const allQuestionsAnswered =
    unitQuestionAnswerPair.length > 0 && !unitQuestionAnswerPair.some((pair) => pair.answer === PMAStepTestAnswer.NoAnswer);
  const allFormFieldsFilledOut = unitQuestionAnswerPair.filter((uqap) => uqap.answer === PMAStepTestAnswer.NoAnswer).length === 0;

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (data.submissionData) {
        const subData = data.submissionData as UnitQuestionAnswerPair[];
        setUnitQuestionAnswerPair(subData);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const { onSetText } = useTeacherPMAController();

  useEffect(() => {
    if (allQuestionsAnswered) {
      onUnitComplete(allQuestionsAnswered);
    }
  }, [allQuestionsAnswered]);

  const answers = unitQuestionAnswerPair.map((p) => p.answer);
  const [currentIndex, setIndex] = useState<number>(0);

  const fullPrompt = currentIndex < prompts.length ? prompts[currentIndex].fullPrompt : undefined;

  useEffect(() => {
    if (fullPrompt) {
      onSetText(fullPrompt);
    }
  }, [fullPrompt]);

  const handleCorrectClick = () => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) =>
        idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: PMAStepTestAnswer.Correct } : a,
      ),
    );
    setIsUnitQuestionnaireDirty(true);
    advanceToNextQuestion();
  };

  const handleIncorrectClick = () => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) =>
        idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: PMAStepTestAnswer.Wrong } : a,
      ),
    );

    setIsUnitQuestionnaireDirty(true);
    advanceToNextQuestion();
  };

  const advanceToNextQuestion = () => {
    if (currentIndex <= prompts.length) {
      setIndex((prev) => prev + 1);
    }
  };

  // Emphasizes the current word within the full prompt.
  const formatFullPrompt = (): JSX.Element => {
    if (!prompts) return <></>;

    const sentence = prompts[currentIndex]?.fullPrompt ?? '';
    const shortPrompt = prompts[currentIndex]?.prompt ?? '';
    const toArray = sentence.split(shortPrompt);

    return (
      <HootTypography variant="displaysmall" textAlign="center" fontWeight={400} isPII={false}>
        {toArray.map((x, idx) => (
          <Fragment key={`${x}-${idx}`}>
            {x}
            {idx !== toArray.length - 1 && <span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>{shortPrompt}</span>}
          </Fragment>
        ))}
      </HootTypography>
    );
  };

  // Combine details array w/ answers array from state. Allows easier time mapping it over in the scorecard below.
  const toKeyValPair = (details: ModuleDetails[], answers: PMAStepTestAnswer[]) => {
    return details.map(({ prompt }, idx) => [prompt, answers[idx]]);
  };

  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);

  const onPromptClick = (idx: number) => {
    setIndex(idx);
    setShowEditAnswerDialog(true);
  };

  const handleCancelEdit = () => {
    setShowEditAnswerDialog(false);
  };

  const handleEditAnswer = (newAnswer: PMAStepTestAnswer) => {
    setUnitQuestionAnswerPair((currentState: UnitQuestionAnswerPair[]) =>
      currentState.map((a, idx) => (idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: newAnswer } : a)),
    );

    handleCancelEdit();
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    setUnitQuestionAnswerPair([]);
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: unitQuestionAnswerPair,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setUnitQuestionAnswerPair([]);
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };
  const disableSubmissionBtn = !allFormFieldsFilledOut || submitPMAUnitRequest.isLoading;

  return (
    <PMAContainer>
      <Stack>
        <Grid container gap={10}>
          {/* Left - Student View/Full prompt */}
          <Grid item xs>
            <Card elevation={0} sx={{ height: 460, border: '1px solid black', borderRadius: '8px' }} contentSx={{ height: '100%', padding: 3 }}>
              <Stack justifyContent="center" alignItems="center" gap={2} height="100%">
                {isUnitComplete ? (
                  <UnitCompleteCard hasBeenSubmitted={false} />
                ) : (
                  <>
                    <Box sx={{ backgroundColor: hootTokens.surface[2], borderRadius: '8px', padding: '16px 32px' }}>{formatFullPrompt()}</Box>
                    <Box display="flex" justifyContent="center" gap={2}>
                      <Button onClick={handleIncorrectClick} variant="contained" color="error.190" startIcon={<CloseFilledSquareIcon />}>
                        Incorrect
                      </Button>
                      <Button onClick={handleCorrectClick} variant="contained" color="success.190" startIcon={<CheckmarkSquare />}>
                        Correct
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </Card>
          </Grid>

          {/* Right - Scorecard/Word List */}
          <Grid item xs>
            <HootTypography variant="tableheadingactive" isPII={false}>
              Scorecard
            </HootTypography>
            <Stack component="ol" sx={{ paddingInlineStart: '8px', marginBottom: 0 }}>
              {toKeyValPair(prompts, answers).map((keyVal, idx) => (
                <Stack key={`${keyVal[0]}-${idx}`} component="li" flexDirection="row" alignItems="center" gap={1}>
                  {/* Current Indicator icon */}
                  {currentIndex === idx && <PlayIcon sx={{ width: '14px', height: '16px' }} />}

                  {/* Prompt */}
                  <Button
                    onClick={() => onPromptClick(idx)}
                    sx={{ display: 'flex', flexDirection: 'row', minHeight: '42px', alignItems: 'center', gap: '4px', padding: '8px 16px' }}
                  >
                    <HootTypography variant="bodylarge" isPII={false}>{`${idx + 1}. ${keyVal[0]}`}</HootTypography>
                  </Button>

                  {/* Correct/Incorrect icon */}
                  {answerRecord[keyVal[1] as PMAStepTestAnswer]}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <EditAnswerDialog
          open={showEditAnswerDialog}
          questionLabel={unitQuestionAnswerPair[currentIndex]?.wordPrompt ?? ''}
          defaultAnswer={unitQuestionAnswerPair[currentIndex]?.answer ?? PMAStepTestAnswer.NoAnswer}
          onSubmit={handleEditAnswer}
          onCancel={handleCancelEdit}
          setIsUnitQuestionnaireDirty={setIsUnitQuestionnaireDirty}
        />
        <PMAFooter
          allFormFieldsFilledOut={allFormFieldsFilledOut}
          onPreviousUnitClick={onPreviousUnitClick}
          setLeaveDialog={setLeaveDialog}
          isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
          disableSubmissionBtn={disableSubmissionBtn}
          onNextUnitAndSubmissionClick={onNextUnitAndSubmissionClick}
        />
        <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
      </Stack>
    </PMAContainer>
  );
};

export default PMA_QUESTIONNAIRE_LAYOUT_3;
