import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface ProgressMonitoringAssessmentUnitSubmissionDataResponse {
  submissionData: any | null;
}

export async function searchPMAUnitSubmissionData(assessmentId: string, unitId: string) {
  const { data } = await axios.get<ProgressMonitoringAssessmentUnitSubmissionDataResponse>(
    `${config.apiUrl}/public/progress-monitoring-assessment/${assessmentId}/unit/${unitId}`,
  );
  return data;
}

export default function useSearchPMAUnitSubmissionData(
  assessmentId: string,
  unitId: string,
  options?: Omit<UseQueryOptions<ProgressMonitoringAssessmentUnitSubmissionDataResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.searchPMAUnitSubmissionData, assessmentId, unitId], () => searchPMAUnitSubmissionData(assessmentId, unitId), options);
}
