export enum TimelineEvent {
  HRA = 'HOOT_READING_ASSESSMENT',
  PMA = 'PROGRESS_MONITORING_ASSESSMENT',
  CompletedLesson = 'COMPLETED_LESSON',
  ScheduledLesson = 'SCHEDULED_LESSON',
  StudentAbsence = 'STUDENT_ABSENCE',
  Cancellation = 'CANCELLATION',
}

export enum TimelineOutcome {
  Mastered = 'MASTERED',
  Passed = 'PASSED',
  ReviewRequired = 'REVIEW_REQUIRED',
  InstructionRequired = 'INSTRUCTION_REQUIRED',
  Blank = 'BLANK',
}

export enum TimelineLessonReviewFocus {
  PreviouslyPassed = 'PREVIOUSLY_PASSED',
  LessonPrimaryFocus = 'LESSON_PRIMARY_FOCUS',
  LessonSecondaryFocus = 'LESSON_SECONDARY_FOCUS',
  FormativeAssessment = 'FORMATIVE_ASSESSMENT',
}

export enum FilterTypeValues {
  DateBased = 'DATE_BASED',
  PeriodBased = 'PERIOD_BASED',
}

export enum PeriodBasedValues {
  EntireEnrolment = 'ENTIRE_CURRENT_ENROLMENT',
  AllTimeLessonHistory = 'ALL_TIME_LESSON_HISTORY',
  MostRecentUntilNextHRA = 'MOST_RECENT_UNTIL_NEXT_HRA',
  MostRecentUntilNextHRAOrPMA = 'MOST_RECENT_UNTIL_NEXT_HRA_OR_PMA',
}

export enum FocusVisibilityOption {
  PreWordReading = 'PRE_WORD_READING',
  EarlyWordReading = 'EARLY_WORD_READING',
  ComplexReading = 'COMPLEX_READING',
  TextLanguage = 'TEXT_LANGUAGE',
  TextReading = 'TEXT_READING',
  OtherAreas = 'OTHER_AREAS',
}

export enum EventVisibilityOption {
  Hra = 'HRA',
  Pma = 'PMA',
  Absence = 'ABSENCE',
  Cancellation = 'CANCELLATION',
  Lessons = 'LESSONS',
}
