import { useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { FULL_DATE } from '@hoot/constants/constants';
import useGetRecentStudentLessonReviews from '@hoot/hooks/api/lesson-reviews/useGetRecentStudentLessonReviews';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { getLessonReviewLessonTypeLabel } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewDetailsCard';

interface FormattedReviewData {
  lessonDate: string;
  lessonNumber?: number;
  lessonType: string;
  focus: string;
  scheduledLessonId?: string;
  editButton?: React.ReactNode;
}

interface RecentReviewsProps {
  onEditLessonReview: (lessonId: string) => void;
}

const RecentReviews = (props: RecentReviewsProps) => {
  const { onEditLessonReview } = props;

  const theme = useTheme();
  const sub600px = useMediaQuery(theme.breakpoints.down('sm'));

  const { studentProfileId } = useParams() as { studentProfileId: string };

  const { data: recentStudentReviews = [], isFetching } = useGetRecentStudentLessonReviews(studentProfileId, {
    enabled: !!studentProfileId,
  });

  const handleEditClick = (lessonId: string) => {
    onEditLessonReview(lessonId);
  };

  const headers: HeaderData<FormattedReviewData>[] = [
    { name: 'Date', property: 'lessonDate' },
    { name: 'Lesson Number', property: 'lessonNumber' },
    { name: 'Lesson Type', property: 'lessonType' },
    { name: 'Focus', property: 'focus', isHidden: sub600px },
    { name: 'Actions', property: 'editButton' },
  ];

  const formattedData =
    recentStudentReviews.map<FormattedReviewData>((rd) => ({
      lessonDate: DateTime.fromISO(rd.lessonDate).toFormat(FULL_DATE),
      lessonNumber: rd.lessonNumber,
      lessonType: getLessonReviewLessonTypeLabel(rd.lessonType),
      focus: rd.focus,
      editButton: rd.scheduledLessonId ? (
        <Button onClick={() => handleEditClick(rd.scheduledLessonId)} variant="contained" size="small">
          Edit
        </Button>
      ) : null,
    })) ?? [];

  if (recentStudentReviews.length === 0) return null;

  return (
    <Card title="Recently Submitted Reviews" isLoading={isFetching}>
      <TableV2 data={formattedData} headers={headers} />
    </Card>
  );
};

export default RecentReviews;
