import { SvgIcon, SvgIconProps } from '@mui/material';

const StudentAbsenceIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#F26D4F" />
    <path
      d="M13.488 14.512H10.344L9.84 16H7.692L10.74 7.576H13.116L16.164 16H13.992L13.488 14.512ZM12.96 12.928L11.916 9.844L10.884 12.928H12.96Z"
      fill="black"
    />
  </SvgIcon>
);

export default StudentAbsenceIcon;
