import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '../../../../../../../hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '../../../../../../../redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import HootTypography from '../../../../../../components/v2/core/HootTypography';
import { PMAContainer } from '../PMAContainer';
import { PMAFooter } from '../PMAPageFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import { RadioOption } from '../interface';
import useTeacherPMAController from '../useTeacherPMAController';

export interface Layout5Questionnaire {
  title: string;
  options: RadioOption[];
}

export function PMA_QUESTIONNAIRE_LAYOUT_5(props: { questionnaire: Layout5Questionnaire[] }) {
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const [answers, setAnswers] = useState<Module_4_GenericAnswer[]>(
    props.questionnaire.map((q) => ({
      section: q.title,
      answer: null,
      answerValue: null,
    })),
  );

  const dispatch = useAppDispatch();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const optionFlatMapped = props.questionnaire.flatMap((q) => q.options);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);

  const pmaController = useTeacherPMAController();

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    enabled: !!assessmentID,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        setAnswers(
          (data.submissionData as Module_4_GenericAnswer[]).map((q) => ({
            section: q.section,
            answer: q.answer,
            answerValue: q.answerValue ?? null,
          })),
        );
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const handleOptionChange = (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const optionSelected = optionFlatMapped.find((o) => o.text === value);
    if (!optionSelected) {
      dispatch(createFlashMessage({ message: 'There was a problem with processing your selection.' }));
    } else {
      setAnswers((currentState) =>
        currentState.map((a) => (a.section !== section ? a : { ...a, answer: optionSelected.text, answerValue: optionSelected.val })),
      );
      setIsUnitQuestionnaireDirty(true);
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const submissionData: Module_4_GenericAnswer[] = answers.map((a) => ({
      section: a.section,
      answer: a.answer,
      answerValue: a.answerValue,
    }));
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: submissionData,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const disableSubmissionBtn = answers.some((a) => !a.answer);

  return (
    <PMAContainer>
      <Stack flex={1}>
        {props.questionnaire.map((q, idx) => (
          <Stack marginTop="24px">
            <Typography variant="titlesmall">{q.title}</Typography>
            <RadioGroup onChange={handleOptionChange(q.title)} name="test" value={answers[idx].answer}>
              {q.options.map((o) => {
                return (
                  <FormControlLabel
                    value={o.text}
                    control={<Radio />}
                    label={<HootTypography isPII={false}>{o.text}</HootTypography>}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  />
                );
              })}
            </RadioGroup>
          </Stack>
        ))}

        <PMAFooter
          allFormFieldsFilledOut={false}
          onPreviousUnitClick={onPreviousUnitClick}
          setLeaveDialog={setLeaveDialog}
          isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
          disableSubmissionBtn={disableSubmissionBtn}
          onNextUnitAndSubmissionClick={onNextUnitAndSubmissionClick}
        />
        <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
      </Stack>
    </PMAContainer>
  );
}
