import { DailyVideo, useAudioTrack, useDaily, useDailyEvent, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/system';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TEACHER_VIDEO_HEIGHT, TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { zIndexes } from '@hoot/constants/zIndices';
import { useShallowMergeMeetingSessionData } from '@hoot/hooks/daily/useShallowMergeMeetingSessionData';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { DailyMediaState, initialSessionState, useTeacherMediaState } from '../../../../hooks/daily/useDailyMediaState';
import { useAudioVideoSettingsDialog } from '../../../context/AudioVideoSettingsDialogContext';
import HootTypography from '../core/HootTypography';
import DailyAudioVisualizer from '../video/DailyAudioVisualizer';
import { CameraOff } from './ui-components/CameraOff';
import LeaveLessonButton from './ui-components/LeaveLessonButton';
import NetworkVisualizer from './ui-components/NetworkVisualizer';
import { CameraButton, MenuButton, MicButton } from './ui-components/VideoComponents';

const LocalTeacherTile = () => {
  const dailyCallObj = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideoTrack = useVideoTrack(localSessionId);
  const localAudioTrack = useAudioTrack(localSessionId);
  const isOnStudentLibrary = useRouteMatch([`${routesDictionary.myStudents.details.library.absolutePath}`]);

  const teacherName = useSelector((state: RootState) => state.activeLesson.inLesson?.teacherName);
  const book = useSelector((state: RootState) => state.readers.inLessonReader.book);

  const { toggleCamera, toggleMicrophone } = useTeacherMediaState();
  const { onShow } = useAudioVideoSettingsDialog();
  const { mergeMeetingSessionData } = useShallowMergeMeetingSessionData();

  const isVideoEnabled = !localVideoTrack.isOff;
  const isAudioEnabled = !localAudioTrack.isOff;

  useDailyEvent(
    'meeting-session-state-updated',
    useCallback(
      (ev) => {
        if (dailyCallObj) {
          /*
           * Note: This event is triggered after 14-15 minutes when a user is in a lesson by themselves. This is becuase Daily creates a
           * new session after 10 minutes when a user is in a session by themselves (https://docs.daily.co/reference/rest-api/meetings).
           * The data (even though it was initialized) does not exist on this trigger (as it is reset) and the users video and audio
           * tracks are read as 'ON' even though they set them off. If there was no check for the data to not be empty then this
           * would turn on the users camera and mic.
           */
          const { isInitialized, teacherCameraOn, teacherMicrophoneOn } = ev.meetingSessionState.data as DailyMediaState;

          if (!_.isEmpty(ev.meetingSessionState.data)) {
            if (!isInitialized) {
              mergeMeetingSessionData<DailyMediaState>({
                ...initialSessionState,
                isInitialized: true,
                updatedAt: DateTime.now().toMillis(),
              });
            } else {
              dailyCallObj.setLocalVideo(teacherCameraOn ?? isVideoEnabled);
              dailyCallObj.setLocalAudio(teacherMicrophoneOn ?? isAudioEnabled);
            }
          }
        }
      },
      [dailyCallObj, mergeMeetingSessionData, isAudioEnabled, isVideoEnabled],
    ),
  );

  const handleOptionsClick = () => {
    onShow();
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation5,
        backgroundColor: hootTokens.palette.white,
        zIndex: !!isOnStudentLibrary && !!book ? zIndexes.video : undefined,
      }}
    >
      <HootTypography isPII variant="bodysmall" textAlign="center">
        {teacherName}
      </HootTypography>

      <Stack gap={1} sx={{ position: 'relative' }}>
        {localSessionId && isVideoEnabled ? (
          <DailyVideo
            sessionId={localSessionId}
            type={'video'}
            automirror={true}
            width={TEACHER_VIDEO_WIDTH}
            height={TEACHER_VIDEO_HEIGHT}
            fit="cover"
            style={{
              borderRadius: '8px',
              transform: 'scale(-1, 1)',
            }}
          />
        ) : null}

        {!isVideoEnabled && <CameraOff />}

        <Stack flexDirection="row" position="absolute" top={8} right={8} columnGap={2}>
          {isVideoEnabled && <DailyAudioVisualizer audioTrack={localAudioTrack} />}
          {isVideoEnabled && <NetworkVisualizer />}
        </Stack>

        <Stack justifyContent="center" direction="row" columnGap={1} mb={1}>
          <CameraButton isOn={isVideoEnabled} onClick={toggleCamera} />

          <MicButton isOn={isAudioEnabled} onClick={toggleMicrophone} />

          <MenuButton onClick={handleOptionsClick} />
        </Stack>
        <LeaveLessonButton />
      </Stack>
    </Box>
  );
};

export default LocalTeacherTile;
