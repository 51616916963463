import { EventType } from '@hoot/events/eventType';
import { LessonCelebrationMessage } from '@hoot/events/messages/lesson-celebration.message';
import { LessonLibraryTabChangedResponseMessage } from '@hoot/events/messages/lesson-library-tab-changed-response.message';
import { LessonReadingStateUpdatedResponseMessage } from '@hoot/events/messages/lesson-reading-state-updated-response.message';
import { LessonStateUpdatedResponseMessage } from '@hoot/events/messages/lesson-state-updated-response.message';
import { PreviewLessonLibraryBookResponseMessage } from '@hoot/events/messages/preview-lesson-library-book-response.message';
import { PreviewLessonWhiteboardTemplateResponseMessage } from '@hoot/events/messages/preview-lesson-whiteboard-template-response.message';
import { RefreshLessonResponseMessage } from '@hoot/events/messages/refresh-lesson-response.message';
import { SearchLessonLibraryResponseMessage } from '@hoot/events/messages/search-lesson-library-response.message';
import { SearchLessonWhiteboardTemplatesResponseMessage } from '@hoot/events/messages/search-lesson-whiteboard-templates-response.message';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { handleSyncLessonState, updateLessonCelebrationCount, updateSendCelebration } from '@hoot/redux/reducers/activeLessonSlice';
import {
  LibraryContext,
  handleSyncLessonLibraryState,
  handleSyncLessonWhiteboardTemplatesState,
  handleTabChange,
  setPreviewBook,
  setPreviewBookWhiteboardTemplate,
} from '@hoot/redux/reducers/librarySlice';
import { handleLessonReaderUpdated } from '@hoot/redux/reducers/readingSlice';
import { handleSyncWhiteboardState } from '@hoot/redux/reducers/whiteboardSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { handleSyncPMA } from '../../redux/reducers/pmaSlice';

const libraryContext = LibraryContext.InLessonLibrary;

const useLessonMessageHandler = () => {
  const dispatch = useAppDispatch();

  // Refresh lesson response handler
  useSocketSubscription<RefreshLessonResponseMessage>(EventType.RefreshLessonResponse, {
    onEventReceived: (response) => {
      const { lessonState, libraryState, readerState, whiteboardTemplatesState, pmaSyncMessage } = response;

      // Library state may be null if no query was ever sent (this is the initial state).
      if (libraryState) {
        dispatch(handleSyncLessonLibraryState(libraryState));
      }
      if (readerState) {
        dispatch(handleLessonReaderUpdated(readerState));
      }
      if (whiteboardTemplatesState) {
        dispatch(handleSyncLessonWhiteboardTemplatesState(whiteboardTemplatesState));
      }
      // If there is no active whiteboard ID, then clear out the whiteboard state
      if (!lessonState.activeWhiteboardId) {
        dispatch(handleSyncWhiteboardState(null));
      }
      if (pmaSyncMessage) {
        dispatch(handleSyncPMA(pmaSyncMessage));
      }

      dispatch(handleSyncLessonState(lessonState));
    },
  });

  // Lesson updated handler.
  useSocketSubscription<LessonStateUpdatedResponseMessage>(EventType.LessonUpdatedResponse, {
    onEventReceived: (response) => {
      const { lessonState } = response;
      dispatch(handleSyncLessonState(lessonState));

      // If there is no active whiteboard ID, then clear out the whiteboard state
      if (!lessonState.activeWhiteboardId) {
        dispatch(handleSyncWhiteboardState(null));
      }
    },
  });

  // Library results updated handler.
  useSocketSubscription<SearchLessonLibraryResponseMessage>(EventType.SearchLessonLibraryResponse, {
    onEventReceived: (response) => {
      const { libraryState } = response;
      dispatch(handleSyncLessonLibraryState(libraryState));
    },
  });

  // Preview book updated handler.
  useSocketSubscription<PreviewLessonLibraryBookResponseMessage>(EventType.PreviewLessonLibraryBookResponse, {
    onEventReceived: (response) => {
      const { previewBook } = response;
      dispatch(setPreviewBook({ libraryContext, previewBook }));
    },
  });

  // Whiteboard template results handler.
  useSocketSubscription<SearchLessonWhiteboardTemplatesResponseMessage>(EventType.SearchLessonWhiteboardTemplatesResponse, {
    onEventReceived: (response) => {
      dispatch(handleSyncLessonWhiteboardTemplatesState(response.whiteboardTemplatesState));
    },
  });

  // Whiteboard template preview handler.
  useSocketSubscription<PreviewLessonWhiteboardTemplateResponseMessage>(EventType.PreviewLessonWhiteboardTemplateResponse, {
    onEventReceived: (response) => {
      dispatch(setPreviewBookWhiteboardTemplate(response.previewWhiteboardTemplate));
    },
  });

  // Tab change handler.
  useSocketSubscription<LessonLibraryTabChangedResponseMessage>(EventType.LessonLibraryTabChangedResponse, {
    onEventReceived: (response) => {
      const { tab } = response;
      dispatch(handleTabChange(tab));
    },
  });

  // Reader state updated handler.
  useSocketSubscription<LessonReadingStateUpdatedResponseMessage>(EventType.LessonReadingStateUpdatedResponse, {
    onEventReceived: (response) => {
      const { readerState } = response;
      dispatch(handleLessonReaderUpdated(readerState));
    },
  });

  // Celebrate
  useSocketSubscription<LessonCelebrationMessage>(EventType.LessonCelebration, {
    onEventReceived: (response) => {
      const { celebrationType, celebrationCount } = response;
      dispatch(updateLessonCelebrationCount(celebrationCount));
      dispatch(updateSendCelebration(celebrationType));
    },
  });
};

export default useLessonMessageHandler;
