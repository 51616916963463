import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { SegmentAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';

interface IEditAnswerProps {
  open: boolean;
  questionLabel: string;
  defaultAnswer: PMAStepTestAnswer;
  onSubmit: (newAnswer: PMAStepTestAnswer) => void;
  onCancel: () => void;
  setIsUnitQuestionnaireDirty: (val: boolean) => void;
}

export const EditAnswerDialog = (props: IEditAnswerProps) => {
  const { open, questionLabel, defaultAnswer, onSubmit, onCancel, setIsUnitQuestionnaireDirty } = props;

  const [newAnswer, setNewAnswer] = useState<PMAStepTestAnswer>(defaultAnswer);

  // Refresh the default value whenever this modal is launched.
  useEffect(() => {
    if (open) {
      setNewAnswer(defaultAnswer);
    }
  }, [open, defaultAnswer]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAnswer(event.target.value as PMAStepTestAnswer);
  };

  const handleApply = () => {
    if (defaultAnswer !== newAnswer) {
      setIsUnitQuestionnaireDirty(true);
    }
    onSubmit(newAnswer);
  };

  const handleCancel = () => {
    setIsUnitQuestionnaireDirty(false);
    onCancel();
  };

  return (
    <BasicAlertDialog
      show={open}
      title={'Edit Answer'}
      maxWidth={'xs'}
      content={
        <Stack gap={3}>
          <HootTypography isPII={false} variant="bodylarge">
            "{questionLabel}"
          </HootTypography>
          <FormControl>
            <RadioGroup defaultValue={newAnswer} value={newAnswer} name="radio-buttons-group" onChange={handleRadioChange} sx={{ marginLeft: '8px' }}>
              <FormControlLabel
                value={SegmentAnswer.Wrong}
                control={<Radio />}
                label={
                  <Stack direction="row" gap={1}>
                    <CloseFilledSquareIcon />
                    <HootTypography isPII={false}>Incorrect</HootTypography>
                  </Stack>
                }
                sx={{ display: 'flex', alignItems: 'center' }}
              />
              <FormControlLabel
                value={SegmentAnswer.Correct}
                control={<Radio />}
                label={
                  <Stack direction="row" gap={1}>
                    <CheckmarkSquare />
                    <HootTypography isPII={false}>Correct</HootTypography>
                  </Stack>
                }
                sx={{ display: 'flex', alignItems: 'center' }}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      }
      primaryAction={{
        label: 'Apply',
        onClick: handleApply,
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: handleCancel,
      }}
      onDismiss={handleCancel}
    />
  );
};
