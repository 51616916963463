import { SvgIcon, SvgIconProps } from '@mui/material';

const InstructionRequiredIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="#F78F1F" />
    <path d="M12.796 7.576V16H10.744V7.576H12.796Z" fill="black" />
  </SvgIcon>
);

export default InstructionRequiredIcon;
