import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import useTeacherPMAController from './useTeacherPMAController';

interface Props {
  allFormFieldsFilledOut: boolean;
  onPreviousUnitClick?: () => void;
  isUnitQuestionnaireDirty: boolean;
  setLeaveDialog: (val: boolean) => void;
  onNextUnitAndSubmissionClick: () => void;
  disableSubmissionBtn: boolean;
  submitButtonLabel?: string;
}

export function PMAFooter(props: Props) {
  const pmaController = useTeacherPMAController();
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);

  const onPreviousUnitClick = () => {
    if (props.isUnitQuestionnaireDirty) {
      props.setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid #D3D3D3', paddingTop: 3, marginTop: '16px' }}>
      <div style={{ display: 'flex', cursor: isPrevEnabled ? 'default' : 'not-allowed' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" rotate="180deg" />}
          size="small"
          disabled={!isPrevEnabled}
          onClick={props.onPreviousUnitClick ?? onPreviousUnitClick}
        >
          Previous Unit
        </Button>
      </div>
      <div style={{ display: 'flex', cursor: !props.allFormFieldsFilledOut ? 'not-allowed' : 'default' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" />}
          size="small"
          disabled={props.disableSubmissionBtn}
          onClick={props.onNextUnitAndSubmissionClick}
        >
          {props.submitButtonLabel ? props.submitButtonLabel : <>Submit and {isNextEnabled ? 'Continue' : 'Close'}</>}
        </Button>
      </div>
    </Stack>
  );
}
