export enum EventType {
  // https://socket.io/docs/v4/client-socket-instance/#events
  Connect = 'connect', // socket-io internal message
  Disconnect = 'disconnect', // socket-io internal message
  ConnectError = 'connect_error', // socket-io internal message
  ConnectionInitialized = 'connection_initialized',
  ResyncSession = 'resync_session',
  CheckForActiveSession = 'check_for_active_session',
  DisconnectOtherSessionFromLesson = 'disconnect_other_session_from_lesson',
  Alert = 'alert',
  ScheduledLessons = 'scheduled_lessons',
  DistrictRepEnrolmentsLessons = 'district_rep_enrolments_lessons',
  RefreshScheduledLessons = 'refresh_scheduled_lessons',
  RefreshDistrictRepEnrolmentsLessons = 'refresh_district_rep_enrolments_lessons',
  JoinLesson = 'join_lesson',
  LessonJoined = 'lesson_joined',
  EndLesson = 'end_lesson',
  LeaveLesson = 'leave_lesson',
  RefreshLessonRequest = 'refresh_lesson_request',
  RefreshLessonResponse = 'refresh_lesson_response',
  LessonUpdatedResponse = 'lesson_updated_response',
  SearchLibraryRequest = 'search_library_request',
  SearchLibraryResults = 'library_search_results',
  LessonLibraryTabChangedResponse = 'lesson_library_tab_changed_response',
  SearchLessonLibraryRequest = 'search_lesson_library_request',
  SearchLessonLibraryResponse = 'search_lesson_library_response',
  PreviewLessonLibraryBookRequest = 'preview_lesson_library_book_request',
  PreviewLessonLibraryBookResponse = 'preview_lesson_library_book_response',
  OpenLessonBookRequest = 'open_lesson_book_request',
  CloseLessonBookRequest = 'close_lesson_book_request',
  LessonActivePageChangeRequest = 'lesson_active_page_change_request',
  LessonBookPageZoomChangeRequest = 'lesson_reader_zoom_change_request',
  LessonBookDoublePageViewChangeRequest = 'lesson_double_page_view_change_request',
  LessonReadingStateUpdatedResponse = 'lesson_reading_state_updated_response',
  GetBookRequest = 'get_book_request',
  GetBookResponse = 'get_book_response',
  LessonBookPageChangeRequest = 'lesson_book_page_change_request',
  LessonEnded = 'lesson_ended',
  ToggleFavouriteBookRequest = 'toggle_favourite_book_request',
  ToggleFavouriteBookResponse = 'toggle_favourite_book_response',
  ToggleLessonFavouriteBookRequest = 'toggle_lesson_favourite_book_request',
  MagicFinger = 'magic_finger',
  PageRead = 'page_read',
  DisconnectAllOtherProfiles = 'disconnected_all_other_profiles',
  Logout = 'logout',
  SyncAndLogLessonDailyError = 'sync_and_log_lesson_daily_error',
  LogDailyLessonNetworkQuality = 'log_daily_lesson_network_quality',
  UserUpdated = 'user_updated',
  LessonTeacherChanged = 'lesson_teacher_changed',
  NotifyRemovedFromLesson = 'notify_removed_from_lesson',
  NewNotificationEvent = 'event:new_notification',
  NotificationReadEvent = 'event:notification_read',
  UpcomingLessonsRequest = 'request:upcoming_lessons',
  UpcomingLessonsResponse = 'response:upcoming_lessons',
  LessonCancelled = 'lesson_cancelled',
  SetLessonReviewState = 'set_lesson_review_state',
  UpdateLessonReviewNoteDisplay = 'update_lesson_review_note_display',
  NotifyLessonReviewSubmitted = 'notify_lesson_review_submitted',
  LessonReviewSubmittedNotification = 'lesson_review_submitted_notification',
  SearchLessonWhiteboardTemplatesRequest = 'search_lesson_whiteboard_templates_request',
  SearchLessonWhiteboardTemplatesResponse = 'search_lesson_whiteboard_templates_response',
  PreviewLessonWhiteboardTemplateRequest = 'preview_lesson_whiteboard_template_request',
  PreviewLessonWhiteboardTemplateResponse = 'preview_lesson_whiteboard_template_response',
  WhiteboardOpenRequest = 'whiteboard_open:request',
  WhiteboardCloseRequest = 'whiteboard_close:request',
  WhiteboardGetStateRequest = 'whiteboard_get_state:request',
  WhiteboardGetStateResponse = 'whiteboard_get_state:response',
  WhiteboardGetSnapshotRequest = 'whiteboard_get_snapshot:request',
  WhiteboardGetSnapshotResponse = 'whiteboard_get_snapshot:response',
  WhiteboardChangeRequest = 'whiteboard_change:request',
  WhiteboardChangeResponse = 'whiteboard_change:response',
  WhiteboardFlushChangeRequest = 'whiteboard_flush_changes:request',
  WhiteboardResetRequest = 'whiteboard_reset:request',
  WhiteboardToolChangeRequest = 'whiteboard_tool_change:request',
  WhiteboardRecenterRequest = 'whiteboard_recenter:request',
  WhiteboardRecenterResponse = 'whiteboard_recenter:response',
  LessonCelebration = 'lesson_celebration',
  PMAOpenRequest = 'pma_open:request',
  PMASyncResponse = 'pma_sync:response',
  PMAPrevUnit = 'pma_prev_unit:request',
  PMANextUnit = 'pma_next_unit:request',
  PMASetText = 'pma_set_text:request',
  PMASetIsUnitComplete = 'pma_set_is_unit_complete:request',
  PMACloseRequest = 'pma_close:request',
}
