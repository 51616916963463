import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { IconButton, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useRef, useState } from 'react';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';
import { ReaderContext, readerKeyLookup, useReaders } from '../../../../../../redux/reducers/readingSlice';
import { Coords, ZoomControl } from '../../../../../components/v2/library/ZoomControl';
import useTeacherInLessonReaderController from '../library/reader/useTeacherInLessonReaderController';

export function PMAToolsMenu(props: { onShowConfirmationClick: (showConfirmation: boolean) => void; currentUnitId: PMAUnitIdentifier }) {
  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const readerKey = readerKeyLookup[ReaderContext.InLessonReader];
  const reader = useReaders()[readerKey];

  const controller = useTeacherInLessonReaderController();

  const [showZoomControl, setShowZoomControl] = useState(false);
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const onZoomClick = () => {
    setShowZoomControl(true);
    dismissToolsMenu();
  };

  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const isModule4 = [PMAUnitIdentifier.Module_4_Unit_1, PMAUnitIdentifier.Module_4_Unit_3, PMAUnitIdentifier.Module_4_Unit_4].includes(
    props.currentUnitId,
  );

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const handleShowConfirmationClick = () => {
    props.onShowConfirmationClick(true);
  };

  const handleDoublePage = () => {
    controller.onToggleDoublePage();
  };

  const toolsMenuItems = (): MenuItem[] => {
    const toolItemsArray = [
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
      { id: 'zoom', label: 'Zoom', icon: <Icon name="zoom_in" />, onClick: onZoomClick },
      {
        id: 'notepad',
        label: 'Notepad',
        icon: <Icon name="pen" />,
        onClick: toggleShowNotepad,
      },
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'celebrations',
        label: 'Celebrations',
        icon: <Icon name="hoot_wheel" />,
        onClick: toggleCelebrationsDialog,
      },
    ];

    if (
      ![
        PMAUnitIdentifier.Module_4_Unit_1,
        PMAUnitIdentifier.Module_4_Unit_2,
        PMAUnitIdentifier.Module_4_Unit_3,
        PMAUnitIdentifier.Module_4_Unit_4,
      ].includes(props.currentUnitId)
    ) {
      // remove the zoom control if not on module 4 units
      toolItemsArray.splice(1, 1);
    }

    return toolItemsArray;
  };

  const {
    inLessonReader: { book, pageIndex, isDoublePage, pageZoom },
  } = useReaders();

  const zoomBoxCoordsRef = useRef<Coords>({ x: 0, y: 0 });

  const disableDoublePageIfFirstPage = pageIndex === 0;
  const disableDoublePage = !book?.isDoublePageEnabled || (book?.isStandardDoublePage && disableDoublePageIfFirstPage) || !isModule4;

  const doublePageToolTip = () => {
    if (!book?.isDoublePageEnabled) {
      return 'This feature is not available for decodables';
    }

    if (disableDoublePageIfFirstPage) {
      return 'Double Page Disabled on First Page';
    }

    return isDoublePage ? 'Show Single Page View' : 'Show Double Page View';
  };

  return (
    <Stack direction="row" gap={2}>
      <Tooltip title="Show Tools Menu">
        <IconButton onClick={showToolsMenu}>
          <Icon name="compass" />
        </IconButton>
      </Tooltip>
      <Tooltip title={doublePageToolTip()}>
        <span>
          <IconButton disabled={disableDoublePage} onClick={handleDoublePage}>
            <Icon name={reader.isDoublePage ? 'book_double' : 'single_page'} color={disableDoublePage ? 'primary.80' : 'primary'} />
          </IconButton>
        </span>
      </Tooltip>
      <IconButton onClick={handleShowConfirmationClick}>
        <Icon name={'close_filled_square'} color={'error.80'} sx={{ background: 'error.80' }} />
      </IconButton>

      <PopoverMenu
        id="tools-menu"
        variant="dark"
        anchorEl={toolsIconButtonElement}
        open={!!toolsIconButtonElement}
        onClose={dismissToolsMenu}
        items={toolsMenuItems()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {showZoomControl ? (
        <ZoomControl
          onClose={() => setShowZoomControl(false)}
          pages={book?.pages ?? []}
          onReset={controller.onResetZoom}
          onZoomChange={controller.onZoomAmountChange}
          onOffsetChange={controller.onZoomOffsetChange}
          zoomValue={pageZoom.zoomAmount * 100}
          zoomBoxCoordsRef={zoomBoxCoordsRef}
          onToggleDoublePage={controller.onToggleDoublePage}
          readerContext={ReaderContext.InLessonReader}
        />
      ) : null}
    </Stack>
  );
}
